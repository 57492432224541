@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

@font-face {
  font-family: "consulter";
  src: url("../fonts/consulter.woff2");
  src: url("../fonts/consulter.woff2");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "consulter";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fas,
.fal {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.icon-arrow-right-2:before {
  content: "\e89f";
}

.icon-process-1:before {
  content: "\e860";
}
.icon-call:before {
  content: "\e8ab";
}

.icon-play:before {
  content: "\e859";
}

.icon-left-3:before {
  content: "\e837";
}

.icon-right-3:before {
  content: "\e86e";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.icon-planning:before {
  content: "\e857";
}

.fal {
  font-weight: 300;
}

.icon-outline:before {
  content: "\e852";
}

.icon-support-2:before {
  content: "\e87c";
}
.icon-coffee-2:before {
  content: "\e802";
}
.icon-teamwork-1:before {
  content: "\e87f";
}

.icon-email-1:before { content: '\e818'; }
.icon-phone:before { content: '\e854'; }
.icon-home-location:before { content: '\e82c'; }
.icon-email:before { content: '\e817'; }