@charset "UTF-8";
@import url(./icone.css);
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Pathway+Gothic+One&family=Poppins:wght@100;300;400;500;600;700&display=swap");
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

html,
body,
div,
span,
img,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
option,
legend,
label,
table,
th,
td,
tr,
article,
aside,
caption,
figure,
footer,
header,
hgroup,
mark,
nav,
section,
time,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

img {
  max-width: 100%;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none !important;
  border: 0;
}

input:focus {
  outline: none;
}

input::-moz-focus-inner {
  border: 0;
  display: none;
}

input::-webkit-focus-inner {
  border: 0;
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::-moz-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666;
}

table {
  border-collapse: collapse;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

ul,
li {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0px;
}

form {
  position: relative;
}

.form-control:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

hr {
  margin: 0;
  padding: 0;
  border-color: #000;
}

ul {
  list-style-type: none;
}

li {
  list-style: none;
  position: relative;
}

a,
button {
  position: relative;
}
a:hover,
a:focus,
button:hover,
button:focus {
  outline: 0;
  text-decoration: none;
}

input,
textarea,
button {
  border: 0px;
}

.container {
  width: 100%;
}
@media (min-width: 1300px) {
  .container {
    max-width: 1386px;
  }
}

.container_testimonial {
  width: 100%;
}
@media (min-width: 1300px) {
  .container_testimonial {
    max-width: 1749px;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row > [class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

/* ------------------------------------------------------------------
    Template All Variables Defined - Value Change with Your's Need
 -------------------------------------------------------------------- */
.color-white {
  color: #fff;
}

.color-black {
  color: #000;
}

.color-red {
  color: #ef0548;
}

.color-secondary {
  color: #0e0a01;
}

.color-yellow {
  color: #ff9c00;
}

.bg-yellow {
  background-color: #ff9c00;
}

.bg-red {
  background-color: #ef0548;
}

.bg-dark_yellow {
  background-color: #f3f3f2;
}

.bg-dark_red {
  background-color: #fdf6f8;
}

.color-d_black {
  color: #0e1e36;
}

.color-pd_black {
  color: #04213f;
}

.bg-dark_white {
  background-color: #eff0f6;
}

.color-primary {
  color: #4069ff;
}

.font-la {
  font-family: "Lato", sans-serif;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.mb-5 {
  margin-bottom: 5px;
}

/* @font-face {
  font-family: "Gilroy";
  src: url("assets/fonts/Gilroy-SemiBold.woff2") format("woff2"), url("assets/fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2") format("woff2"),
    url("../fonts/lato-medium.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2") format("woff2"),
    url("../fonts/lato-semibold.woff2") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
body {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  color: #243342;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #243342;
}

h1 {
  font-size: 64px;
  line-height: 78px;
}

h2 {
  font-size: 48px;
  line-height: 60px;
}

h3 {
  font-size: 36px;
  line-height: 48px;
}

h4 {
  font-size: 24px;
  line-height: 36px;
}

h5 {
  font-size: 18px;
  line-height: 26px;
}

h6 {
  font-size: 18px;
  line-height: 26px;
}

a {
  font-weight: 600;
  cursor: pointer;
  font-size: 17px;
  color: #4069ff;
  line-height: 32px;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  outline: none !important;
}

p {
  margin: 0px;
}

.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-center:before,
.align-center:after {
  content: none;
}

.align-center-h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.align-center-h:before,
.align-center-h:after {
  content: none;
}

.align-center-v {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-center-v:before,
.align-center-v:after {
  content: none;
}

.align-center-v-b {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.align-center-v-b:before,
.align-center-v-b:after {
  content: none;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-content-center:before,
.justify-content-center:after {
  content: none;
}

.eq-height {
  display: -webkit-box;
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -o-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.eq-height:before,
.eq-height:after {
  content: none;
}

.section-padding {
  padding: 120px 0px;
}
@media (max-width: 995px) {
  .section-padding {
    padding: 100px 0px;
  }
}
@media (max-width: 769px) {
  .section-padding {
    padding: 80px 0px;
  }
}
@media (max-width: 500px) {
  .section-padding {
    padding: 50px 0px;
  }
}

.section-padding_3 {
  margin-top: 100px;
}
@media (max-width: 995px) {
  .section-padding_3 {
    margin-top: 80px;
  }
}
@media (max-width: 500px) {
  .section-padding_3 {
    margin-top: 50px;
  }
}

.section-title.section-title_2 h5 {
  color: #ff9c00;
}
.section-title.section-title_2 h2 span {
  color: #ff9c00;
  font-weight: 500;
}
.section-title h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #4069ff;
  margin-bottom: 20px;
}
.section-title h5 img {
  margin-right: 10px;
}
.section-title h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #0e1e36;
}
.section-title h2 span {
  font-weight: 400;
}
@media (max-width: 768px) {
  .section-title h2 {
    font-size: 38px;
    line-height: 50px;
  }
}
@media (max-width: 500px) {
  .section-title h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

.protfolio_wrapper {
  background-color: #eff0f6;
  overflow: hidden;
}

.employee__wrapper_2 {
  padding-top: 120px;
  position: relative;
  z-index: 1;
}
@media (max-width: 995px) {
  .employee__wrapper_2 {
    padding-top: 100px;
  }
}
@media (max-width: 769px) {
  .employee__wrapper_2 {
    padding-top: 80px;
  }
}
@media (max-width: 500px) {
  .employee__wrapper_2 {
    padding-top: 50px;
  }
}
.employee__wrapper_2::after {
  content: "";
  display: block;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 50%;
  /* background-image: url(assets/img/home-5/bg_05.png) !important; */
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
}

.can-help-home-3.can-help-home-4 {
  padding-top: 120px !important;
}
@media (max-width: 995px) {
  .can-help-home-3.can-help-home-4 {
    padding-top: 100px !important;
  }
}
@media (max-width: 769px) {
  .can-help-home-3.can-help-home-4 {
    padding-top: 80px !important;
  }
}
@media (max-width: 500px) {
  .can-help-home-3.can-help-home-4 {
    padding-top: 50px !important;
  }
}

.company-skill {
  position: relative;
  z-index: -1;
}
.company-skill::after {
  bottom: -30%;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 638px;
  overflow: hidden;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  /* background-image: url(../../assets/img/portfolio/our-portfolio-bg.png); */
}

/* ----------------------------------
    Template Helps Classes - Styles
 ------------------------------------ */
/***
Spacing

xs : 0 - 575
sm:576- 767
md:768 - 991
lg: 992 - 1159
xl:1200 - 1359

mt : margin-top
ml: margin-left
mr: margin-right
mb: margin-bottom

*/
.ml-auto {
  margin-left: auto;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mb-105 {
  margin-bottom: 105px;
}

.ml-105 {
  margin-left: 105px;
}

.mr-105 {
  margin-right: 105px;
}

.pt-105 {
  padding-top: 105px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pl-105 {
  padding-left: 105px;
}

.pr-105 {
  padding-right: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.ml-110 {
  margin-left: 110px;
}

.mr-110 {
  margin-right: 110px;
}

.pt-110 {
  padding-top: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pl-110 {
  padding-left: 110px;
}

.pr-110 {
  padding-right: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mb-115 {
  margin-bottom: 115px;
}

.ml-115 {
  margin-left: 115px;
}

.mr-115 {
  margin-right: 115px;
}

.pt-115 {
  padding-top: 115px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pl-115 {
  padding-left: 115px;
}

.pr-115 {
  padding-right: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.ml-120 {
  margin-left: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mb-125 {
  margin-bottom: 125px;
}

.ml-125 {
  margin-left: 125px;
}

.mr-125 {
  margin-right: 125px;
}

.pt-125 {
  padding-top: 125px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pl-125 {
  padding-left: 125px;
}

.pr-125 {
  padding-right: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mb-130 {
  margin-bottom: 130px;
}

.ml-130 {
  margin-left: 130px;
}

.mr-130 {
  margin-right: 130px;
}

.pt-130 {
  padding-top: 130px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pl-130 {
  padding-left: 130px;
}

.pr-130 {
  padding-right: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mb-135 {
  margin-bottom: 135px;
}

.ml-135 {
  margin-left: 135px;
}

.mr-135 {
  margin-right: 135px;
}

.pt-135 {
  padding-top: 135px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pl-135 {
  padding-left: 135px;
}

.pr-135 {
  padding-right: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mb-140 {
  margin-bottom: 140px;
}

.ml-140 {
  margin-left: 140px;
}

.mr-140 {
  margin-right: 140px;
}

.pt-140 {
  padding-top: 140px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pl-140 {
  padding-left: 140px;
}

.pr-140 {
  padding-right: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mb-145 {
  margin-bottom: 145px;
}

.ml-145 {
  margin-left: 145px;
}

.mr-145 {
  margin-right: 145px;
}

.pt-145 {
  padding-top: 145px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pl-145 {
  padding-left: 145px;
}

.pr-145 {
  padding-right: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-150 {
  margin-bottom: 150px;
}

.ml-150 {
  margin-left: 150px;
}

.mr-150 {
  margin-right: 150px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pl-150 {
  padding-left: 150px;
}

.pr-150 {
  padding-right: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mb-155 {
  margin-bottom: 155px;
}

.ml-155 {
  margin-left: 155px;
}

.mr-155 {
  margin-right: 155px;
}

.pt-155 {
  padding-top: 155px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pl-155 {
  padding-left: 155px;
}

.pr-155 {
  padding-right: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mb-160 {
  margin-bottom: 160px;
}

.ml-160 {
  margin-left: 160px;
}

.mr-160 {
  margin-right: 160px;
}

.pt-160 {
  padding-top: 160px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pl-160 {
  padding-left: 160px;
}

.pr-160 {
  padding-right: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mb-165 {
  margin-bottom: 165px;
}

.ml-165 {
  margin-left: 165px;
}

.mr-165 {
  margin-right: 165px;
}

.pt-165 {
  padding-top: 165px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pl-165 {
  padding-left: 165px;
}

.pr-165 {
  padding-right: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mb-170 {
  margin-bottom: 170px;
}

.ml-170 {
  margin-left: 170px;
}

.mr-170 {
  margin-right: 170px;
}

.pt-170 {
  padding-top: 170px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pl-170 {
  padding-left: 170px;
}

.pr-170 {
  padding-right: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mb-175 {
  margin-bottom: 175px;
}

.ml-175 {
  margin-left: 175px;
}

.mr-175 {
  margin-right: 175px;
}

.pt-175 {
  padding-top: 175px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pl-175 {
  padding-left: 175px;
}

.pr-175 {
  padding-right: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mb-180 {
  margin-bottom: 180px;
}

.ml-180 {
  margin-left: 180px;
}

.mr-180 {
  margin-right: 180px;
}

.pt-180 {
  padding-top: 180px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pl-180 {
  padding-left: 180px;
}

.pr-180 {
  padding-right: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mb-185 {
  margin-bottom: 185px;
}

.ml-185 {
  margin-left: 185px;
}

.mr-185 {
  margin-right: 185px;
}

.pt-185 {
  padding-top: 185px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pl-185 {
  padding-left: 185px;
}

.pr-185 {
  padding-right: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mb-190 {
  margin-bottom: 190px;
}

.ml-190 {
  margin-left: 190px;
}

.mr-190 {
  margin-right: 190px;
}

.pt-190 {
  padding-top: 190px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pl-190 {
  padding-left: 190px;
}

.pr-190 {
  padding-right: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mb-195 {
  margin-bottom: 195px;
}

.ml-195 {
  margin-left: 195px;
}

.mr-195 {
  margin-right: 195px;
}

.pt-195 {
  padding-top: 195px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pl-195 {
  padding-left: 195px;
}

.pr-195 {
  padding-right: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-200 {
  margin-left: 200px;
}

.mr-200 {
  margin-right: 200px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pl-200 {
  padding-left: 200px;
}

.pr-200 {
  padding-right: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mb-205 {
  margin-bottom: 205px;
}

.ml-205 {
  margin-left: 205px;
}

.mr-205 {
  margin-right: 205px;
}

.pt-205 {
  padding-top: 205px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pl-205 {
  padding-left: 205px;
}

.pr-205 {
  padding-right: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mb-210 {
  margin-bottom: 210px;
}

.ml-210 {
  margin-left: 210px;
}

.mr-210 {
  margin-right: 210px;
}

.pt-210 {
  padding-top: 210px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pl-210 {
  padding-left: 210px;
}

.pr-210 {
  padding-right: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mb-215 {
  margin-bottom: 215px;
}

.ml-215 {
  margin-left: 215px;
}

.mr-215 {
  margin-right: 215px;
}

.pt-215 {
  padding-top: 215px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pl-215 {
  padding-left: 215px;
}

.pr-215 {
  padding-right: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mb-220 {
  margin-bottom: 220px;
}

.ml-220 {
  margin-left: 220px;
}

.mr-220 {
  margin-right: 220px;
}

.pt-220 {
  padding-top: 220px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pl-220 {
  padding-left: 220px;
}

.pr-220 {
  padding-right: 220px;
}

/*

responsive css
sm device
*/
@media all and (min-width: 576px) and (max-width: 767px) {
  .mt-sm-5 {
    margin-top: 5px;
  }
  .mb-sm-5 {
    margin-bottom: 5px;
  }
  .ml-sm-5 {
    margin-left: 5px;
  }
  .mr-sm-5 {
    margin-right: 5px;
  }
  .pt-sm-5 {
    padding-top: 5px;
  }
  .pb-sm-5 {
    padding-bottom: 5px;
  }
  .pl-sm-5 {
    padding-left: 5px;
  }
  .pr-sm-5 {
    padding-right: 5px;
  }
  .mt-sm-10 {
    margin-top: 10px;
  }
  .mb-sm-10 {
    margin-bottom: 10px;
  }
  .ml-sm-10 {
    margin-left: 10px;
  }
  .mr-sm-10 {
    margin-right: 10px;
  }
  .pt-sm-10 {
    padding-top: 10px;
  }
  .pb-sm-10 {
    padding-bottom: 10px;
  }
  .pl-sm-10 {
    padding-left: 10px;
  }
  .pr-sm-10 {
    padding-right: 10px;
  }
  .mt-sm-15 {
    margin-top: 15px;
  }
  .mb-sm-15 {
    margin-bottom: 15px;
  }
  .ml-sm-15 {
    margin-left: 15px;
  }
  .mr-sm-15 {
    margin-right: 15px;
  }
  .pt-sm-15 {
    padding-top: 15px;
  }
  .pb-sm-15 {
    padding-bottom: 15px;
  }
  .pl-sm-15 {
    padding-left: 15px;
  }
  .pr-sm-15 {
    padding-right: 15px;
  }
  .mt-sm-20 {
    margin-top: 20px;
  }
  .mb-sm-20 {
    margin-bottom: 20px;
  }
  .ml-sm-20 {
    margin-left: 20px;
  }
  .mr-sm-20 {
    margin-right: 20px;
  }
  .pt-sm-20 {
    padding-top: 20px;
  }
  .pb-sm-20 {
    padding-bottom: 20px;
  }
  .pl-sm-20 {
    padding-left: 20px;
  }
  .pr-sm-20 {
    padding-right: 20px;
  }
  .mt-sm-25 {
    margin-top: 25px;
  }
  .mb-sm-25 {
    margin-bottom: 25px;
  }
  .ml-sm-25 {
    margin-left: 25px;
  }
  .mr-sm-25 {
    margin-right: 25px;
  }
  .pt-sm-25 {
    padding-top: 25px;
  }
  .pb-sm-25 {
    padding-bottom: 25px;
  }
  .pl-sm-25 {
    padding-left: 25px;
  }
  .pr-sm-25 {
    padding-right: 25px;
  }
  .mt-sm-30 {
    margin-top: 30px;
  }
  .mb-sm-30 {
    margin-bottom: 30px;
  }
  .ml-sm-30 {
    margin-left: 30px;
  }
  .mr-sm-30 {
    margin-right: 30px;
  }
  .pt-sm-30 {
    padding-top: 30px;
  }
  .pb-sm-30 {
    padding-bottom: 30px;
  }
  .pl-sm-30 {
    padding-left: 30px;
  }
  .pr-sm-30 {
    padding-right: 30px;
  }
  .mt-sm-35 {
    margin-top: 35px;
  }
  .mb-sm-35 {
    margin-bottom: 35px;
  }
  .ml-sm-35 {
    margin-left: 35px;
  }
  .mr-sm-35 {
    margin-right: 35px;
  }
  .pt-sm-35 {
    padding-top: 35px;
  }
  .pb-sm-35 {
    padding-bottom: 35px;
  }
  .pl-sm-35 {
    padding-left: 35px;
  }
  .pr-sm-35 {
    padding-right: 35px;
  }
  .mt-sm-40 {
    margin-top: 40px;
  }
  .mb-sm-40 {
    margin-bottom: 40px;
  }
  .ml-sm-40 {
    margin-left: 40px;
  }
  .mr-sm-40 {
    margin-right: 40px;
  }
  .pt-sm-40 {
    padding-top: 40px;
  }
  .pb-sm-40 {
    padding-bottom: 40px;
  }
  .pl-sm-40 {
    padding-left: 40px;
  }
  .pr-sm-40 {
    padding-right: 40px;
  }
  .mt-sm-45 {
    margin-top: 45px;
  }
  .mb-sm-45 {
    margin-bottom: 45px;
  }
  .ml-sm-45 {
    margin-left: 45px;
  }
  .mr-sm-45 {
    margin-right: 45px;
  }
  .pt-sm-45 {
    padding-top: 45px;
  }
  .pb-sm-45 {
    padding-bottom: 45px;
  }
  .pl-sm-45 {
    padding-left: 45px;
  }
  .pr-sm-45 {
    padding-right: 45px;
  }
  .mt-sm-50 {
    margin-top: 50px;
  }
  .mb-sm-50 {
    margin-bottom: 50px;
  }
  .ml-sm-50 {
    margin-left: 50px;
  }
  .mr-sm-50 {
    margin-right: 50px;
  }
  .pt-sm-50 {
    padding-top: 50px;
  }
  .pb-sm-50 {
    padding-bottom: 50px;
  }
  .pl-sm-50 {
    padding-left: 50px;
  }
  .pr-sm-50 {
    padding-right: 50px;
  }
  .mt-sm-55 {
    margin-top: 55px;
  }
  .mb-sm-55 {
    margin-bottom: 55px;
  }
  .ml-sm-55 {
    margin-left: 55px;
  }
  .mr-sm-55 {
    margin-right: 55px;
  }
  .pt-sm-55 {
    padding-top: 55px;
  }
  .pb-sm-55 {
    padding-bottom: 55px;
  }
  .pl-sm-55 {
    padding-left: 55px;
  }
  .pr-sm-55 {
    padding-right: 55px;
  }
  .mt-sm-60 {
    margin-top: 60px;
  }
  .mb-sm-60 {
    margin-bottom: 60px;
  }
  .ml-sm-60 {
    margin-left: 60px;
  }
  .mr-sm-60 {
    margin-right: 60px;
  }
  .pt-sm-60 {
    padding-top: 60px;
  }
  .pb-sm-60 {
    padding-bottom: 60px;
  }
  .pl-sm-60 {
    padding-left: 60px;
  }
  .pr-sm-60 {
    padding-right: 60px;
  }
  .mt-sm-65 {
    margin-top: 65px;
  }
  .mb-sm-65 {
    margin-bottom: 65px;
  }
  .ml-sm-65 {
    margin-left: 65px;
  }
  .mr-sm-65 {
    margin-right: 65px;
  }
  .pt-sm-65 {
    padding-top: 65px;
  }
  .pb-sm-65 {
    padding-bottom: 65px;
  }
  .pl-sm-65 {
    padding-left: 65px;
  }
  .pr-sm-65 {
    padding-right: 65px;
  }
  .mt-sm-70 {
    margin-top: 70px;
  }
  .mb-sm-70 {
    margin-bottom: 70px;
  }
  .ml-sm-70 {
    margin-left: 70px;
  }
  .mr-sm-70 {
    margin-right: 70px;
  }
  .pt-sm-70 {
    padding-top: 70px;
  }
  .pb-sm-70 {
    padding-bottom: 70px;
  }
  .pl-sm-70 {
    padding-left: 70px;
  }
  .pr-sm-70 {
    padding-right: 70px;
  }
  .mt-sm-75 {
    margin-top: 75px;
  }
  .mb-sm-75 {
    margin-bottom: 75px;
  }
  .ml-sm-75 {
    margin-left: 75px;
  }
  .mr-sm-75 {
    margin-right: 75px;
  }
  .pt-sm-75 {
    padding-top: 75px;
  }
  .pb-sm-75 {
    padding-bottom: 75px;
  }
  .pl-sm-75 {
    padding-left: 75px;
  }
  .pr-sm-75 {
    padding-right: 75px;
  }
  .mt-sm-80 {
    margin-top: 80px;
  }
  .mb-sm-80 {
    margin-bottom: 80px;
  }
  .ml-sm-80 {
    margin-left: 80px;
  }
  .mr-sm-80 {
    margin-right: 80px;
  }
  .pt-sm-80 {
    padding-top: 80px;
  }
  .pb-sm-80 {
    padding-bottom: 80px;
  }
  .pl-sm-80 {
    padding-left: 80px;
  }
  .pr-sm-80 {
    padding-right: 80px;
  }
  .mt-sm-85 {
    margin-top: 85px;
  }
  .mb-sm-85 {
    margin-bottom: 85px;
  }
  .ml-sm-85 {
    margin-left: 85px;
  }
  .mr-sm-85 {
    margin-right: 85px;
  }
  .pt-sm-85 {
    padding-top: 85px;
  }
  .pb-sm-85 {
    padding-bottom: 85px;
  }
  .pl-sm-85 {
    padding-left: 85px;
  }
  .pr-sm-85 {
    padding-right: 85px;
  }
  .mt-sm-90 {
    margin-top: 90px;
  }
  .mb-sm-90 {
    margin-bottom: 90px;
  }
  .ml-sm-90 {
    margin-left: 90px;
  }
  .mr-sm-90 {
    margin-right: 90px;
  }
  .pt-sm-90 {
    padding-top: 90px;
  }
  .pb-sm-90 {
    padding-bottom: 90px;
  }
  .pl-sm-90 {
    padding-left: 90px;
  }
  .pr-sm-90 {
    padding-right: 90px;
  }
  .mt-sm-95 {
    margin-top: 95px;
  }
  .mb-sm-95 {
    margin-bottom: 95px;
  }
  .ml-sm-95 {
    margin-left: 95px;
  }
  .mr-sm-95 {
    margin-right: 95px;
  }
  .pt-sm-95 {
    padding-top: 95px;
  }
  .pb-sm-95 {
    padding-bottom: 95px;
  }
  .pl-sm-95 {
    padding-left: 95px;
  }
  .pr-sm-95 {
    padding-right: 95px;
  }
  .mt-sm-100 {
    margin-top: 100px;
  }
  .mb-sm-100 {
    margin-bottom: 100px;
  }
  .ml-sm-100 {
    margin-left: 100px;
  }
  .mr-sm-100 {
    margin-right: 100px;
  }
  .pt-sm-100 {
    padding-top: 100px;
  }
  .pb-sm-100 {
    padding-bottom: 100px;
  }
  .pl-sm-100 {
    padding-left: 100px;
  }
  .pr-sm-100 {
    padding-right: 100px;
  }
  .mt-sm-105 {
    margin-top: 105px;
  }
  .mb-sm-105 {
    margin-bottom: 105px;
  }
  .ml-sm-105 {
    margin-left: 105px;
  }
  .mr-sm-105 {
    margin-right: 105px;
  }
  .pt-sm-105 {
    padding-top: 105px;
  }
  .pb-sm-105 {
    padding-bottom: 105px;
  }
  .pl-sm-105 {
    padding-left: 105px;
  }
  .pr-sm-105 {
    padding-right: 105px;
  }
  .mt-sm-110 {
    margin-top: 110px;
  }
  .mb-sm-110 {
    margin-bottom: 110px;
  }
  .ml-sm-110 {
    margin-left: 110px;
  }
  .mr-sm-110 {
    margin-right: 110px;
  }
  .pt-sm-110 {
    padding-top: 110px;
  }
  .pb-sm-110 {
    padding-bottom: 110px;
  }
  .pl-sm-110 {
    padding-left: 110px;
  }
  .pr-sm-110 {
    padding-right: 110px;
  }
  .mt-sm-115 {
    margin-top: 115px;
  }
  .mb-sm-115 {
    margin-bottom: 115px;
  }
  .ml-sm-115 {
    margin-left: 115px;
  }
  .mr-sm-115 {
    margin-right: 115px;
  }
  .pt-sm-115 {
    padding-top: 115px;
  }
  .pb-sm-115 {
    padding-bottom: 115px;
  }
  .pl-sm-115 {
    padding-left: 115px;
  }
  .pr-sm-115 {
    padding-right: 115px;
  }
  .mt-sm-120 {
    margin-top: 120px;
  }
  .mb-sm-120 {
    margin-bottom: 120px;
  }
  .ml-sm-120 {
    margin-left: 120px;
  }
  .mr-sm-120 {
    margin-right: 120px;
  }
  .pt-sm-120 {
    padding-top: 120px;
  }
  .pb-sm-120 {
    padding-bottom: 120px;
  }
  .pl-sm-120 {
    padding-left: 120px;
  }
  .pr-sm-120 {
    padding-right: 120px;
  }
  .mt-sm-125 {
    margin-top: 125px;
  }
  .mb-sm-125 {
    margin-bottom: 125px;
  }
  .ml-sm-125 {
    margin-left: 125px;
  }
  .mr-sm-125 {
    margin-right: 125px;
  }
  .pt-sm-125 {
    padding-top: 125px;
  }
  .pb-sm-125 {
    padding-bottom: 125px;
  }
  .pl-sm-125 {
    padding-left: 125px;
  }
  .pr-sm-125 {
    padding-right: 125px;
  }
  .mt-sm-130 {
    margin-top: 130px;
  }
  .mb-sm-130 {
    margin-bottom: 130px;
  }
  .ml-sm-130 {
    margin-left: 130px;
  }
  .mr-sm-130 {
    margin-right: 130px;
  }
  .pt-sm-130 {
    padding-top: 130px;
  }
  .pb-sm-130 {
    padding-bottom: 130px;
  }
  .pl-sm-130 {
    padding-left: 130px;
  }
  .pr-sm-130 {
    padding-right: 130px;
  }
  .mt-sm-135 {
    margin-top: 135px;
  }
  .mb-sm-135 {
    margin-bottom: 135px;
  }
  .ml-sm-135 {
    margin-left: 135px;
  }
  .mr-sm-135 {
    margin-right: 135px;
  }
  .pt-sm-135 {
    padding-top: 135px;
  }
  .pb-sm-135 {
    padding-bottom: 135px;
  }
  .pl-sm-135 {
    padding-left: 135px;
  }
  .pr-sm-135 {
    padding-right: 135px;
  }
  .mt-sm-140 {
    margin-top: 140px;
  }
  .mb-sm-140 {
    margin-bottom: 140px;
  }
  .ml-sm-140 {
    margin-left: 140px;
  }
  .mr-sm-140 {
    margin-right: 140px;
  }
  .pt-sm-140 {
    padding-top: 140px;
  }
  .pb-sm-140 {
    padding-bottom: 140px;
  }
  .pl-sm-140 {
    padding-left: 140px;
  }
  .pr-sm-140 {
    padding-right: 140px;
  }
  .mt-sm-145 {
    margin-top: 145px;
  }
  .mb-sm-145 {
    margin-bottom: 145px;
  }
  .ml-sm-145 {
    margin-left: 145px;
  }
  .mr-sm-145 {
    margin-right: 145px;
  }
  .pt-sm-145 {
    padding-top: 145px;
  }
  .pb-sm-145 {
    padding-bottom: 145px;
  }
  .pl-sm-145 {
    padding-left: 145px;
  }
  .pr-sm-145 {
    padding-right: 145px;
  }
  .mt-sm-150 {
    margin-top: 150px;
  }
  .mb-sm-150 {
    margin-bottom: 150px;
  }
  .ml-sm-150 {
    margin-left: 150px;
  }
  .mr-sm-150 {
    margin-right: 150px;
  }
  .pt-sm-150 {
    padding-top: 150px;
  }
  .pb-sm-150 {
    padding-bottom: 150px;
  }
  .pl-sm-150 {
    padding-left: 150px;
  }
  .pr-sm-150 {
    padding-right: 150px;
  }
  .mt-sm-155 {
    margin-top: 155px;
  }
  .mb-sm-155 {
    margin-bottom: 155px;
  }
  .ml-sm-155 {
    margin-left: 155px;
  }
  .mr-sm-155 {
    margin-right: 155px;
  }
  .pt-sm-155 {
    padding-top: 155px;
  }
  .pb-sm-155 {
    padding-bottom: 155px;
  }
  .pl-sm-155 {
    padding-left: 155px;
  }
  .pr-sm-155 {
    padding-right: 155px;
  }
  .mt-sm-160 {
    margin-top: 160px;
  }
  .mb-sm-160 {
    margin-bottom: 160px;
  }
  .ml-sm-160 {
    margin-left: 160px;
  }
  .mr-sm-160 {
    margin-right: 160px;
  }
  .pt-sm-160 {
    padding-top: 160px;
  }
  .pb-sm-160 {
    padding-bottom: 160px;
  }
  .pl-sm-160 {
    padding-left: 160px;
  }
  .pr-sm-160 {
    padding-right: 160px;
  }
  .mt-sm-165 {
    margin-top: 165px;
  }
  .mb-sm-165 {
    margin-bottom: 165px;
  }
  .ml-sm-165 {
    margin-left: 165px;
  }
  .mr-sm-165 {
    margin-right: 165px;
  }
  .pt-sm-165 {
    padding-top: 165px;
  }
  .pb-sm-165 {
    padding-bottom: 165px;
  }
  .pl-sm-165 {
    padding-left: 165px;
  }
  .pr-sm-165 {
    padding-right: 165px;
  }
  .mt-sm-170 {
    margin-top: 170px;
  }
  .mb-sm-170 {
    margin-bottom: 170px;
  }
  .ml-sm-170 {
    margin-left: 170px;
  }
  .mr-sm-170 {
    margin-right: 170px;
  }
  .pt-sm-170 {
    padding-top: 170px;
  }
  .pb-sm-170 {
    padding-bottom: 170px;
  }
  .pl-sm-170 {
    padding-left: 170px;
  }
  .pr-sm-170 {
    padding-right: 170px;
  }
  .mt-sm-175 {
    margin-top: 175px;
  }
  .mb-sm-175 {
    margin-bottom: 175px;
  }
  .ml-sm-175 {
    margin-left: 175px;
  }
  .mr-sm-175 {
    margin-right: 175px;
  }
  .pt-sm-175 {
    padding-top: 175px;
  }
  .pb-sm-175 {
    padding-bottom: 175px;
  }
  .pl-sm-175 {
    padding-left: 175px;
  }
  .pr-sm-175 {
    padding-right: 175px;
  }
  .mt-sm-180 {
    margin-top: 180px;
  }
  .mb-sm-180 {
    margin-bottom: 180px;
  }
  .ml-sm-180 {
    margin-left: 180px;
  }
  .mr-sm-180 {
    margin-right: 180px;
  }
  .pt-sm-180 {
    padding-top: 180px;
  }
  .pb-sm-180 {
    padding-bottom: 180px;
  }
  .pl-sm-180 {
    padding-left: 180px;
  }
  .pr-sm-180 {
    padding-right: 180px;
  }
  .mt-sm-185 {
    margin-top: 185px;
  }
  .mb-sm-185 {
    margin-bottom: 185px;
  }
  .ml-sm-185 {
    margin-left: 185px;
  }
  .mr-sm-185 {
    margin-right: 185px;
  }
  .pt-sm-185 {
    padding-top: 185px;
  }
  .pb-sm-185 {
    padding-bottom: 185px;
  }
  .pl-sm-185 {
    padding-left: 185px;
  }
  .pr-sm-185 {
    padding-right: 185px;
  }
  .mt-sm-190 {
    margin-top: 190px;
  }
  .mb-sm-190 {
    margin-bottom: 190px;
  }
  .ml-sm-190 {
    margin-left: 190px;
  }
  .mr-sm-190 {
    margin-right: 190px;
  }
  .pt-sm-190 {
    padding-top: 190px;
  }
  .pb-sm-190 {
    padding-bottom: 190px;
  }
  .pl-sm-190 {
    padding-left: 190px;
  }
  .pr-sm-190 {
    padding-right: 190px;
  }
  .mt-sm-195 {
    margin-top: 195px;
  }
  .mb-sm-195 {
    margin-bottom: 195px;
  }
  .ml-sm-195 {
    margin-left: 195px;
  }
  .mr-sm-195 {
    margin-right: 195px;
  }
  .pt-sm-195 {
    padding-top: 195px;
  }
  .pb-sm-195 {
    padding-bottom: 195px;
  }
  .pl-sm-195 {
    padding-left: 195px;
  }
  .pr-sm-195 {
    padding-right: 195px;
  }
  .mt-sm-200 {
    margin-top: 200px;
  }
  .mb-sm-200 {
    margin-bottom: 200px;
  }
  .ml-sm-200 {
    margin-left: 200px;
  }
  .mr-sm-200 {
    margin-right: 200px;
  }
  .pt-sm-200 {
    padding-top: 200px;
  }
  .pb-sm-200 {
    padding-bottom: 200px;
  }
  .pl-sm-200 {
    padding-left: 200px;
  }
  .pr-sm-200 {
    padding-right: 200px;
  }
  .mt-sm-205 {
    margin-top: 205px;
  }
  .mb-sm-205 {
    margin-bottom: 205px;
  }
  .ml-sm-205 {
    margin-left: 205px;
  }
  .mr-sm-205 {
    margin-right: 205px;
  }
  .pt-sm-205 {
    padding-top: 205px;
  }
  .pb-sm-205 {
    padding-bottom: 205px;
  }
  .pl-sm-205 {
    padding-left: 205px;
  }
  .pr-sm-205 {
    padding-right: 205px;
  }
  .mt-sm-210 {
    margin-top: 210px;
  }
  .mb-sm-210 {
    margin-bottom: 210px;
  }
  .ml-sm-210 {
    margin-left: 210px;
  }
  .mr-sm-210 {
    margin-right: 210px;
  }
  .pt-sm-210 {
    padding-top: 210px;
  }
  .pb-sm-210 {
    padding-bottom: 210px;
  }
  .pl-sm-210 {
    padding-left: 210px;
  }
  .pr-sm-210 {
    padding-right: 210px;
  }
  .mt-sm-215 {
    margin-top: 215px;
  }
  .mb-sm-215 {
    margin-bottom: 215px;
  }
  .ml-sm-215 {
    margin-left: 215px;
  }
  .mr-sm-215 {
    margin-right: 215px;
  }
  .pt-sm-215 {
    padding-top: 215px;
  }
  .pb-sm-215 {
    padding-bottom: 215px;
  }
  .pl-sm-215 {
    padding-left: 215px;
  }
  .pr-sm-215 {
    padding-right: 215px;
  }
  .mt-sm-220 {
    margin-top: 220px;
  }
  .mb-sm-220 {
    margin-bottom: 220px;
  }
  .ml-sm-220 {
    margin-left: 220px;
  }
  .mr-sm-220 {
    margin-right: 220px;
  }
  .pt-sm-220 {
    padding-top: 220px;
  }
  .pb-sm-220 {
    padding-bottom: 220px;
  }
  .pl-sm-220 {
    padding-left: 220px;
  }
  .pr-sm-220 {
    padding-right: 220px;
  }
}
/*

responsive css
md device
*/
@media all and (min-width: 768px) and (max-width: 991px) {
  .mt-md-5 {
    margin-top: 5px;
  }
  .mb-md-5 {
    margin-bottom: 5px;
  }
  .ml-md-5 {
    margin-left: 5px;
  }
  .mr-md-5 {
    margin-right: 5px;
  }
  .pt-md-5 {
    padding-top: 5px;
  }
  .pb-md-5 {
    padding-bottom: 5px;
  }
  .pl-md-5 {
    padding-left: 5px;
  }
  .pr-md-5 {
    padding-right: 5px;
  }
  .mt-md-10 {
    margin-top: 10px;
  }
  .mb-md-10 {
    margin-bottom: 10px;
  }
  .ml-md-10 {
    margin-left: 10px;
  }
  .mr-md-10 {
    margin-right: 10px;
  }
  .pt-md-10 {
    padding-top: 10px;
  }
  .pb-md-10 {
    padding-bottom: 10px;
  }
  .pl-md-10 {
    padding-left: 10px;
  }
  .pr-md-10 {
    padding-right: 10px;
  }
  .mt-md-15 {
    margin-top: 15px;
  }
  .mb-md-15 {
    margin-bottom: 15px;
  }
  .ml-md-15 {
    margin-left: 15px;
  }
  .mr-md-15 {
    margin-right: 15px;
  }
  .pt-md-15 {
    padding-top: 15px;
  }
  .pb-md-15 {
    padding-bottom: 15px;
  }
  .pl-md-15 {
    padding-left: 15px;
  }
  .pr-md-15 {
    padding-right: 15px;
  }
  .mt-md-20 {
    margin-top: 20px;
  }
  .mb-md-20 {
    margin-bottom: 20px;
  }
  .ml-md-20 {
    margin-left: 20px;
  }
  .mr-md-20 {
    margin-right: 20px;
  }
  .pt-md-20 {
    padding-top: 20px;
  }
  .pb-md-20 {
    padding-bottom: 20px;
  }
  .pl-md-20 {
    padding-left: 20px;
  }
  .pr-md-20 {
    padding-right: 20px;
  }
  .mt-md-25 {
    margin-top: 25px;
  }
  .mb-md-25 {
    margin-bottom: 25px;
  }
  .ml-md-25 {
    margin-left: 25px;
  }
  .mr-md-25 {
    margin-right: 25px;
  }
  .pt-md-25 {
    padding-top: 25px;
  }
  .pb-md-25 {
    padding-bottom: 25px;
  }
  .pl-md-25 {
    padding-left: 25px;
  }
  .pr-md-25 {
    padding-right: 25px;
  }
  .mt-md-30 {
    margin-top: 30px;
  }
  .mb-md-30 {
    margin-bottom: 30px;
  }
  .ml-md-30 {
    margin-left: 30px;
  }
  .mr-md-30 {
    margin-right: 30px;
  }
  .pt-md-30 {
    padding-top: 30px;
  }
  .pb-md-30 {
    padding-bottom: 30px;
  }
  .pl-md-30 {
    padding-left: 30px;
  }
  .pr-md-30 {
    padding-right: 30px;
  }
  .mt-md-35 {
    margin-top: 35px;
  }
  .mb-md-35 {
    margin-bottom: 35px;
  }
  .ml-md-35 {
    margin-left: 35px;
  }
  .mr-md-35 {
    margin-right: 35px;
  }
  .pt-md-35 {
    padding-top: 35px;
  }
  .pb-md-35 {
    padding-bottom: 35px;
  }
  .pl-md-35 {
    padding-left: 35px;
  }
  .pr-md-35 {
    padding-right: 35px;
  }
  .mt-md-40 {
    margin-top: 40px;
  }
  .mb-md-40 {
    margin-bottom: 40px;
  }
  .ml-md-40 {
    margin-left: 40px;
  }
  .mr-md-40 {
    margin-right: 40px;
  }
  .pt-md-40 {
    padding-top: 40px;
  }
  .pb-md-40 {
    padding-bottom: 40px;
  }
  .pl-md-40 {
    padding-left: 40px;
  }
  .pr-md-40 {
    padding-right: 40px;
  }
  .mt-md-45 {
    margin-top: 45px;
  }
  .mb-md-45 {
    margin-bottom: 45px;
  }
  .ml-md-45 {
    margin-left: 45px;
  }
  .mr-md-45 {
    margin-right: 45px;
  }
  .pt-md-45 {
    padding-top: 45px;
  }
  .pb-md-45 {
    padding-bottom: 45px;
  }
  .pl-md-45 {
    padding-left: 45px;
  }
  .pr-md-45 {
    padding-right: 45px;
  }
  .mt-md-50 {
    margin-top: 50px;
  }
  .mb-md-50 {
    margin-bottom: 50px;
  }
  .ml-md-50 {
    margin-left: 50px;
  }
  .mr-md-50 {
    margin-right: 50px;
  }
  .pt-md-50 {
    padding-top: 50px;
  }
  .pb-md-50 {
    padding-bottom: 50px;
  }
  .pl-md-50 {
    padding-left: 50px;
  }
  .pr-md-50 {
    padding-right: 50px;
  }
  .mt-md-55 {
    margin-top: 55px;
  }
  .mb-md-55 {
    margin-bottom: 55px;
  }
  .ml-md-55 {
    margin-left: 55px;
  }
  .mr-md-55 {
    margin-right: 55px;
  }
  .pt-md-55 {
    padding-top: 55px;
  }
  .pb-md-55 {
    padding-bottom: 55px;
  }
  .pl-md-55 {
    padding-left: 55px;
  }
  .pr-md-55 {
    padding-right: 55px;
  }
  .mt-md-60 {
    margin-top: 60px;
  }
  .mb-md-60 {
    margin-bottom: 60px;
  }
  .ml-md-60 {
    margin-left: 60px;
  }
  .mr-md-60 {
    margin-right: 60px;
  }
  .pt-md-60 {
    padding-top: 60px;
  }
  .pb-md-60 {
    padding-bottom: 60px;
  }
  .pl-md-60 {
    padding-left: 60px;
  }
  .pr-md-60 {
    padding-right: 60px;
  }
  .mt-md-65 {
    margin-top: 65px;
  }
  .mb-md-65 {
    margin-bottom: 65px;
  }
  .ml-md-65 {
    margin-left: 65px;
  }
  .mr-md-65 {
    margin-right: 65px;
  }
  .pt-md-65 {
    padding-top: 65px;
  }
  .pb-md-65 {
    padding-bottom: 65px;
  }
  .pl-md-65 {
    padding-left: 65px;
  }
  .pr-md-65 {
    padding-right: 65px;
  }
  .mt-md-70 {
    margin-top: 70px;
  }
  .mb-md-70 {
    margin-bottom: 70px;
  }
  .ml-md-70 {
    margin-left: 70px;
  }
  .mr-md-70 {
    margin-right: 70px;
  }
  .pt-md-70 {
    padding-top: 70px;
  }
  .pb-md-70 {
    padding-bottom: 70px;
  }
  .pl-md-70 {
    padding-left: 70px;
  }
  .pr-md-70 {
    padding-right: 70px;
  }
  .mt-md-75 {
    margin-top: 75px;
  }
  .mb-md-75 {
    margin-bottom: 75px;
  }
  .ml-md-75 {
    margin-left: 75px;
  }
  .mr-md-75 {
    margin-right: 75px;
  }
  .pt-md-75 {
    padding-top: 75px;
  }
  .pb-md-75 {
    padding-bottom: 75px;
  }
  .pl-md-75 {
    padding-left: 75px;
  }
  .pr-md-75 {
    padding-right: 75px;
  }
  .mt-md-80 {
    margin-top: 80px;
  }
  .mb-md-80 {
    margin-bottom: 80px;
  }
  .ml-md-80 {
    margin-left: 80px;
  }
  .mr-md-80 {
    margin-right: 80px;
  }
  .pt-md-80 {
    padding-top: 80px;
  }
  .pb-md-80 {
    padding-bottom: 80px;
  }
  .pl-md-80 {
    padding-left: 80px;
  }
  .pr-md-80 {
    padding-right: 80px;
  }
  .mt-md-85 {
    margin-top: 85px;
  }
  .mb-md-85 {
    margin-bottom: 85px;
  }
  .ml-md-85 {
    margin-left: 85px;
  }
  .mr-md-85 {
    margin-right: 85px;
  }
  .pt-md-85 {
    padding-top: 85px;
  }
  .pb-md-85 {
    padding-bottom: 85px;
  }
  .pl-md-85 {
    padding-left: 85px;
  }
  .pr-md-85 {
    padding-right: 85px;
  }
  .mt-md-90 {
    margin-top: 90px;
  }
  .mb-md-90 {
    margin-bottom: 90px;
  }
  .ml-md-90 {
    margin-left: 90px;
  }
  .mr-md-90 {
    margin-right: 90px;
  }
  .pt-md-90 {
    padding-top: 90px;
  }
  .pb-md-90 {
    padding-bottom: 90px;
  }
  .pl-md-90 {
    padding-left: 90px;
  }
  .pr-md-90 {
    padding-right: 90px;
  }
  .mt-md-95 {
    margin-top: 95px;
  }
  .mb-md-95 {
    margin-bottom: 95px;
  }
  .ml-md-95 {
    margin-left: 95px;
  }
  .mr-md-95 {
    margin-right: 95px;
  }
  .pt-md-95 {
    padding-top: 95px;
  }
  .pb-md-95 {
    padding-bottom: 95px;
  }
  .pl-md-95 {
    padding-left: 95px;
  }
  .pr-md-95 {
    padding-right: 95px;
  }
  .mt-md-100 {
    margin-top: 100px;
  }
  .mb-md-100 {
    margin-bottom: 100px;
  }
  .ml-md-100 {
    margin-left: 100px;
  }
  .mr-md-100 {
    margin-right: 100px;
  }
  .pt-md-100 {
    padding-top: 100px;
  }
  .pb-md-100 {
    padding-bottom: 100px;
  }
  .pl-md-100 {
    padding-left: 100px;
  }
  .pr-md-100 {
    padding-right: 100px;
  }
  .mt-md-105 {
    margin-top: 105px;
  }
  .mb-md-105 {
    margin-bottom: 105px;
  }
  .ml-md-105 {
    margin-left: 105px;
  }
  .mr-md-105 {
    margin-right: 105px;
  }
  .pt-md-105 {
    padding-top: 105px;
  }
  .pb-md-105 {
    padding-bottom: 105px;
  }
  .pl-md-105 {
    padding-left: 105px;
  }
  .pr-md-105 {
    padding-right: 105px;
  }
  .mt-md-110 {
    margin-top: 110px;
  }
  .mb-md-110 {
    margin-bottom: 110px;
  }
  .ml-md-110 {
    margin-left: 110px;
  }
  .mr-md-110 {
    margin-right: 110px;
  }
  .pt-md-110 {
    padding-top: 110px;
  }
  .pb-md-110 {
    padding-bottom: 110px;
  }
  .pl-md-110 {
    padding-left: 110px;
  }
  .pr-md-110 {
    padding-right: 110px;
  }
  .mt-md-115 {
    margin-top: 115px;
  }
  .mb-md-115 {
    margin-bottom: 115px;
  }
  .ml-md-115 {
    margin-left: 115px;
  }
  .mr-md-115 {
    margin-right: 115px;
  }
  .pt-md-115 {
    padding-top: 115px;
  }
  .pb-md-115 {
    padding-bottom: 115px;
  }
  .pl-md-115 {
    padding-left: 115px;
  }
  .pr-md-115 {
    padding-right: 115px;
  }
  .mt-md-120 {
    margin-top: 120px;
  }
  .mb-md-120 {
    margin-bottom: 120px;
  }
  .ml-md-120 {
    margin-left: 120px;
  }
  .mr-md-120 {
    margin-right: 120px;
  }
  .pt-md-120 {
    padding-top: 120px;
  }
  .pb-md-120 {
    padding-bottom: 120px;
  }
  .pl-md-120 {
    padding-left: 120px;
  }
  .pr-md-120 {
    padding-right: 120px;
  }
  .mt-md-125 {
    margin-top: 125px;
  }
  .mb-md-125 {
    margin-bottom: 125px;
  }
  .ml-md-125 {
    margin-left: 125px;
  }
  .mr-md-125 {
    margin-right: 125px;
  }
  .pt-md-125 {
    padding-top: 125px;
  }
  .pb-md-125 {
    padding-bottom: 125px;
  }
  .pl-md-125 {
    padding-left: 125px;
  }
  .pr-md-125 {
    padding-right: 125px;
  }
  .mt-md-130 {
    margin-top: 130px;
  }
  .mb-md-130 {
    margin-bottom: 130px;
  }
  .ml-md-130 {
    margin-left: 130px;
  }
  .mr-md-130 {
    margin-right: 130px;
  }
  .pt-md-130 {
    padding-top: 130px;
  }
  .pb-md-130 {
    padding-bottom: 130px;
  }
  .pl-md-130 {
    padding-left: 130px;
  }
  .pr-md-130 {
    padding-right: 130px;
  }
  .mt-md-135 {
    margin-top: 135px;
  }
  .mb-md-135 {
    margin-bottom: 135px;
  }
  .ml-md-135 {
    margin-left: 135px;
  }
  .mr-md-135 {
    margin-right: 135px;
  }
  .pt-md-135 {
    padding-top: 135px;
  }
  .pb-md-135 {
    padding-bottom: 135px;
  }
  .pl-md-135 {
    padding-left: 135px;
  }
  .pr-md-135 {
    padding-right: 135px;
  }
  .mt-md-140 {
    margin-top: 140px;
  }
  .mb-md-140 {
    margin-bottom: 140px;
  }
  .ml-md-140 {
    margin-left: 140px;
  }
  .mr-md-140 {
    margin-right: 140px;
  }
  .pt-md-140 {
    padding-top: 140px;
  }
  .pb-md-140 {
    padding-bottom: 140px;
  }
  .pl-md-140 {
    padding-left: 140px;
  }
  .pr-md-140 {
    padding-right: 140px;
  }
  .mt-md-145 {
    margin-top: 145px;
  }
  .mb-md-145 {
    margin-bottom: 145px;
  }
  .ml-md-145 {
    margin-left: 145px;
  }
  .mr-md-145 {
    margin-right: 145px;
  }
  .pt-md-145 {
    padding-top: 145px;
  }
  .pb-md-145 {
    padding-bottom: 145px;
  }
  .pl-md-145 {
    padding-left: 145px;
  }
  .pr-md-145 {
    padding-right: 145px;
  }
  .mt-md-150 {
    margin-top: 150px;
  }
  .mb-md-150 {
    margin-bottom: 150px;
  }
  .ml-md-150 {
    margin-left: 150px;
  }
  .mr-md-150 {
    margin-right: 150px;
  }
  .pt-md-150 {
    padding-top: 150px;
  }
  .pb-md-150 {
    padding-bottom: 150px;
  }
  .pl-md-150 {
    padding-left: 150px;
  }
  .pr-md-150 {
    padding-right: 150px;
  }
  .mt-md-155 {
    margin-top: 155px;
  }
  .mb-md-155 {
    margin-bottom: 155px;
  }
  .ml-md-155 {
    margin-left: 155px;
  }
  .mr-md-155 {
    margin-right: 155px;
  }
  .pt-md-155 {
    padding-top: 155px;
  }
  .pb-md-155 {
    padding-bottom: 155px;
  }
  .pl-md-155 {
    padding-left: 155px;
  }
  .pr-md-155 {
    padding-right: 155px;
  }
  .mt-md-160 {
    margin-top: 160px;
  }
  .mb-md-160 {
    margin-bottom: 160px;
  }
  .ml-md-160 {
    margin-left: 160px;
  }
  .mr-md-160 {
    margin-right: 160px;
  }
  .pt-md-160 {
    padding-top: 160px;
  }
  .pb-md-160 {
    padding-bottom: 160px;
  }
  .pl-md-160 {
    padding-left: 160px;
  }
  .pr-md-160 {
    padding-right: 160px;
  }
  .mt-md-165 {
    margin-top: 165px;
  }
  .mb-md-165 {
    margin-bottom: 165px;
  }
  .ml-md-165 {
    margin-left: 165px;
  }
  .mr-md-165 {
    margin-right: 165px;
  }
  .pt-md-165 {
    padding-top: 165px;
  }
  .pb-md-165 {
    padding-bottom: 165px;
  }
  .pl-md-165 {
    padding-left: 165px;
  }
  .pr-md-165 {
    padding-right: 165px;
  }
  .mt-md-170 {
    margin-top: 170px;
  }
  .mb-md-170 {
    margin-bottom: 170px;
  }
  .ml-md-170 {
    margin-left: 170px;
  }
  .mr-md-170 {
    margin-right: 170px;
  }
  .pt-md-170 {
    padding-top: 170px;
  }
  .pb-md-170 {
    padding-bottom: 170px;
  }
  .pl-md-170 {
    padding-left: 170px;
  }
  .pr-md-170 {
    padding-right: 170px;
  }
  .mt-md-175 {
    margin-top: 175px;
  }
  .mb-md-175 {
    margin-bottom: 175px;
  }
  .ml-md-175 {
    margin-left: 175px;
  }
  .mr-md-175 {
    margin-right: 175px;
  }
  .pt-md-175 {
    padding-top: 175px;
  }
  .pb-md-175 {
    padding-bottom: 175px;
  }
  .pl-md-175 {
    padding-left: 175px;
  }
  .pr-md-175 {
    padding-right: 175px;
  }
  .mt-md-180 {
    margin-top: 180px;
  }
  .mb-md-180 {
    margin-bottom: 180px;
  }
  .ml-md-180 {
    margin-left: 180px;
  }
  .mr-md-180 {
    margin-right: 180px;
  }
  .pt-md-180 {
    padding-top: 180px;
  }
  .pb-md-180 {
    padding-bottom: 180px;
  }
  .pl-md-180 {
    padding-left: 180px;
  }
  .pr-md-180 {
    padding-right: 180px;
  }
  .mt-md-185 {
    margin-top: 185px;
  }
  .mb-md-185 {
    margin-bottom: 185px;
  }
  .ml-md-185 {
    margin-left: 185px;
  }
  .mr-md-185 {
    margin-right: 185px;
  }
  .pt-md-185 {
    padding-top: 185px;
  }
  .pb-md-185 {
    padding-bottom: 185px;
  }
  .pl-md-185 {
    padding-left: 185px;
  }
  .pr-md-185 {
    padding-right: 185px;
  }
  .mt-md-190 {
    margin-top: 190px;
  }
  .mb-md-190 {
    margin-bottom: 190px;
  }
  .ml-md-190 {
    margin-left: 190px;
  }
  .mr-md-190 {
    margin-right: 190px;
  }
  .pt-md-190 {
    padding-top: 190px;
  }
  .pb-md-190 {
    padding-bottom: 190px;
  }
  .pl-md-190 {
    padding-left: 190px;
  }
  .pr-md-190 {
    padding-right: 190px;
  }
  .mt-md-195 {
    margin-top: 195px;
  }
  .mb-md-195 {
    margin-bottom: 195px;
  }
  .ml-md-195 {
    margin-left: 195px;
  }
  .mr-md-195 {
    margin-right: 195px;
  }
  .pt-md-195 {
    padding-top: 195px;
  }
  .pb-md-195 {
    padding-bottom: 195px;
  }
  .pl-md-195 {
    padding-left: 195px;
  }
  .pr-md-195 {
    padding-right: 195px;
  }
  .mt-md-200 {
    margin-top: 200px;
  }
  .mb-md-200 {
    margin-bottom: 200px;
  }
  .ml-md-200 {
    margin-left: 200px;
  }
  .mr-md-200 {
    margin-right: 200px;
  }
  .pt-md-200 {
    padding-top: 200px;
  }
  .pb-md-200 {
    padding-bottom: 200px;
  }
  .pl-md-200 {
    padding-left: 200px;
  }
  .pr-md-200 {
    padding-right: 200px;
  }
  .mt-md-205 {
    margin-top: 205px;
  }
  .mb-md-205 {
    margin-bottom: 205px;
  }
  .ml-md-205 {
    margin-left: 205px;
  }
  .mr-md-205 {
    margin-right: 205px;
  }
  .pt-md-205 {
    padding-top: 205px;
  }
  .pb-md-205 {
    padding-bottom: 205px;
  }
  .pl-md-205 {
    padding-left: 205px;
  }
  .pr-md-205 {
    padding-right: 205px;
  }
  .mt-md-210 {
    margin-top: 210px;
  }
  .mb-md-210 {
    margin-bottom: 210px;
  }
  .ml-md-210 {
    margin-left: 210px;
  }
  .mr-md-210 {
    margin-right: 210px;
  }
  .pt-md-210 {
    padding-top: 210px;
  }
  .pb-md-210 {
    padding-bottom: 210px;
  }
  .pl-md-210 {
    padding-left: 210px;
  }
  .pr-md-210 {
    padding-right: 210px;
  }
  .mt-md-215 {
    margin-top: 215px;
  }
  .mb-md-215 {
    margin-bottom: 215px;
  }
  .ml-md-215 {
    margin-left: 215px;
  }
  .mr-md-215 {
    margin-right: 215px;
  }
  .pt-md-215 {
    padding-top: 215px;
  }
  .pb-md-215 {
    padding-bottom: 215px;
  }
  .pl-md-215 {
    padding-left: 215px;
  }
  .pr-md-215 {
    padding-right: 215px;
  }
  .mt-md-220 {
    margin-top: 220px;
  }
  .mb-md-220 {
    margin-bottom: 220px;
  }
  .ml-md-220 {
    margin-left: 220px;
  }
  .mr-md-220 {
    margin-right: 220px;
  }
  .pt-md-220 {
    padding-top: 220px;
  }
  .pb-md-220 {
    padding-bottom: 220px;
  }
  .pl-md-220 {
    padding-left: 220px;
  }
  .pr-md-220 {
    padding-right: 220px;
  }
}
/*
responsive css
lg device
*/
@media all and (min-width: 992px) and (max-width: 1199px) {
  .mt-lg-5 {
    margin-top: 5px;
  }
  .mb-lg-5 {
    margin-bottom: 5px;
  }
  .ml-lg-5 {
    margin-left: 5px;
  }
  .mr-lg-5 {
    margin-right: 5px;
  }
  .pt-lg-5 {
    padding-top: 5px;
  }
  .pb-lg-5 {
    padding-bottom: 5px;
  }
  .pl-lg-5 {
    padding-left: 5px;
  }
  .pr-lg-5 {
    padding-right: 5px;
  }
  .mt-lg-10 {
    margin-top: 10px;
  }
  .mb-lg-10 {
    margin-bottom: 10px;
  }
  .ml-lg-10 {
    margin-left: 10px;
  }
  .mr-lg-10 {
    margin-right: 10px;
  }
  .pt-lg-10 {
    padding-top: 10px;
  }
  .pb-lg-10 {
    padding-bottom: 10px;
  }
  .pl-lg-10 {
    padding-left: 10px;
  }
  .pr-lg-10 {
    padding-right: 10px;
  }
  .mt-lg-15 {
    margin-top: 15px;
  }
  .mb-lg-15 {
    margin-bottom: 15px;
  }
  .ml-lg-15 {
    margin-left: 15px;
  }
  .mr-lg-15 {
    margin-right: 15px;
  }
  .pt-lg-15 {
    padding-top: 15px;
  }
  .pb-lg-15 {
    padding-bottom: 15px;
  }
  .pl-lg-15 {
    padding-left: 15px;
  }
  .pr-lg-15 {
    padding-right: 15px;
  }
  .mt-lg-20 {
    margin-top: 20px;
  }
  .mb-lg-20 {
    margin-bottom: 20px;
  }
  .ml-lg-20 {
    margin-left: 20px;
  }
  .mr-lg-20 {
    margin-right: 20px;
  }
  .pt-lg-20 {
    padding-top: 20px;
  }
  .pb-lg-20 {
    padding-bottom: 20px;
  }
  .pl-lg-20 {
    padding-left: 20px;
  }
  .pr-lg-20 {
    padding-right: 20px;
  }
  .mt-lg-25 {
    margin-top: 25px;
  }
  .mb-lg-25 {
    margin-bottom: 25px;
  }
  .ml-lg-25 {
    margin-left: 25px;
  }
  .mr-lg-25 {
    margin-right: 25px;
  }
  .pt-lg-25 {
    padding-top: 25px;
  }
  .pb-lg-25 {
    padding-bottom: 25px;
  }
  .pl-lg-25 {
    padding-left: 25px;
  }
  .pr-lg-25 {
    padding-right: 25px;
  }
  .mt-lg-30 {
    margin-top: 30px;
  }
  .mb-lg-30 {
    margin-bottom: 30px;
  }
  .ml-lg-30 {
    margin-left: 30px;
  }
  .mr-lg-30 {
    margin-right: 30px;
  }
  .pt-lg-30 {
    padding-top: 30px;
  }
  .pb-lg-30 {
    padding-bottom: 30px;
  }
  .pl-lg-30 {
    padding-left: 30px;
  }
  .pr-lg-30 {
    padding-right: 30px;
  }
  .mt-lg-35 {
    margin-top: 35px;
  }
  .mb-lg-35 {
    margin-bottom: 35px;
  }
  .ml-lg-35 {
    margin-left: 35px;
  }
  .mr-lg-35 {
    margin-right: 35px;
  }
  .pt-lg-35 {
    padding-top: 35px;
  }
  .pb-lg-35 {
    padding-bottom: 35px;
  }
  .pl-lg-35 {
    padding-left: 35px;
  }
  .pr-lg-35 {
    padding-right: 35px;
  }
  .mt-lg-40 {
    margin-top: 40px;
  }
  .mb-lg-40 {
    margin-bottom: 40px;
  }
  .ml-lg-40 {
    margin-left: 40px;
  }
  .mr-lg-40 {
    margin-right: 40px;
  }
  .pt-lg-40 {
    padding-top: 40px;
  }
  .pb-lg-40 {
    padding-bottom: 40px;
  }
  .pl-lg-40 {
    padding-left: 40px;
  }
  .pr-lg-40 {
    padding-right: 40px;
  }
  .mt-lg-45 {
    margin-top: 45px;
  }
  .mb-lg-45 {
    margin-bottom: 45px;
  }
  .ml-lg-45 {
    margin-left: 45px;
  }
  .mr-lg-45 {
    margin-right: 45px;
  }
  .pt-lg-45 {
    padding-top: 45px;
  }
  .pb-lg-45 {
    padding-bottom: 45px;
  }
  .pl-lg-45 {
    padding-left: 45px;
  }
  .pr-lg-45 {
    padding-right: 45px;
  }
  .mt-lg-50 {
    margin-top: 50px;
  }
  .mb-lg-50 {
    margin-bottom: 50px;
  }
  .ml-lg-50 {
    margin-left: 50px;
  }
  .mr-lg-50 {
    margin-right: 50px;
  }
  .pt-lg-50 {
    padding-top: 50px;
  }
  .pb-lg-50 {
    padding-bottom: 50px;
  }
  .pl-lg-50 {
    padding-left: 50px;
  }
  .pr-lg-50 {
    padding-right: 50px;
  }
  .mt-lg-55 {
    margin-top: 55px;
  }
  .mb-lg-55 {
    margin-bottom: 55px;
  }
  .ml-lg-55 {
    margin-left: 55px;
  }
  .mr-lg-55 {
    margin-right: 55px;
  }
  .pt-lg-55 {
    padding-top: 55px;
  }
  .pb-lg-55 {
    padding-bottom: 55px;
  }
  .pl-lg-55 {
    padding-left: 55px;
  }
  .pr-lg-55 {
    padding-right: 55px;
  }
  .mt-lg-60 {
    margin-top: 60px;
  }
  .mb-lg-60 {
    margin-bottom: 60px;
  }
  .ml-lg-60 {
    margin-left: 60px;
  }
  .mr-lg-60 {
    margin-right: 60px;
  }
  .pt-lg-60 {
    padding-top: 60px;
  }
  .pb-lg-60 {
    padding-bottom: 60px;
  }
  .pl-lg-60 {
    padding-left: 60px;
  }
  .pr-lg-60 {
    padding-right: 60px;
  }
  .mt-lg-65 {
    margin-top: 65px;
  }
  .mb-lg-65 {
    margin-bottom: 65px;
  }
  .ml-lg-65 {
    margin-left: 65px;
  }
  .mr-lg-65 {
    margin-right: 65px;
  }
  .pt-lg-65 {
    padding-top: 65px;
  }
  .pb-lg-65 {
    padding-bottom: 65px;
  }
  .pl-lg-65 {
    padding-left: 65px;
  }
  .pr-lg-65 {
    padding-right: 65px;
  }
  .mt-lg-70 {
    margin-top: 70px;
  }
  .mb-lg-70 {
    margin-bottom: 70px;
  }
  .ml-lg-70 {
    margin-left: 70px;
  }
  .mr-lg-70 {
    margin-right: 70px;
  }
  .pt-lg-70 {
    padding-top: 70px;
  }
  .pb-lg-70 {
    padding-bottom: 70px;
  }
  .pl-lg-70 {
    padding-left: 70px;
  }
  .pr-lg-70 {
    padding-right: 70px;
  }
  .mt-lg-75 {
    margin-top: 75px;
  }
  .mb-lg-75 {
    margin-bottom: 75px;
  }
  .ml-lg-75 {
    margin-left: 75px;
  }
  .mr-lg-75 {
    margin-right: 75px;
  }
  .pt-lg-75 {
    padding-top: 75px;
  }
  .pb-lg-75 {
    padding-bottom: 75px;
  }
  .pl-lg-75 {
    padding-left: 75px;
  }
  .pr-lg-75 {
    padding-right: 75px;
  }
  .mt-lg-80 {
    margin-top: 80px;
  }
  .mb-lg-80 {
    margin-bottom: 80px;
  }
  .ml-lg-80 {
    margin-left: 80px;
  }
  .mr-lg-80 {
    margin-right: 80px;
  }
  .pt-lg-80 {
    padding-top: 80px;
  }
  .pb-lg-80 {
    padding-bottom: 80px;
  }
  .pl-lg-80 {
    padding-left: 80px;
  }
  .pr-lg-80 {
    padding-right: 80px;
  }
  .mt-lg-85 {
    margin-top: 85px;
  }
  .mb-lg-85 {
    margin-bottom: 85px;
  }
  .ml-lg-85 {
    margin-left: 85px;
  }
  .mr-lg-85 {
    margin-right: 85px;
  }
  .pt-lg-85 {
    padding-top: 85px;
  }
  .pb-lg-85 {
    padding-bottom: 85px;
  }
  .pl-lg-85 {
    padding-left: 85px;
  }
  .pr-lg-85 {
    padding-right: 85px;
  }
  .mt-lg-90 {
    margin-top: 90px;
  }
  .mb-lg-90 {
    margin-bottom: 90px;
  }
  .ml-lg-90 {
    margin-left: 90px;
  }
  .mr-lg-90 {
    margin-right: 90px;
  }
  .pt-lg-90 {
    padding-top: 90px;
  }
  .pb-lg-90 {
    padding-bottom: 90px;
  }
  .pl-lg-90 {
    padding-left: 90px;
  }
  .pr-lg-90 {
    padding-right: 90px;
  }
  .mt-lg-95 {
    margin-top: 95px;
  }
  .mb-lg-95 {
    margin-bottom: 95px;
  }
  .ml-lg-95 {
    margin-left: 95px;
  }
  .mr-lg-95 {
    margin-right: 95px;
  }
  .pt-lg-95 {
    padding-top: 95px;
  }
  .pb-lg-95 {
    padding-bottom: 95px;
  }
  .pl-lg-95 {
    padding-left: 95px;
  }
  .pr-lg-95 {
    padding-right: 95px;
  }
  .mt-lg-100 {
    margin-top: 100px;
  }
  .mb-lg-100 {
    margin-bottom: 100px;
  }
  .ml-lg-100 {
    margin-left: 100px;
  }
  .mr-lg-100 {
    margin-right: 100px;
  }
  .pt-lg-100 {
    padding-top: 100px;
  }
  .pb-lg-100 {
    padding-bottom: 100px;
  }
  .pl-lg-100 {
    padding-left: 100px;
  }
  .pr-lg-100 {
    padding-right: 100px;
  }
  .mt-lg-105 {
    margin-top: 105px;
  }
  .mb-lg-105 {
    margin-bottom: 105px;
  }
  .ml-lg-105 {
    margin-left: 105px;
  }
  .mr-lg-105 {
    margin-right: 105px;
  }
  .pt-lg-105 {
    padding-top: 105px;
  }
  .pb-lg-105 {
    padding-bottom: 105px;
  }
  .pl-lg-105 {
    padding-left: 105px;
  }
  .pr-lg-105 {
    padding-right: 105px;
  }
  .mt-lg-110 {
    margin-top: 110px;
  }
  .mb-lg-110 {
    margin-bottom: 110px;
  }
  .ml-lg-110 {
    margin-left: 110px;
  }
  .mr-lg-110 {
    margin-right: 110px;
  }
  .pt-lg-110 {
    padding-top: 110px;
  }
  .pb-lg-110 {
    padding-bottom: 110px;
  }
  .pl-lg-110 {
    padding-left: 110px;
  }
  .pr-lg-110 {
    padding-right: 110px;
  }
  .mt-lg-115 {
    margin-top: 115px;
  }
  .mb-lg-115 {
    margin-bottom: 115px;
  }
  .ml-lg-115 {
    margin-left: 115px;
  }
  .mr-lg-115 {
    margin-right: 115px;
  }
  .pt-lg-115 {
    padding-top: 115px;
  }
  .pb-lg-115 {
    padding-bottom: 115px;
  }
  .pl-lg-115 {
    padding-left: 115px;
  }
  .pr-lg-115 {
    padding-right: 115px;
  }
  .mt-lg-120 {
    margin-top: 120px;
  }
  .mb-lg-120 {
    margin-bottom: 120px;
  }
  .ml-lg-120 {
    margin-left: 120px;
  }
  .mr-lg-120 {
    margin-right: 120px;
  }
  .pt-lg-120 {
    padding-top: 120px;
  }
  .pb-lg-120 {
    padding-bottom: 120px;
  }
  .pl-lg-120 {
    padding-left: 120px;
  }
  .pr-lg-120 {
    padding-right: 120px;
  }
  .mt-lg-125 {
    margin-top: 125px;
  }
  .mb-lg-125 {
    margin-bottom: 125px;
  }
  .ml-lg-125 {
    margin-left: 125px;
  }
  .mr-lg-125 {
    margin-right: 125px;
  }
  .pt-lg-125 {
    padding-top: 125px;
  }
  .pb-lg-125 {
    padding-bottom: 125px;
  }
  .pl-lg-125 {
    padding-left: 125px;
  }
  .pr-lg-125 {
    padding-right: 125px;
  }
  .mt-lg-130 {
    margin-top: 130px;
  }
  .mb-lg-130 {
    margin-bottom: 130px;
  }
  .ml-lg-130 {
    margin-left: 130px;
  }
  .mr-lg-130 {
    margin-right: 130px;
  }
  .pt-lg-130 {
    padding-top: 130px;
  }
  .pb-lg-130 {
    padding-bottom: 130px;
  }
  .pl-lg-130 {
    padding-left: 130px;
  }
  .pr-lg-130 {
    padding-right: 130px;
  }
  .mt-lg-135 {
    margin-top: 135px;
  }
  .mb-lg-135 {
    margin-bottom: 135px;
  }
  .ml-lg-135 {
    margin-left: 135px;
  }
  .mr-lg-135 {
    margin-right: 135px;
  }
  .pt-lg-135 {
    padding-top: 135px;
  }
  .pb-lg-135 {
    padding-bottom: 135px;
  }
  .pl-lg-135 {
    padding-left: 135px;
  }
  .pr-lg-135 {
    padding-right: 135px;
  }
  .mt-lg-140 {
    margin-top: 140px;
  }
  .mb-lg-140 {
    margin-bottom: 140px;
  }
  .ml-lg-140 {
    margin-left: 140px;
  }
  .mr-lg-140 {
    margin-right: 140px;
  }
  .pt-lg-140 {
    padding-top: 140px;
  }
  .pb-lg-140 {
    padding-bottom: 140px;
  }
  .pl-lg-140 {
    padding-left: 140px;
  }
  .pr-lg-140 {
    padding-right: 140px;
  }
  .mt-lg-145 {
    margin-top: 145px;
  }
  .mb-lg-145 {
    margin-bottom: 145px;
  }
  .ml-lg-145 {
    margin-left: 145px;
  }
  .mr-lg-145 {
    margin-right: 145px;
  }
  .pt-lg-145 {
    padding-top: 145px;
  }
  .pb-lg-145 {
    padding-bottom: 145px;
  }
  .pl-lg-145 {
    padding-left: 145px;
  }
  .pr-lg-145 {
    padding-right: 145px;
  }
  .mt-lg-150 {
    margin-top: 150px;
  }
  .mb-lg-150 {
    margin-bottom: 150px;
  }
  .ml-lg-150 {
    margin-left: 150px;
  }
  .mr-lg-150 {
    margin-right: 150px;
  }
  .pt-lg-150 {
    padding-top: 150px;
  }
  .pb-lg-150 {
    padding-bottom: 150px;
  }
  .pl-lg-150 {
    padding-left: 150px;
  }
  .pr-lg-150 {
    padding-right: 150px;
  }
  .mt-lg-155 {
    margin-top: 155px;
  }
  .mb-lg-155 {
    margin-bottom: 155px;
  }
  .ml-lg-155 {
    margin-left: 155px;
  }
  .mr-lg-155 {
    margin-right: 155px;
  }
  .pt-lg-155 {
    padding-top: 155px;
  }
  .pb-lg-155 {
    padding-bottom: 155px;
  }
  .pl-lg-155 {
    padding-left: 155px;
  }
  .pr-lg-155 {
    padding-right: 155px;
  }
  .mt-lg-160 {
    margin-top: 160px;
  }
  .mb-lg-160 {
    margin-bottom: 160px;
  }
  .ml-lg-160 {
    margin-left: 160px;
  }
  .mr-lg-160 {
    margin-right: 160px;
  }
  .pt-lg-160 {
    padding-top: 160px;
  }
  .pb-lg-160 {
    padding-bottom: 160px;
  }
  .pl-lg-160 {
    padding-left: 160px;
  }
  .pr-lg-160 {
    padding-right: 160px;
  }
  .mt-lg-165 {
    margin-top: 165px;
  }
  .mb-lg-165 {
    margin-bottom: 165px;
  }
  .ml-lg-165 {
    margin-left: 165px;
  }
  .mr-lg-165 {
    margin-right: 165px;
  }
  .pt-lg-165 {
    padding-top: 165px;
  }
  .pb-lg-165 {
    padding-bottom: 165px;
  }
  .pl-lg-165 {
    padding-left: 165px;
  }
  .pr-lg-165 {
    padding-right: 165px;
  }
  .mt-lg-170 {
    margin-top: 170px;
  }
  .mb-lg-170 {
    margin-bottom: 170px;
  }
  .ml-lg-170 {
    margin-left: 170px;
  }
  .mr-lg-170 {
    margin-right: 170px;
  }
  .pt-lg-170 {
    padding-top: 170px;
  }
  .pb-lg-170 {
    padding-bottom: 170px;
  }
  .pl-lg-170 {
    padding-left: 170px;
  }
  .pr-lg-170 {
    padding-right: 170px;
  }
  .mt-lg-175 {
    margin-top: 175px;
  }
  .mb-lg-175 {
    margin-bottom: 175px;
  }
  .ml-lg-175 {
    margin-left: 175px;
  }
  .mr-lg-175 {
    margin-right: 175px;
  }
  .pt-lg-175 {
    padding-top: 175px;
  }
  .pb-lg-175 {
    padding-bottom: 175px;
  }
  .pl-lg-175 {
    padding-left: 175px;
  }
  .pr-lg-175 {
    padding-right: 175px;
  }
  .mt-lg-180 {
    margin-top: 180px;
  }
  .mb-lg-180 {
    margin-bottom: 180px;
  }
  .ml-lg-180 {
    margin-left: 180px;
  }
  .mr-lg-180 {
    margin-right: 180px;
  }
  .pt-lg-180 {
    padding-top: 180px;
  }
  .pb-lg-180 {
    padding-bottom: 180px;
  }
  .pl-lg-180 {
    padding-left: 180px;
  }
  .pr-lg-180 {
    padding-right: 180px;
  }
  .mt-lg-185 {
    margin-top: 185px;
  }
  .mb-lg-185 {
    margin-bottom: 185px;
  }
  .ml-lg-185 {
    margin-left: 185px;
  }
  .mr-lg-185 {
    margin-right: 185px;
  }
  .pt-lg-185 {
    padding-top: 185px;
  }
  .pb-lg-185 {
    padding-bottom: 185px;
  }
  .pl-lg-185 {
    padding-left: 185px;
  }
  .pr-lg-185 {
    padding-right: 185px;
  }
  .mt-lg-190 {
    margin-top: 190px;
  }
  .mb-lg-190 {
    margin-bottom: 190px;
  }
  .ml-lg-190 {
    margin-left: 190px;
  }
  .mr-lg-190 {
    margin-right: 190px;
  }
  .pt-lg-190 {
    padding-top: 190px;
  }
  .pb-lg-190 {
    padding-bottom: 190px;
  }
  .pl-lg-190 {
    padding-left: 190px;
  }
  .pr-lg-190 {
    padding-right: 190px;
  }
  .mt-lg-195 {
    margin-top: 195px;
  }
  .mb-lg-195 {
    margin-bottom: 195px;
  }
  .ml-lg-195 {
    margin-left: 195px;
  }
  .mr-lg-195 {
    margin-right: 195px;
  }
  .pt-lg-195 {
    padding-top: 195px;
  }
  .pb-lg-195 {
    padding-bottom: 195px;
  }
  .pl-lg-195 {
    padding-left: 195px;
  }
  .pr-lg-195 {
    padding-right: 195px;
  }
  .mt-lg-200 {
    margin-top: 200px;
  }
  .mb-lg-200 {
    margin-bottom: 200px;
  }
  .ml-lg-200 {
    margin-left: 200px;
  }
  .mr-lg-200 {
    margin-right: 200px;
  }
  .pt-lg-200 {
    padding-top: 200px;
  }
  .pb-lg-200 {
    padding-bottom: 200px;
  }
  .pl-lg-200 {
    padding-left: 200px;
  }
  .pr-lg-200 {
    padding-right: 200px;
  }
  .mt-lg-205 {
    margin-top: 205px;
  }
  .mb-lg-205 {
    margin-bottom: 205px;
  }
  .ml-lg-205 {
    margin-left: 205px;
  }
  .mr-lg-205 {
    margin-right: 205px;
  }
  .pt-lg-205 {
    padding-top: 205px;
  }
  .pb-lg-205 {
    padding-bottom: 205px;
  }
  .pl-lg-205 {
    padding-left: 205px;
  }
  .pr-lg-205 {
    padding-right: 205px;
  }
  .mt-lg-210 {
    margin-top: 210px;
  }
  .mb-lg-210 {
    margin-bottom: 210px;
  }
  .ml-lg-210 {
    margin-left: 210px;
  }
  .mr-lg-210 {
    margin-right: 210px;
  }
  .pt-lg-210 {
    padding-top: 210px;
  }
  .pb-lg-210 {
    padding-bottom: 210px;
  }
  .pl-lg-210 {
    padding-left: 210px;
  }
  .pr-lg-210 {
    padding-right: 210px;
  }
  .mt-lg-215 {
    margin-top: 215px;
  }
  .mb-lg-215 {
    margin-bottom: 215px;
  }
  .ml-lg-215 {
    margin-left: 215px;
  }
  .mr-lg-215 {
    margin-right: 215px;
  }
  .pt-lg-215 {
    padding-top: 215px;
  }
  .pb-lg-215 {
    padding-bottom: 215px;
  }
  .pl-lg-215 {
    padding-left: 215px;
  }
  .pr-lg-215 {
    padding-right: 215px;
  }
  .mt-lg-220 {
    margin-top: 220px;
  }
  .mb-lg-220 {
    margin-bottom: 220px;
  }
  .ml-lg-220 {
    margin-left: 220px;
  }
  .mr-lg-220 {
    margin-right: 220px;
  }
  .pt-lg-220 {
    padding-top: 220px;
  }
  .pb-lg-220 {
    padding-bottom: 220px;
  }
  .pl-lg-220 {
    padding-left: 220px;
  }
  .pr-lg-220 {
    padding-right: 220px;
  }
}
/*
responsive css
xl device
*/
@media all and (min-width: 1200px) and (max-width: 1400px) {
  .mt-xl-5 {
    margin-top: 5px;
  }
  .mb-xl-5 {
    margin-bottom: 5px;
  }
  .ml-xl-5 {
    margin-left: 5px;
  }
  .mr-xl-5 {
    margin-right: 5px;
  }
  .pt-xl-5 {
    padding-top: 5px;
  }
  .pb-xl-5 {
    padding-bottom: 5px;
  }
  .pl-xl-5 {
    padding-left: 5px;
  }
  .pr-xl-5 {
    padding-right: 5px;
  }
  .mt-xl-10 {
    margin-top: 10px;
  }
  .mb-xl-10 {
    margin-bottom: 10px;
  }
  .ml-xl-10 {
    margin-left: 10px;
  }
  .mr-xl-10 {
    margin-right: 10px;
  }
  .pt-xl-10 {
    padding-top: 10px;
  }
  .pb-xl-10 {
    padding-bottom: 10px;
  }
  .pl-xl-10 {
    padding-left: 10px;
  }
  .pr-xl-10 {
    padding-right: 10px;
  }
  .mt-xl-15 {
    margin-top: 15px;
  }
  .mb-xl-15 {
    margin-bottom: 15px;
  }
  .ml-xl-15 {
    margin-left: 15px;
  }
  .mr-xl-15 {
    margin-right: 15px;
  }
  .pt-xl-15 {
    padding-top: 15px;
  }
  .pb-xl-15 {
    padding-bottom: 15px;
  }
  .pl-xl-15 {
    padding-left: 15px;
  }
  .pr-xl-15 {
    padding-right: 15px;
  }
  .mt-xl-20 {
    margin-top: 20px;
  }
  .mb-xl-20 {
    margin-bottom: 20px;
  }
  .ml-xl-20 {
    margin-left: 20px;
  }
  .mr-xl-20 {
    margin-right: 20px;
  }
  .pt-xl-20 {
    padding-top: 20px;
  }
  .pb-xl-20 {
    padding-bottom: 20px;
  }
  .pl-xl-20 {
    padding-left: 20px;
  }
  .pr-xl-20 {
    padding-right: 20px;
  }
  .mt-xl-25 {
    margin-top: 25px;
  }
  .mb-xl-25 {
    margin-bottom: 25px;
  }
  .ml-xl-25 {
    margin-left: 25px;
  }
  .mr-xl-25 {
    margin-right: 25px;
  }
  .pt-xl-25 {
    padding-top: 25px;
  }
  .pb-xl-25 {
    padding-bottom: 25px;
  }
  .pl-xl-25 {
    padding-left: 25px;
  }
  .pr-xl-25 {
    padding-right: 25px;
  }
  .mt-xl-30 {
    margin-top: 30px;
  }
  .mb-xl-30 {
    margin-bottom: 30px;
  }
  .ml-xl-30 {
    margin-left: 30px;
  }
  .mr-xl-30 {
    margin-right: 30px;
  }
  .pt-xl-30 {
    padding-top: 30px;
  }
  .pb-xl-30 {
    padding-bottom: 30px;
  }
  .pl-xl-30 {
    padding-left: 30px;
  }
  .pr-xl-30 {
    padding-right: 30px;
  }
  .mt-xl-35 {
    margin-top: 35px;
  }
  .mb-xl-35 {
    margin-bottom: 35px;
  }
  .ml-xl-35 {
    margin-left: 35px;
  }
  .mr-xl-35 {
    margin-right: 35px;
  }
  .pt-xl-35 {
    padding-top: 35px;
  }
  .pb-xl-35 {
    padding-bottom: 35px;
  }
  .pl-xl-35 {
    padding-left: 35px;
  }
  .pr-xl-35 {
    padding-right: 35px;
  }
  .mt-xl-40 {
    margin-top: 40px;
  }
  .mb-xl-40 {
    margin-bottom: 40px;
  }
  .ml-xl-40 {
    margin-left: 40px;
  }
  .mr-xl-40 {
    margin-right: 40px;
  }
  .pt-xl-40 {
    padding-top: 40px;
  }
  .pb-xl-40 {
    padding-bottom: 40px;
  }
  .pl-xl-40 {
    padding-left: 40px;
  }
  .pr-xl-40 {
    padding-right: 40px;
  }
  .mt-xl-45 {
    margin-top: 45px;
  }
  .mb-xl-45 {
    margin-bottom: 45px;
  }
  .ml-xl-45 {
    margin-left: 45px;
  }
  .mr-xl-45 {
    margin-right: 45px;
  }
  .pt-xl-45 {
    padding-top: 45px;
  }
  .pb-xl-45 {
    padding-bottom: 45px;
  }
  .pl-xl-45 {
    padding-left: 45px;
  }
  .pr-xl-45 {
    padding-right: 45px;
  }
  .mt-xl-50 {
    margin-top: 50px;
  }
  .mb-xl-50 {
    margin-bottom: 50px;
  }
  .ml-xl-50 {
    margin-left: 50px;
  }
  .mr-xl-50 {
    margin-right: 50px;
  }
  .pt-xl-50 {
    padding-top: 50px;
  }
  .pb-xl-50 {
    padding-bottom: 50px;
  }
  .pl-xl-50 {
    padding-left: 50px;
  }
  .pr-xl-50 {
    padding-right: 50px;
  }
  .mt-xl-55 {
    margin-top: 55px;
  }
  .mb-xl-55 {
    margin-bottom: 55px;
  }
  .ml-xl-55 {
    margin-left: 55px;
  }
  .mr-xl-55 {
    margin-right: 55px;
  }
  .pt-xl-55 {
    padding-top: 55px;
  }
  .pb-xl-55 {
    padding-bottom: 55px;
  }
  .pl-xl-55 {
    padding-left: 55px;
  }
  .pr-xl-55 {
    padding-right: 55px;
  }
  .mt-xl-60 {
    margin-top: 60px;
  }
  .mb-xl-60 {
    margin-bottom: 60px;
  }
  .ml-xl-60 {
    margin-left: 60px;
  }
  .mr-xl-60 {
    margin-right: 60px;
  }
  .pt-xl-60 {
    padding-top: 60px;
  }
  .pb-xl-60 {
    padding-bottom: 60px;
  }
  .pl-xl-60 {
    padding-left: 60px;
  }
  .pr-xl-60 {
    padding-right: 60px;
  }
  .mt-xl-65 {
    margin-top: 65px;
  }
  .mb-xl-65 {
    margin-bottom: 65px;
  }
  .ml-xl-65 {
    margin-left: 65px;
  }
  .mr-xl-65 {
    margin-right: 65px;
  }
  .pt-xl-65 {
    padding-top: 65px;
  }
  .pb-xl-65 {
    padding-bottom: 65px;
  }
  .pl-xl-65 {
    padding-left: 65px;
  }
  .pr-xl-65 {
    padding-right: 65px;
  }
  .mt-xl-70 {
    margin-top: 70px;
  }
  .mb-xl-70 {
    margin-bottom: 70px;
  }
  .ml-xl-70 {
    margin-left: 70px;
  }
  .mr-xl-70 {
    margin-right: 70px;
  }
  .pt-xl-70 {
    padding-top: 70px;
  }
  .pb-xl-70 {
    padding-bottom: 70px;
  }
  .pl-xl-70 {
    padding-left: 70px;
  }
  .pr-xl-70 {
    padding-right: 70px;
  }
  .mt-xl-75 {
    margin-top: 75px;
  }
  .mb-xl-75 {
    margin-bottom: 75px;
  }
  .ml-xl-75 {
    margin-left: 75px;
  }
  .mr-xl-75 {
    margin-right: 75px;
  }
  .pt-xl-75 {
    padding-top: 75px;
  }
  .pb-xl-75 {
    padding-bottom: 75px;
  }
  .pl-xl-75 {
    padding-left: 75px;
  }
  .pr-xl-75 {
    padding-right: 75px;
  }
  .mt-xl-80 {
    margin-top: 80px;
  }
  .mb-xl-80 {
    margin-bottom: 80px;
  }
  .ml-xl-80 {
    margin-left: 80px;
  }
  .mr-xl-80 {
    margin-right: 80px;
  }
  .pt-xl-80 {
    padding-top: 80px;
  }
  .pb-xl-80 {
    padding-bottom: 80px;
  }
  .pl-xl-80 {
    padding-left: 80px;
  }
  .pr-xl-80 {
    padding-right: 80px;
  }
  .mt-xl-85 {
    margin-top: 85px;
  }
  .mb-xl-85 {
    margin-bottom: 85px;
  }
  .ml-xl-85 {
    margin-left: 85px;
  }
  .mr-xl-85 {
    margin-right: 85px;
  }
  .pt-xl-85 {
    padding-top: 85px;
  }
  .pb-xl-85 {
    padding-bottom: 85px;
  }
  .pl-xl-85 {
    padding-left: 85px;
  }
  .pr-xl-85 {
    padding-right: 85px;
  }
  .mt-xl-90 {
    margin-top: 90px;
  }
  .mb-xl-90 {
    margin-bottom: 90px;
  }
  .ml-xl-90 {
    margin-left: 90px;
  }
  .mr-xl-90 {
    margin-right: 90px;
  }
  .pt-xl-90 {
    padding-top: 90px;
  }
  .pb-xl-90 {
    padding-bottom: 90px;
  }
  .pl-xl-90 {
    padding-left: 90px;
  }
  .pr-xl-90 {
    padding-right: 90px;
  }
  .mt-xl-95 {
    margin-top: 95px;
  }
  .mb-xl-95 {
    margin-bottom: 95px;
  }
  .ml-xl-95 {
    margin-left: 95px;
  }
  .mr-xl-95 {
    margin-right: 95px;
  }
  .pt-xl-95 {
    padding-top: 95px;
  }
  .pb-xl-95 {
    padding-bottom: 95px;
  }
  .pl-xl-95 {
    padding-left: 95px;
  }
  .pr-xl-95 {
    padding-right: 95px;
  }
  .mt-xl-100 {
    margin-top: 100px;
  }
  .mb-xl-100 {
    margin-bottom: 100px;
  }
  .ml-xl-100 {
    margin-left: 100px;
  }
  .mr-xl-100 {
    margin-right: 100px;
  }
  .pt-xl-100 {
    padding-top: 100px;
  }
  .pb-xl-100 {
    padding-bottom: 100px;
  }
  .pl-xl-100 {
    padding-left: 100px;
  }
  .pr-xl-100 {
    padding-right: 100px;
  }
  .mt-xl-105 {
    margin-top: 105px;
  }
  .mb-xl-105 {
    margin-bottom: 105px;
  }
  .ml-xl-105 {
    margin-left: 105px;
  }
  .mr-xl-105 {
    margin-right: 105px;
  }
  .pt-xl-105 {
    padding-top: 105px;
  }
  .pb-xl-105 {
    padding-bottom: 105px;
  }
  .pl-xl-105 {
    padding-left: 105px;
  }
  .pr-xl-105 {
    padding-right: 105px;
  }
  .mt-xl-110 {
    margin-top: 110px;
  }
  .mb-xl-110 {
    margin-bottom: 110px;
  }
  .ml-xl-110 {
    margin-left: 110px;
  }
  .mr-xl-110 {
    margin-right: 110px;
  }
  .pt-xl-110 {
    padding-top: 110px;
  }
  .pb-xl-110 {
    padding-bottom: 110px;
  }
  .pl-xl-110 {
    padding-left: 110px;
  }
  .pr-xl-110 {
    padding-right: 110px;
  }
  .mt-xl-115 {
    margin-top: 115px;
  }
  .mb-xl-115 {
    margin-bottom: 115px;
  }
  .ml-xl-115 {
    margin-left: 115px;
  }
  .mr-xl-115 {
    margin-right: 115px;
  }
  .pt-xl-115 {
    padding-top: 115px;
  }
  .pb-xl-115 {
    padding-bottom: 115px;
  }
  .pl-xl-115 {
    padding-left: 115px;
  }
  .pr-xl-115 {
    padding-right: 115px;
  }
  .mt-xl-120 {
    margin-top: 120px;
  }
  .mb-xl-120 {
    margin-bottom: 120px;
  }
  .ml-xl-120 {
    margin-left: 120px;
  }
  .mr-xl-120 {
    margin-right: 120px;
  }
  .pt-xl-120 {
    padding-top: 120px;
  }
  .pb-xl-120 {
    padding-bottom: 120px;
  }
  .pl-xl-120 {
    padding-left: 120px;
  }
  .pr-xl-120 {
    padding-right: 120px;
  }
  .mt-xl-125 {
    margin-top: 125px;
  }
  .mb-xl-125 {
    margin-bottom: 125px;
  }
  .ml-xl-125 {
    margin-left: 125px;
  }
  .mr-xl-125 {
    margin-right: 125px;
  }
  .pt-xl-125 {
    padding-top: 125px;
  }
  .pb-xl-125 {
    padding-bottom: 125px;
  }
  .pl-xl-125 {
    padding-left: 125px;
  }
  .pr-xl-125 {
    padding-right: 125px;
  }
  .mt-xl-130 {
    margin-top: 130px;
  }
  .mb-xl-130 {
    margin-bottom: 130px;
  }
  .ml-xl-130 {
    margin-left: 130px;
  }
  .mr-xl-130 {
    margin-right: 130px;
  }
  .pt-xl-130 {
    padding-top: 130px;
  }
  .pb-xl-130 {
    padding-bottom: 130px;
  }
  .pl-xl-130 {
    padding-left: 130px;
  }
  .pr-xl-130 {
    padding-right: 130px;
  }
  .mt-xl-135 {
    margin-top: 135px;
  }
  .mb-xl-135 {
    margin-bottom: 135px;
  }
  .ml-xl-135 {
    margin-left: 135px;
  }
  .mr-xl-135 {
    margin-right: 135px;
  }
  .pt-xl-135 {
    padding-top: 135px;
  }
  .pb-xl-135 {
    padding-bottom: 135px;
  }
  .pl-xl-135 {
    padding-left: 135px;
  }
  .pr-xl-135 {
    padding-right: 135px;
  }
  .mt-xl-140 {
    margin-top: 140px;
  }
  .mb-xl-140 {
    margin-bottom: 140px;
  }
  .ml-xl-140 {
    margin-left: 140px;
  }
  .mr-xl-140 {
    margin-right: 140px;
  }
  .pt-xl-140 {
    padding-top: 140px;
  }
  .pb-xl-140 {
    padding-bottom: 140px;
  }
  .pl-xl-140 {
    padding-left: 140px;
  }
  .pr-xl-140 {
    padding-right: 140px;
  }
  .mt-xl-145 {
    margin-top: 145px;
  }
  .mb-xl-145 {
    margin-bottom: 145px;
  }
  .ml-xl-145 {
    margin-left: 145px;
  }
  .mr-xl-145 {
    margin-right: 145px;
  }
  .pt-xl-145 {
    padding-top: 145px;
  }
  .pb-xl-145 {
    padding-bottom: 145px;
  }
  .pl-xl-145 {
    padding-left: 145px;
  }
  .pr-xl-145 {
    padding-right: 145px;
  }
  .mt-xl-150 {
    margin-top: 150px;
  }
  .mb-xl-150 {
    margin-bottom: 150px;
  }
  .ml-xl-150 {
    margin-left: 150px;
  }
  .mr-xl-150 {
    margin-right: 150px;
  }
  .pt-xl-150 {
    padding-top: 150px;
  }
  .pb-xl-150 {
    padding-bottom: 150px;
  }
  .pl-xl-150 {
    padding-left: 150px;
  }
  .pr-xl-150 {
    padding-right: 150px;
  }
  .mt-xl-155 {
    margin-top: 155px;
  }
  .mb-xl-155 {
    margin-bottom: 155px;
  }
  .ml-xl-155 {
    margin-left: 155px;
  }
  .mr-xl-155 {
    margin-right: 155px;
  }
  .pt-xl-155 {
    padding-top: 155px;
  }
  .pb-xl-155 {
    padding-bottom: 155px;
  }
  .pl-xl-155 {
    padding-left: 155px;
  }
  .pr-xl-155 {
    padding-right: 155px;
  }
  .mt-xl-160 {
    margin-top: 160px;
  }
  .mb-xl-160 {
    margin-bottom: 160px;
  }
  .ml-xl-160 {
    margin-left: 160px;
  }
  .mr-xl-160 {
    margin-right: 160px;
  }
  .pt-xl-160 {
    padding-top: 160px;
  }
  .pb-xl-160 {
    padding-bottom: 160px;
  }
  .pl-xl-160 {
    padding-left: 160px;
  }
  .pr-xl-160 {
    padding-right: 160px;
  }
  .mt-xl-165 {
    margin-top: 165px;
  }
  .mb-xl-165 {
    margin-bottom: 165px;
  }
  .ml-xl-165 {
    margin-left: 165px;
  }
  .mr-xl-165 {
    margin-right: 165px;
  }
  .pt-xl-165 {
    padding-top: 165px;
  }
  .pb-xl-165 {
    padding-bottom: 165px;
  }
  .pl-xl-165 {
    padding-left: 165px;
  }
  .pr-xl-165 {
    padding-right: 165px;
  }
  .mt-xl-170 {
    margin-top: 170px;
  }
  .mb-xl-170 {
    margin-bottom: 170px;
  }
  .ml-xl-170 {
    margin-left: 170px;
  }
  .mr-xl-170 {
    margin-right: 170px;
  }
  .pt-xl-170 {
    padding-top: 170px;
  }
  .pb-xl-170 {
    padding-bottom: 170px;
  }
  .pl-xl-170 {
    padding-left: 170px;
  }
  .pr-xl-170 {
    padding-right: 170px;
  }
  .mt-xl-175 {
    margin-top: 175px;
  }
  .mb-xl-175 {
    margin-bottom: 175px;
  }
  .ml-xl-175 {
    margin-left: 175px;
  }
  .mr-xl-175 {
    margin-right: 175px;
  }
  .pt-xl-175 {
    padding-top: 175px;
  }
  .pb-xl-175 {
    padding-bottom: 175px;
  }
  .pl-xl-175 {
    padding-left: 175px;
  }
  .pr-xl-175 {
    padding-right: 175px;
  }
  .mt-xl-180 {
    margin-top: 180px;
  }
  .mb-xl-180 {
    margin-bottom: 180px;
  }
  .ml-xl-180 {
    margin-left: 180px;
  }
  .mr-xl-180 {
    margin-right: 180px;
  }
  .pt-xl-180 {
    padding-top: 180px;
  }
  .pb-xl-180 {
    padding-bottom: 180px;
  }
  .pl-xl-180 {
    padding-left: 180px;
  }
  .pr-xl-180 {
    padding-right: 180px;
  }
  .mt-xl-185 {
    margin-top: 185px;
  }
  .mb-xl-185 {
    margin-bottom: 185px;
  }
  .ml-xl-185 {
    margin-left: 185px;
  }
  .mr-xl-185 {
    margin-right: 185px;
  }
  .pt-xl-185 {
    padding-top: 185px;
  }
  .pb-xl-185 {
    padding-bottom: 185px;
  }
  .pl-xl-185 {
    padding-left: 185px;
  }
  .pr-xl-185 {
    padding-right: 185px;
  }
  .mt-xl-190 {
    margin-top: 190px;
  }
  .mb-xl-190 {
    margin-bottom: 190px;
  }
  .ml-xl-190 {
    margin-left: 190px;
  }
  .mr-xl-190 {
    margin-right: 190px;
  }
  .pt-xl-190 {
    padding-top: 190px;
  }
  .pb-xl-190 {
    padding-bottom: 190px;
  }
  .pl-xl-190 {
    padding-left: 190px;
  }
  .pr-xl-190 {
    padding-right: 190px;
  }
  .mt-xl-195 {
    margin-top: 195px;
  }
  .mb-xl-195 {
    margin-bottom: 195px;
  }
  .ml-xl-195 {
    margin-left: 195px;
  }
  .mr-xl-195 {
    margin-right: 195px;
  }
  .pt-xl-195 {
    padding-top: 195px;
  }
  .pb-xl-195 {
    padding-bottom: 195px;
  }
  .pl-xl-195 {
    padding-left: 195px;
  }
  .pr-xl-195 {
    padding-right: 195px;
  }
  .mt-xl-200 {
    margin-top: 200px;
  }
  .mb-xl-200 {
    margin-bottom: 200px;
  }
  .ml-xl-200 {
    margin-left: 200px;
  }
  .mr-xl-200 {
    margin-right: 200px;
  }
  .pt-xl-200 {
    padding-top: 200px;
  }
  .pb-xl-200 {
    padding-bottom: 200px;
  }
  .pl-xl-200 {
    padding-left: 200px;
  }
  .pr-xl-200 {
    padding-right: 200px;
  }
  .mt-xl-205 {
    margin-top: 205px;
  }
  .mb-xl-205 {
    margin-bottom: 205px;
  }
  .ml-xl-205 {
    margin-left: 205px;
  }
  .mr-xl-205 {
    margin-right: 205px;
  }
  .pt-xl-205 {
    padding-top: 205px;
  }
  .pb-xl-205 {
    padding-bottom: 205px;
  }
  .pl-xl-205 {
    padding-left: 205px;
  }
  .pr-xl-205 {
    padding-right: 205px;
  }
  .mt-xl-210 {
    margin-top: 210px;
  }
  .mb-xl-210 {
    margin-bottom: 210px;
  }
  .ml-xl-210 {
    margin-left: 210px;
  }
  .mr-xl-210 {
    margin-right: 210px;
  }
  .pt-xl-210 {
    padding-top: 210px;
  }
  .pb-xl-210 {
    padding-bottom: 210px;
  }
  .pl-xl-210 {
    padding-left: 210px;
  }
  .pr-xl-210 {
    padding-right: 210px;
  }
  .mt-xl-215 {
    margin-top: 215px;
  }
  .mb-xl-215 {
    margin-bottom: 215px;
  }
  .ml-xl-215 {
    margin-left: 215px;
  }
  .mr-xl-215 {
    margin-right: 215px;
  }
  .pt-xl-215 {
    padding-top: 215px;
  }
  .pb-xl-215 {
    padding-bottom: 215px;
  }
  .pl-xl-215 {
    padding-left: 215px;
  }
  .pr-xl-215 {
    padding-right: 215px;
  }
  .mt-xl-220 {
    margin-top: 220px;
  }
  .mb-xl-220 {
    margin-bottom: 220px;
  }
  .ml-xl-220 {
    margin-left: 220px;
  }
  .mr-xl-220 {
    margin-right: 220px;
  }
  .pt-xl-220 {
    padding-top: 220px;
  }
  .pb-xl-220 {
    padding-bottom: 220px;
  }
  .pl-xl-220 {
    padding-left: 220px;
  }
  .pr-xl-220 {
    padding-right: 220px;
  }
}
/*

responsive css
xs device
*/
@media all and (max-width: 575px) {
  .mt-xs-5 {
    margin-top: 5px;
  }
  .mb-xs-5 {
    margin-bottom: 5px;
  }
  .ml-xs-5 {
    margin-left: 5px;
  }
  .mr-xs-5 {
    margin-right: 5px;
  }
  .pt-xs-5 {
    padding-top: 5px;
  }
  .pb-xs-5 {
    padding-bottom: 5px;
  }
  .pl-xs-5 {
    padding-left: 5px;
  }
  .pr-xs-5 {
    padding-right: 5px;
  }
  .mt-xs-10 {
    margin-top: 10px;
  }
  .mb-xs-10 {
    margin-bottom: 10px;
  }
  .ml-xs-10 {
    margin-left: 10px;
  }
  .mr-xs-10 {
    margin-right: 10px;
  }
  .pt-xs-10 {
    padding-top: 10px;
  }
  .pb-xs-10 {
    padding-bottom: 10px;
  }
  .pl-xs-10 {
    padding-left: 10px;
  }
  .pr-xs-10 {
    padding-right: 10px;
  }
  .mt-xs-15 {
    margin-top: 15px;
  }
  .mb-xs-15 {
    margin-bottom: 15px;
  }
  .ml-xs-15 {
    margin-left: 15px;
  }
  .mr-xs-15 {
    margin-right: 15px;
  }
  .pt-xs-15 {
    padding-top: 15px;
  }
  .pb-xs-15 {
    padding-bottom: 15px;
  }
  .pl-xs-15 {
    padding-left: 15px;
  }
  .pr-xs-15 {
    padding-right: 15px;
  }
  .mt-xs-20 {
    margin-top: 20px;
  }
  .mb-xs-20 {
    margin-bottom: 20px;
  }
  .ml-xs-20 {
    margin-left: 20px;
  }
  .mr-xs-20 {
    margin-right: 20px;
  }
  .pt-xs-20 {
    padding-top: 20px;
  }
  .pb-xs-20 {
    padding-bottom: 20px;
  }
  .pl-xs-20 {
    padding-left: 20px;
  }
  .pr-xs-20 {
    padding-right: 20px;
  }
  .mt-xs-25 {
    margin-top: 25px;
  }
  .mb-xs-25 {
    margin-bottom: 25px;
  }
  .ml-xs-25 {
    margin-left: 25px;
  }
  .mr-xs-25 {
    margin-right: 25px;
  }
  .pt-xs-25 {
    padding-top: 25px;
  }
  .pb-xs-25 {
    padding-bottom: 25px;
  }
  .pl-xs-25 {
    padding-left: 25px;
  }
  .pr-xs-25 {
    padding-right: 25px;
  }
  .mt-xs-30 {
    margin-top: 30px;
  }
  .mb-xs-30 {
    margin-bottom: 30px;
  }
  .ml-xs-30 {
    margin-left: 30px;
  }
  .mr-xs-30 {
    margin-right: 30px;
  }
  .pt-xs-30 {
    padding-top: 30px;
  }
  .pb-xs-30 {
    padding-bottom: 30px;
  }
  .pl-xs-30 {
    padding-left: 30px;
  }
  .pr-xs-30 {
    padding-right: 30px;
  }
  .mt-xs-35 {
    margin-top: 35px;
  }
  .mb-xs-35 {
    margin-bottom: 35px;
  }
  .ml-xs-35 {
    margin-left: 35px;
  }
  .mr-xs-35 {
    margin-right: 35px;
  }
  .pt-xs-35 {
    padding-top: 35px;
  }
  .pb-xs-35 {
    padding-bottom: 35px;
  }
  .pl-xs-35 {
    padding-left: 35px;
  }
  .pr-xs-35 {
    padding-right: 35px;
  }
  .mt-xs-40 {
    margin-top: 40px;
  }
  .mb-xs-40 {
    margin-bottom: 40px;
  }
  .ml-xs-40 {
    margin-left: 40px;
  }
  .mr-xs-40 {
    margin-right: 40px;
  }
  .pt-xs-40 {
    padding-top: 40px;
  }
  .pb-xs-40 {
    padding-bottom: 40px;
  }
  .pl-xs-40 {
    padding-left: 40px;
  }
  .pr-xs-40 {
    padding-right: 40px;
  }
  .mt-xs-45 {
    margin-top: 45px;
  }
  .mb-xs-45 {
    margin-bottom: 45px;
  }
  .ml-xs-45 {
    margin-left: 45px;
  }
  .mr-xs-45 {
    margin-right: 45px;
  }
  .pt-xs-45 {
    padding-top: 45px;
  }
  .pb-xs-45 {
    padding-bottom: 45px;
  }
  .pl-xs-45 {
    padding-left: 45px;
  }
  .pr-xs-45 {
    padding-right: 45px;
  }
  .mt-xs-50 {
    margin-top: 50px;
  }
  .mb-xs-50 {
    margin-bottom: 50px;
  }
  .ml-xs-50 {
    margin-left: 50px;
  }
  .mr-xs-50 {
    margin-right: 50px;
  }
  .pt-xs-50 {
    padding-top: 50px;
  }
  .pb-xs-50 {
    padding-bottom: 50px;
  }
  .pl-xs-50 {
    padding-left: 50px;
  }
  .pr-xs-50 {
    padding-right: 50px;
  }
  .mt-xs-55 {
    margin-top: 55px;
  }
  .mb-xs-55 {
    margin-bottom: 55px;
  }
  .ml-xs-55 {
    margin-left: 55px;
  }
  .mr-xs-55 {
    margin-right: 55px;
  }
  .pt-xs-55 {
    padding-top: 55px;
  }
  .pb-xs-55 {
    padding-bottom: 55px;
  }
  .pl-xs-55 {
    padding-left: 55px;
  }
  .pr-xs-55 {
    padding-right: 55px;
  }
  .mt-xs-60 {
    margin-top: 60px;
  }
  .mb-xs-60 {
    margin-bottom: 60px;
  }
  .ml-xs-60 {
    margin-left: 60px;
  }
  .mr-xs-60 {
    margin-right: 60px;
  }
  .pt-xs-60 {
    padding-top: 60px;
  }
  .pb-xs-60 {
    padding-bottom: 60px;
  }
  .pl-xs-60 {
    padding-left: 60px;
  }
  .pr-xs-60 {
    padding-right: 60px;
  }
  .mt-xs-65 {
    margin-top: 65px;
  }
  .mb-xs-65 {
    margin-bottom: 65px;
  }
  .ml-xs-65 {
    margin-left: 65px;
  }
  .mr-xs-65 {
    margin-right: 65px;
  }
  .pt-xs-65 {
    padding-top: 65px;
  }
  .pb-xs-65 {
    padding-bottom: 65px;
  }
  .pl-xs-65 {
    padding-left: 65px;
  }
  .pr-xs-65 {
    padding-right: 65px;
  }
  .mt-xs-70 {
    margin-top: 70px;
  }
  .mb-xs-70 {
    margin-bottom: 70px;
  }
  .ml-xs-70 {
    margin-left: 70px;
  }
  .mr-xs-70 {
    margin-right: 70px;
  }
  .pt-xs-70 {
    padding-top: 70px;
  }
  .pb-xs-70 {
    padding-bottom: 70px;
  }
  .pl-xs-70 {
    padding-left: 70px;
  }
  .pr-xs-70 {
    padding-right: 70px;
  }
  .mt-xs-75 {
    margin-top: 75px;
  }
  .mb-xs-75 {
    margin-bottom: 75px;
  }
  .ml-xs-75 {
    margin-left: 75px;
  }
  .mr-xs-75 {
    margin-right: 75px;
  }
  .pt-xs-75 {
    padding-top: 75px;
  }
  .pb-xs-75 {
    padding-bottom: 75px;
  }
  .pl-xs-75 {
    padding-left: 75px;
  }
  .pr-xs-75 {
    padding-right: 75px;
  }
  .mt-xs-80 {
    margin-top: 80px;
  }
  .mb-xs-80 {
    margin-bottom: 80px;
  }
  .ml-xs-80 {
    margin-left: 80px;
  }
  .mr-xs-80 {
    margin-right: 80px;
  }
  .pt-xs-80 {
    padding-top: 80px;
  }
  .pb-xs-80 {
    padding-bottom: 80px;
  }
  .pl-xs-80 {
    padding-left: 80px;
  }
  .pr-xs-80 {
    padding-right: 80px;
  }
  .mt-xs-85 {
    margin-top: 85px;
  }
  .mb-xs-85 {
    margin-bottom: 85px;
  }
  .ml-xs-85 {
    margin-left: 85px;
  }
  .mr-xs-85 {
    margin-right: 85px;
  }
  .pt-xs-85 {
    padding-top: 85px;
  }
  .pb-xs-85 {
    padding-bottom: 85px;
  }
  .pl-xs-85 {
    padding-left: 85px;
  }
  .pr-xs-85 {
    padding-right: 85px;
  }
  .mt-xs-90 {
    margin-top: 90px;
  }
  .mb-xs-90 {
    margin-bottom: 90px;
  }
  .ml-xs-90 {
    margin-left: 90px;
  }
  .mr-xs-90 {
    margin-right: 90px;
  }
  .pt-xs-90 {
    padding-top: 90px;
  }
  .pb-xs-90 {
    padding-bottom: 90px;
  }
  .pl-xs-90 {
    padding-left: 90px;
  }
  .pr-xs-90 {
    padding-right: 90px;
  }
  .mt-xs-95 {
    margin-top: 95px;
  }
  .mb-xs-95 {
    margin-bottom: 95px;
  }
  .ml-xs-95 {
    margin-left: 95px;
  }
  .mr-xs-95 {
    margin-right: 95px;
  }
  .pt-xs-95 {
    padding-top: 95px;
  }
  .pb-xs-95 {
    padding-bottom: 95px;
  }
  .pl-xs-95 {
    padding-left: 95px;
  }
  .pr-xs-95 {
    padding-right: 95px;
  }
  .mt-xs-100 {
    margin-top: 100px;
  }
  .mb-xs-100 {
    margin-bottom: 100px;
  }
  .ml-xs-100 {
    margin-left: 100px;
  }
  .mr-xs-100 {
    margin-right: 100px;
  }
  .pt-xs-100 {
    padding-top: 100px;
  }
  .pb-xs-100 {
    padding-bottom: 100px;
  }
  .pl-xs-100 {
    padding-left: 100px;
  }
  .pr-xs-100 {
    padding-right: 100px;
  }
  .mt-xs-105 {
    margin-top: 105px;
  }
  .mb-xs-105 {
    margin-bottom: 105px;
  }
  .ml-xs-105 {
    margin-left: 105px;
  }
  .mr-xs-105 {
    margin-right: 105px;
  }
  .pt-xs-105 {
    padding-top: 105px;
  }
  .pb-xs-105 {
    padding-bottom: 105px;
  }
  .pl-xs-105 {
    padding-left: 105px;
  }
  .pr-xs-105 {
    padding-right: 105px;
  }
  .mt-xs-110 {
    margin-top: 110px;
  }
  .mb-xs-110 {
    margin-bottom: 110px;
  }
  .ml-xs-110 {
    margin-left: 110px;
  }
  .mr-xs-110 {
    margin-right: 110px;
  }
  .pt-xs-110 {
    padding-top: 110px;
  }
  .pb-xs-110 {
    padding-bottom: 110px;
  }
  .pl-xs-110 {
    padding-left: 110px;
  }
  .pr-xs-110 {
    padding-right: 110px;
  }
  .mt-xs-115 {
    margin-top: 115px;
  }
  .mb-xs-115 {
    margin-bottom: 115px;
  }
  .ml-xs-115 {
    margin-left: 115px;
  }
  .mr-xs-115 {
    margin-right: 115px;
  }
  .pt-xs-115 {
    padding-top: 115px;
  }
  .pb-xs-115 {
    padding-bottom: 115px;
  }
  .pl-xs-115 {
    padding-left: 115px;
  }
  .pr-xs-115 {
    padding-right: 115px;
  }
  .mt-xs-120 {
    margin-top: 120px;
  }
  .mb-xs-120 {
    margin-bottom: 120px;
  }
  .ml-xs-120 {
    margin-left: 120px;
  }
  .mr-xs-120 {
    margin-right: 120px;
  }
  .pt-xs-120 {
    padding-top: 120px;
  }
  .pb-xs-120 {
    padding-bottom: 120px;
  }
  .pl-xs-120 {
    padding-left: 120px;
  }
  .pr-xs-120 {
    padding-right: 120px;
  }
  .mt-xs-125 {
    margin-top: 125px;
  }
  .mb-xs-125 {
    margin-bottom: 125px;
  }
  .ml-xs-125 {
    margin-left: 125px;
  }
  .mr-xs-125 {
    margin-right: 125px;
  }
  .pt-xs-125 {
    padding-top: 125px;
  }
  .pb-xs-125 {
    padding-bottom: 125px;
  }
  .pl-xs-125 {
    padding-left: 125px;
  }
  .pr-xs-125 {
    padding-right: 125px;
  }
  .mt-xs-130 {
    margin-top: 130px;
  }
  .mb-xs-130 {
    margin-bottom: 130px;
  }
  .ml-xs-130 {
    margin-left: 130px;
  }
  .mr-xs-130 {
    margin-right: 130px;
  }
  .pt-xs-130 {
    padding-top: 130px;
  }
  .pb-xs-130 {
    padding-bottom: 130px;
  }
  .pl-xs-130 {
    padding-left: 130px;
  }
  .pr-xs-130 {
    padding-right: 130px;
  }
  .mt-xs-135 {
    margin-top: 135px;
  }
  .mb-xs-135 {
    margin-bottom: 135px;
  }
  .ml-xs-135 {
    margin-left: 135px;
  }
  .mr-xs-135 {
    margin-right: 135px;
  }
  .pt-xs-135 {
    padding-top: 135px;
  }
  .pb-xs-135 {
    padding-bottom: 135px;
  }
  .pl-xs-135 {
    padding-left: 135px;
  }
  .pr-xs-135 {
    padding-right: 135px;
  }
  .mt-xs-140 {
    margin-top: 140px;
  }
  .mb-xs-140 {
    margin-bottom: 140px;
  }
  .ml-xs-140 {
    margin-left: 140px;
  }
  .mr-xs-140 {
    margin-right: 140px;
  }
  .pt-xs-140 {
    padding-top: 140px;
  }
  .pb-xs-140 {
    padding-bottom: 140px;
  }
  .pl-xs-140 {
    padding-left: 140px;
  }
  .pr-xs-140 {
    padding-right: 140px;
  }
  .mt-xs-145 {
    margin-top: 145px;
  }
  .mb-xs-145 {
    margin-bottom: 145px;
  }
  .ml-xs-145 {
    margin-left: 145px;
  }
  .mr-xs-145 {
    margin-right: 145px;
  }
  .pt-xs-145 {
    padding-top: 145px;
  }
  .pb-xs-145 {
    padding-bottom: 145px;
  }
  .pl-xs-145 {
    padding-left: 145px;
  }
  .pr-xs-145 {
    padding-right: 145px;
  }
  .mt-xs-150 {
    margin-top: 150px;
  }
  .mb-xs-150 {
    margin-bottom: 150px;
  }
  .ml-xs-150 {
    margin-left: 150px;
  }
  .mr-xs-150 {
    margin-right: 150px;
  }
  .pt-xs-150 {
    padding-top: 150px;
  }
  .pb-xs-150 {
    padding-bottom: 150px;
  }
  .pl-xs-150 {
    padding-left: 150px;
  }
  .pr-xs-150 {
    padding-right: 150px;
  }
  .mt-xs-155 {
    margin-top: 155px;
  }
  .mb-xs-155 {
    margin-bottom: 155px;
  }
  .ml-xs-155 {
    margin-left: 155px;
  }
  .mr-xs-155 {
    margin-right: 155px;
  }
  .pt-xs-155 {
    padding-top: 155px;
  }
  .pb-xs-155 {
    padding-bottom: 155px;
  }
  .pl-xs-155 {
    padding-left: 155px;
  }
  .pr-xs-155 {
    padding-right: 155px;
  }
  .mt-xs-160 {
    margin-top: 160px;
  }
  .mb-xs-160 {
    margin-bottom: 160px;
  }
  .ml-xs-160 {
    margin-left: 160px;
  }
  .mr-xs-160 {
    margin-right: 160px;
  }
  .pt-xs-160 {
    padding-top: 160px;
  }
  .pb-xs-160 {
    padding-bottom: 160px;
  }
  .pl-xs-160 {
    padding-left: 160px;
  }
  .pr-xs-160 {
    padding-right: 160px;
  }
  .mt-xs-165 {
    margin-top: 165px;
  }
  .mb-xs-165 {
    margin-bottom: 165px;
  }
  .ml-xs-165 {
    margin-left: 165px;
  }
  .mr-xs-165 {
    margin-right: 165px;
  }
  .pt-xs-165 {
    padding-top: 165px;
  }
  .pb-xs-165 {
    padding-bottom: 165px;
  }
  .pl-xs-165 {
    padding-left: 165px;
  }
  .pr-xs-165 {
    padding-right: 165px;
  }
  .mt-xs-170 {
    margin-top: 170px;
  }
  .mb-xs-170 {
    margin-bottom: 170px;
  }
  .ml-xs-170 {
    margin-left: 170px;
  }
  .mr-xs-170 {
    margin-right: 170px;
  }
  .pt-xs-170 {
    padding-top: 170px;
  }
  .pb-xs-170 {
    padding-bottom: 170px;
  }
  .pl-xs-170 {
    padding-left: 170px;
  }
  .pr-xs-170 {
    padding-right: 170px;
  }
  .mt-xs-175 {
    margin-top: 175px;
  }
  .mb-xs-175 {
    margin-bottom: 175px;
  }
  .ml-xs-175 {
    margin-left: 175px;
  }
  .mr-xs-175 {
    margin-right: 175px;
  }
  .pt-xs-175 {
    padding-top: 175px;
  }
  .pb-xs-175 {
    padding-bottom: 175px;
  }
  .pl-xs-175 {
    padding-left: 175px;
  }
  .pr-xs-175 {
    padding-right: 175px;
  }
  .mt-xs-180 {
    margin-top: 180px;
  }
  .mb-xs-180 {
    margin-bottom: 180px;
  }
  .ml-xs-180 {
    margin-left: 180px;
  }
  .mr-xs-180 {
    margin-right: 180px;
  }
  .pt-xs-180 {
    padding-top: 180px;
  }
  .pb-xs-180 {
    padding-bottom: 180px;
  }
  .pl-xs-180 {
    padding-left: 180px;
  }
  .pr-xs-180 {
    padding-right: 180px;
  }
  .mt-xs-185 {
    margin-top: 185px;
  }
  .mb-xs-185 {
    margin-bottom: 185px;
  }
  .ml-xs-185 {
    margin-left: 185px;
  }
  .mr-xs-185 {
    margin-right: 185px;
  }
  .pt-xs-185 {
    padding-top: 185px;
  }
  .pb-xs-185 {
    padding-bottom: 185px;
  }
  .pl-xs-185 {
    padding-left: 185px;
  }
  .pr-xs-185 {
    padding-right: 185px;
  }
  .mt-xs-190 {
    margin-top: 190px;
  }
  .mb-xs-190 {
    margin-bottom: 190px;
  }
  .ml-xs-190 {
    margin-left: 190px;
  }
  .mr-xs-190 {
    margin-right: 190px;
  }
  .pt-xs-190 {
    padding-top: 190px;
  }
  .pb-xs-190 {
    padding-bottom: 190px;
  }
  .pl-xs-190 {
    padding-left: 190px;
  }
  .pr-xs-190 {
    padding-right: 190px;
  }
  .mt-xs-195 {
    margin-top: 195px;
  }
  .mb-xs-195 {
    margin-bottom: 195px;
  }
  .ml-xs-195 {
    margin-left: 195px;
  }
  .mr-xs-195 {
    margin-right: 195px;
  }
  .pt-xs-195 {
    padding-top: 195px;
  }
  .pb-xs-195 {
    padding-bottom: 195px;
  }
  .pl-xs-195 {
    padding-left: 195px;
  }
  .pr-xs-195 {
    padding-right: 195px;
  }
  .mt-xs-200 {
    margin-top: 200px;
  }
  .mb-xs-200 {
    margin-bottom: 200px;
  }
  .ml-xs-200 {
    margin-left: 200px;
  }
  .mr-xs-200 {
    margin-right: 200px;
  }
  .pt-xs-200 {
    padding-top: 200px;
  }
  .pb-xs-200 {
    padding-bottom: 200px;
  }
  .pl-xs-200 {
    padding-left: 200px;
  }
  .pr-xs-200 {
    padding-right: 200px;
  }
  .mt-xs-205 {
    margin-top: 205px;
  }
  .mb-xs-205 {
    margin-bottom: 205px;
  }
  .ml-xs-205 {
    margin-left: 205px;
  }
  .mr-xs-205 {
    margin-right: 205px;
  }
  .pt-xs-205 {
    padding-top: 205px;
  }
  .pb-xs-205 {
    padding-bottom: 205px;
  }
  .pl-xs-205 {
    padding-left: 205px;
  }
  .pr-xs-205 {
    padding-right: 205px;
  }
  .mt-xs-210 {
    margin-top: 210px;
  }
  .mb-xs-210 {
    margin-bottom: 210px;
  }
  .ml-xs-210 {
    margin-left: 210px;
  }
  .mr-xs-210 {
    margin-right: 210px;
  }
  .pt-xs-210 {
    padding-top: 210px;
  }
  .pb-xs-210 {
    padding-bottom: 210px;
  }
  .pl-xs-210 {
    padding-left: 210px;
  }
  .pr-xs-210 {
    padding-right: 210px;
  }
  .mt-xs-215 {
    margin-top: 215px;
  }
  .mb-xs-215 {
    margin-bottom: 215px;
  }
  .ml-xs-215 {
    margin-left: 215px;
  }
  .mr-xs-215 {
    margin-right: 215px;
  }
  .pt-xs-215 {
    padding-top: 215px;
  }
  .pb-xs-215 {
    padding-bottom: 215px;
  }
  .pl-xs-215 {
    padding-left: 215px;
  }
  .pr-xs-215 {
    padding-right: 215px;
  }
  .mt-xs-220 {
    margin-top: 220px;
  }
  .mb-xs-220 {
    margin-bottom: 220px;
  }
  .ml-xs-220 {
    margin-left: 220px;
  }
  .mr-xs-220 {
    margin-right: 220px;
  }
  .pt-xs-220 {
    padding-top: 220px;
  }
  .pb-xs-220 {
    padding-bottom: 220px;
  }
  .pl-xs-220 {
    padding-left: 220px;
  }
  .pr-xs-220 {
    padding-right: 220px;
  }
}
/*

/* black overlay */
[data-overlay] {
  position: relative;
}

[data-overlay]::before {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  content: "";
  height: 100%;
  position: absolute;
  background: #000 none repeat scroll 0 0;
}

[data-overlay="3"]::before {
  opacity: 0.3;
}

[data-overlay="4"]::before {
  opacity: 0.4;
}

[data-overlay="5"]::before {
  opacity: 0.5;
}

[data-overlay="6"]::before {
  opacity: 0.6;
}

[data-overlay="7"]::before {
  opacity: 0.7;
}

[data-overlay="8"]::before {
  opacity: 0.8;
}

[data-overlay="9"]::before {
  opacity: 0.9;
}

[data-overlay="4"]::before {
  opacity: 0.4;
}

[data-overlay="5"]::before {
  opacity: 0.5;
}

[data-overlay="6"]::before {
  opacity: 0.6;
}

[data-overlay="7"]::before {
  opacity: 0.7;
}

[data-overlay="8"]::before {
  opacity: 0.8;
}

[data-overlay="9"]::before {
  opacity: 0.9;
}

/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.bg-center {
  background-position: center;
}

.bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-position: left;
}

.bg-right {
  background-repeat: no-repeat;
  background-position: right;
}

.bg-left {
  background-repeat: no-repeat;
  background-position: left;
}

.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-top {
  background-repeat: no-repeat;
  background-position: top;
}

.bg-100 {
  background-size: 100% 100%;
}

.triangle {
  width: 0;
  height: 0;
}
.triangle.triangle-top-left {
  border-top: 100px solid #32557f;
  border-right: 100px solid transparent;
}
.triangle.triangle-top-right {
  border-top: 100px solid #32557f;
  border-left: 100px solid transparent;
}
.triangle.triangle-bottom-left {
  border-bottom: 100px solid #32557f;
  border-right: 100px solid transparent;
}
.triangle.triangle-bottom-right {
  border-bottom: 100px solid #32557f;
  border-left: 100px solid transparent;
}

.bg-overlay {
  position: relative;
  z-index: 1;
}
.bg-overlay::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #000;
  z-index: -1;
}

.fix {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.shape {
  position: absolute;
}
@media (max-width: 1191px) {
  .shape {
    display: none;
    opacity: 0;
  }
}

.navbar-brand {
  padding: 0;
  margin-right: 0;
}

#scrollUp {
  right: 20px;
  bottom: 20px;
  height: 40px;
  width: 40px;
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  border-radius: 50%;
  line-height: 44px;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #4069ff;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
}
#scrollUp:hover {
  background-color: #0e1e36;
}

@media only screen and (max-width: 991px) {
  #scrollUp {
    font-size: 15px;
    height: 30px;
    line-height: 34px;
    right: 30px;
    width: 30px;
  }
}
button {
  border: none;
}

.clear-fix {
  overflow: hidden;
}

section {
  position: relative;
  z-index: 1;
}
section.pt-235 {
  padding-top: 225px;
}

@keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }
  50% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }
  50% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.equal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.equal > [class*="col-"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 414px) {
  .d-none-mobile {
    display: none !important;
  }
}

.offcanvas-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
  position: fixed;
  background: #000;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.offcanvas-overlay.overlay-open {
  opacity: 0.5;
  visibility: visible;
}

.no-padding .col-12 {
  padding: 0;
}

img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@keyframes left-to-right {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

@keyframes left-to-right {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}
@keyframes right-to-left {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
@keyframes right-to-left {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
@keyframes left-to-center {
  0% {
    background-position: left center;
  }
  100% {
    background-position: center center;
  }
}
@keyframes left-to-center {
  0% {
    background-position: left center;
  }
  100% {
    background-position: center center;
  }
}
@keyframes right-to-center {
  0% {
    background-position: right center;
  }
  100% {
    background-position: center center;
  }
}
@keyframes right-to-center {
  0% {
    background-position: right center;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-to-bottom {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}
@keyframes top-to-bottom {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}
@keyframes top-to-center {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-to-center {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-to-top {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}
@keyframes bottom-to-top {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}
@keyframes bottom-to-center {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-to-center {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-left-to-bottom-right {
  0% {
    background-position: left top;
  }
  100% {
    background-position: right bottom;
  }
}
@keyframes top-left-to-bottom-right {
  0% {
    background-position: left top;
  }
  100% {
    background-position: right bottom;
  }
}
@keyframes top-left-to-center {
  0% {
    background-position: left top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-left-to-center {
  0% {
    background-position: left top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-right-to-bottom-left {
  0% {
    background-position: right top;
  }
  100% {
    background-position: left bottom;
  }
}
@keyframes top-right-to-bottom-left {
  0% {
    background-position: right top;
  }
  100% {
    background-position: left bottom;
  }
}
@keyframes top-right-to-center {
  0% {
    background-position: right top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-right-to-center {
  0% {
    background-position: right top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-left-to-top-right {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}
@keyframes bottom-left-to-top-right {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}
@keyframes bottom-left-to-center {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-left-to-center {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-right-to-top-left {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}
@keyframes bottom-right-to-top-left {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}
@keyframes bottom-right-to-center {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-right-to-center {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes zoomIn {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
@keyframes zoomIn {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
.animation_left-to-right {
  background-position: left center;
}
.active .animation_left-to-right {
  -webkit-animation: left-to-right 5s ease 0s 1 normal forwards running;
  animation: left-to-right 5s ease 0s 1 normal forwards running;
}

.animation_right-to-left {
  background-position: right center;
}
.active .animation_right-to-left {
  -webkit-animation: right-to-left 5s ease 0s 1 normal forwards running;
  animation: right-to-left 5s ease 0s 1 normal forwards running;
}

.animation_left-to-center {
  background-position: left center;
}
.active .animation_left-to-center {
  -webkit-animation: left-to-center 5s ease 0s 1 normal forwards running;
  animation: left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_right-to-center {
  background-position: right center;
}
.active .animation_right-to-center {
  -webkit-animation: right-to-center 5s ease 0s 1 normal forwards running;
  animation: right-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-to-bottom {
  background-position: center top;
}
.active .animation_top-to-bottom {
  -webkit-animation: top-to-bottom 5s ease 0s 1 normal forwards running;
  animation: top-to-bottom 5s ease 0s 1 normal forwards running;
}

.animation_top-to-center {
  background-position: center top;
}
.active .animation_top-to-center {
  -webkit-animation: top-to-center 5s ease 0s 1 normal forwards running;
  animation: top-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-to-top {
  background-position: center bottom;
}
.active .animation_bottom-to-top {
  -webkit-animation: bottom-to-top 5s ease 0s 1 normal forwards running;
  animation: bottom-to-top 5s ease 0s 1 normal forwards running;
}

.animation_bottom-to-center {
  background-position: center bottom;
}
.active .animation_bottom-to-center {
  -webkit-animation: bottom-to-center 5s ease 0s 1 normal forwards running;
  animation: bottom-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-left-to-bottom-right {
  background-position: left top;
}
.active .animation_top-left-to-bottom-right {
  -webkit-animation: top-left-to-bottom-right 5s ease 0s 1 normal forwards
    running;
  animation: top-left-to-bottom-right 5s ease 0s 1 normal forwards running;
}

.animation_top-left-to-center {
  background-position: left top;
}
.active .animation_top-left-to-center {
  -webkit-animation: top-left-to-center 5s ease 0s 1 normal forwards running;
  animation: top-left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-right-to-bottom-left {
  background-position: right top;
}
.active .animation_top-right-to-bottom-left {
  -webkit-animation: top-right-to-bottom-left 5s ease 0s 1 normal forwards
    running;
  animation: top-right-to-bottom-left 5s ease 0s 1 normal forwards running;
}

.animation_top-right-to-center {
  background-position: right top;
}
.active .animation_top-right-to-center {
  -webkit-animation: top-right-to-center 5s ease 0s 1 normal forwards running;
  animation: top-right-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-left-to-top-right {
  background-position: left bottom;
}
.active .animation_bottom-left-to-top-right {
  -webkit-animation: bottom-left-to-top-right 5s ease 0s 1 normal forwards
    running;
  animation: bottom-left-to-top-right 5s ease 0s 1 normal forwards running;
}

.animation_bottom-left-to-center {
  background-position: left bottom;
}
.active .animation_bottom-left-to-center {
  -webkit-animation: bottom-left-to-center 5s ease 0s 1 normal forwards running;
  animation: bottom-left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-right-to-top-left {
  background-position: right bottom;
}
.active .animation_bottom-right-to-top-left {
  -webkit-animation: bottom-right-to-top-left 5s ease 0s 1 normal forwards
    running;
  animation: bottom-right-to-top-left 5s ease 0s 1 normal forwards running;
}

.animation_bottom-right-to-center {
  background-position: right bottom;
}
.active .animation_bottom-right-to-center {
  -webkit-animation: bottom-right-to-center 5s ease 0s 1 normal forwards running;
  animation: bottom-right-to-center 5s ease 0s 1 normal forwards running;
}

/* Alignments
--------------------------------------------- */
.alignleft {
  /*rtl:ignore*/
  float: left;
  /*rtl:ignore*/
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}

.alignright {
  /*rtl:ignore*/
  float: right;
  /*rtl:ignore*/
  margin-left: 1.5em;
  margin-bottom: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
}

@keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes playVideo1 {
  0% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
  }
}
@keyframes playVideo1 {
  0% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
  }
}
@keyframes playVideo2 {
  0% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.5);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
  }
}
@keyframes playVideo2 {
  0% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.5);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
  }
}
@keyframes playVideo3 {
  0% {
    -webkit-box-shadow: 0 0 0px 17px #4069ff;
    box-shadow: 0 0 0px 17px #4069ff;
  }
  40% {
    -webkit-box-shadow: 0 0 0px 6px #4069ff;
    box-shadow: 0 0 0px 6px #4069ff;
  }
  80% {
    -webkit-box-shadow: 0 0 0px 12px #4069ff;
    box-shadow: 0 0 0px 12px #4069ff;
  }
  100% {
    -webkit-box-shadow: 0 0 0px 17px #4069ff;
    box-shadow: 0 0 0px 17px #4069ff;
  }
}
@keyframes playVideo3 {
  0% {
    -webkit-box-shadow: 0 0 0px 17px #4069ff;
    box-shadow: 0 0 0px 17px #4069ff;
  }
  40% {
    -webkit-box-shadow: 0 0 0px 6px #4069ff;
    box-shadow: 0 0 0px 6px #4069ff;
  }
  80% {
    -webkit-box-shadow: 0 0 0px 12px #4069ff;
    box-shadow: 0 0 0px 12px #4069ff;
  }
  100% {
    -webkit-box-shadow: 0 0 0px 17px #4069ff;
    box-shadow: 0 0 0px 17px #4069ff;
  }
}
@keyframes playVideo4 {
  0% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
  }
}
@keyframes playVideo4 {
  0% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 0px rgba(64, 105, 255, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 5px rgba(64, 105, 255, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
    box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
  }
}
@keyframes playVideo5 {
  0% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.1);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
  }
}
@keyframes playVideo5 {
  0% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
  }
  40% {
    -webkit-box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.1);
  }
  80% {
    -webkit-box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 10px rgba(255, 255, 255, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
  }
}
@keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes scale5 {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes moverightbounce {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes moverightbounce {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0px;
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
@keyframes shakeMe {
  0%,
  20%,
  60%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
}
@keyframes shakeMe {
  0%,
  20%,
  60%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
}
/* ----------------------------------
  All Aniamtion Styles
 ------------------------------------ */
@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale /* Safari and Chrome */ {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes playscale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes move-bottom {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes move-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.theme-btn {
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 18px 37.8px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #4069ff;
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.theme-btn:after {
  background: #ff9c00;
}
.theme-btn.btn__2 {
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.theme-btn.btn__2:after {
  background: #ef0548 !important;
  top: 50%;
  left: 50%;
  content: "";
  width: 100%;
  height: 0%;
  z-index: -1;
  position: absolute;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.theme-btn.btn__2:hover,
.theme-btn.btn__2:focus {
  color: #fff !important;
}
.theme-btn.btn__2:hover:after,
.theme-btn.btn__2:focus:after {
  height: 380%;
}
@media (max-width: 900px) {
  .theme-btn.btn_sm__2 {
    padding: 12px 25px;
  }
}
@media (max-width: 767px) {
  .theme-btn {
    padding: 17px 30px;
    font-size: 14px;
  }
}
@media (max-width: 580px) {
  .theme-btn {
    padding: 16px 26px;
  }
}
@media (max-width: 480px) {
  .theme-btn {
    padding: 15px 25px;
  }
}
.theme-btn i {
  font-size: 12px;
  margin-left: 10px;
}
.theme-btn.btn-white {
  color: #0e1e36;
  background-color: #fff;
}
.theme-btn.btn-white:after {
  background: #ff9c00;
}
.theme-btn:hover,
.theme-btn:focus {
  color: #fff;
  background-color: #ff9c00;
}
.theme-btn.btn-sm {
  padding: 15px 29px;
}
.theme-btn.btn-yellow {
  background-color: #ff9c00;
}
.theme-btn.btn-yellow:after {
  background: #4069ff;
}
.theme-btn.btn-yellow:hover,
.theme-btn.btn-yellow:focus {
  background: #4069ff;
}
.theme-btn.btn-md {
  padding-top: 20px;
  padding-bottom: 20px;
}
.theme-btn.btn-red {
  background-color: #ef0548;
}
.theme-btn.btn-red:after {
  background: #ff9c00;
}
.theme-btn.btn-red:hover,
.theme-btn.btn-red:focus {
  background-color: #ef0548;
}
.theme-btn.btn-black {
  padding: 8px 79.1px;
  background-color: #0e0a01;
}
.theme-btn.btn-black:after {
  background: #4069ff;
}
.theme-btn.btn-black:hover,
.theme-btn.btn-black:focus {
  background: #4069ff;
}
.theme-btn.btn-transparent {
  color: #ff9c00;
  padding: 13.5px 31.1px;
  background: transparent;
  border: 1.5px solid rgba(255, 156, 0, 0.3);
}
.theme-btn.btn-transparent i {
  margin: 0 10px 0 0;
}
.theme-btn.btn-transparent:after {
  background: #4069ff;
}
.theme-btn.btn-transparent:hover,
.theme-btn.btn-transparent:focus {
  color: #000;
  background: #4069ff;
  border-color: #4069ff;
}
.theme-btn.btn-border {
  color: #243342;
  padding: 13.5px 26.5px;
  background: transparent;
  border: 1.5px solid rgba(36, 51, 66, 0.5);
}
.theme-btn.btn-border:after {
  background: #243342;
}
.theme-btn.btn-border:hover,
.theme-btn.btn-border:focus {
  color: #fff;
  background: #243342;
}
.theme-btn.btn-yellow-transparent {
  color: #ff9c00;
  padding: 13.5px 31.1px;
  background: transparent;
  border: 1.5px solid rgba(255, 156, 0, 0.3);
}
.theme-btn.btn-yellow-transparent:after {
  background: #4069ff;
}
.theme-btn.btn-yellow-transparent:hover,
.theme-btn.btn-yellow-transparent:focus {
  color: #fff;
  border-width: 1px;
  padding: 14px 31.6px;
  border-color: #4069ff;
  background: transparent;
}
.theme-btn:after {
  top: 50%;
  left: 50%;
  content: "";
  width: 100%;
  height: 0%;
  z-index: -1;
  position: absolute;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.theme-btn:hover:after,
.theme-btn:focus:after {
  height: 380%;
}

.video_btn {
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px dotted #fff;
  border-radius: 500px;
  margin: 0 auto;
}
.video_btn .theme-btn-wrapper {
  -webkit-animation: playscale 3s linear infinite;
  animation: playscale 3s linear infinite;
  width: 85px;
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  border-radius: 500px;
  border: 1px dotted #fff;
}
.video_btn .theme-btn-wrapper .popup-video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  line-height: 85px;
  color: #4069ff;
}

/***********************************
PAGE LOAD ANIMATION
***********************************/
.preloader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: default;
  position: fixed;
  z-index: 9999999;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.preloader .animation-preloader {
  z-index: 1000;
}
.preloader .animation-preloader .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #342;
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}
@media (max-width: 767px) {
  .preloader .animation-preloader .spinner {
    width: 7.5em;
    height: 7.5em;
    margin: 0 auto 1.5em auto;
  }
}
.preloader .animation-preloader .txt-loading {
  font-size: 80px;
  line-height: 118px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 700;
}
@media (max-width: 767px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 50px;
    line-height: 77px;
  }
}
@media (max-width: 575px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 36px;
    line-height: 58px;
  }
}
.preloader .animation-preloader .txt-loading .letters-loading {
  color: #ef0548;
  position: relative;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.preloader .animation-preloader .txt-loading .letters-loading::before {
  -webkit-animation: letters-loading 4s infinite;
  animation: letters-loading 4s infinite;
  color: #4069ff;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  position: absolute;
  top: -3px;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}
.preloader p {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: #243342;
}
.preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}
.preloader .loader .row {
  height: 100%;
}
.preloader .loader .loader-section {
  padding: 0px;
}
.preloader .loader .loader-section .bg {
  background-color: #fff;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}
.preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

/* ----------------------------------
    Template Section Styles
 ------------------------------------*/
header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed !important;
}
header.transparent {
  background: transparent;
}
header.transparent .main-header-wraper {
  background: transparent !important;
}
header .logo a {
  display: block;
}
header.header-1 {
  position: relative;
}
header.header-1 .main-menu.main-menu_4 ul > li:hover > a,
header.header-1 .main-menu.main-menu_4 ul > li:focus > a,
header.header-1 .main-menu.main-menu_4 ul > li.active > a {
  color: #4069ff;
}
header.header-1 .main-menu ul > li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
header.header-1 .main-menu ul > li > a {
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  line-height: 19px;
  color: #0e1e36;
  padding: 40.5px 24px;
  display: inline-block;
  text-transform: capitalize;
}
@media (max-width: 1299px) {
  header.header-1 .main-menu ul > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
}
header.header-1 .main-menu ul > li > a i {
  margin-left: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-1 .main-menu ul > li:hover > a,
header.header-1 .main-menu ul > li:focus > a,
header.header-1 .main-menu ul > li.active > a {
  color: #4069ff;
}
header.header-1 .main-menu ul > li:hover > a i,
header.header-1 .main-menu ul > li:focus > a i,
header.header-1 .main-menu ul > li.active > a i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
header.header-1 .main-menu ul > li:hover > ul,
header.header-1 .main-menu ul > li:focus > ul,
header.header-1 .main-menu ul > li.active > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  height: 300px;
  overflow: auto;
}
header.header-1 .main-menu ul > li > ul {
  left: 0;
  top: 100%;
  opacity: 0;
  min-width: 230px;
  z-index: 999;
  position: absolute;
  visibility: hidden;
  padding: 10px 0px;
  background-color: #fff;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}
header.header-1 .main-menu ul > li > ul li {
  display: block;
}
header.header-1 .main-menu ul > li > ul li a {
  display: block;
  color: #0e1e36;
  padding: 12px 25px;
}
header.header-1 .main-menu ul > li > ul li a:hover {
  color: #4069ff;
}
header.header-1 .main-menu ul > li > ul ul {
  top: 0;
  left: 100%;
}
header.header-1 .top-header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;
  padding: 9px 15px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed !important;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));
}
header.header-1 .top-header.top-header-2 {
  padding: 0;
  background-color: #fff;
}
header.header-1 .top-header.top-header-2 .header-cta ul > li > a {
  color: #243342;
}
header.header-1 .top-header.top-header-2 .header-cta ul > li > a i {
  color: #ff9c00;
}
header.header-1 .top-header.top-header-2 .social-profile a i {
  color: #243342;
}
header.header-1 .top-header.top-header_4 .header-cta i {
  color: #4069ff !important;
}
header.header-1 .top-header.top-header_4 .social-profile li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
header.header-1 .top-header.top-header_4 .social-profile li a:hover,
header.header-1 .top-header.top-header_4 .social-profile li a:focus {
  background-color: #4069ff !important;
}
header.header-1 .top-header .header-cta ul {
  margin: 0;
  padding: 0;
  gap: 49.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 991px) {
  header.header-1 .top-header .header-cta ul {
    gap: 40px;
  }
}
header.header-1 .top-header .header-cta ul li {
  position: relative;
}
header.header-1 .top-header .header-cta ul li::before {
  top: 50%;
  width: 1.5px;
  content: "";
  height: 30px;
  right: -24.75px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.3);
}
@media (max-width: 991px) {
  header.header-1 .top-header .header-cta ul li::before {
    right: -18.5px;
  }
}
header.header-1 .top-header .header-cta ul li:last-child::before {
  display: none;
}
header.header-1 .top-header .header-cta ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  font-family: "Lato", sans-serif;
  cursor: default;
  display: inline-block;
}
header.header-1 .top-header .header-cta ul li a i {
  margin-right: 10px;
}
header.header-1 .top-header .header-cta ul li a:hover:-moz-any-link,
header.header-1 .top-header .header-cta ul li a:focus:-moz-any-link {
  color: #000;
  cursor: pointer;
}
header.header-1 .top-header .header-cta ul li a:hover:any-link,
header.header-1 .top-header .header-cta ul li a:focus:any-link {
  color: #000;
  cursor: pointer;
}
header.header-1 .top-header .header-right-socail {
  gap: 30px;
}
@media (max-width: 991px) {
  header.header-1 .top-header .header-right-socail {
    gap: 20px;
  }
}
header.header-1 .top-header .header-right-socail h6 {
  font-size: 15px;
  line-height: 18px;
}
header.header-1 .top-header .header-right-socail .social-profile ul {
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  header.header-1 .top-header .header-right-socail .social-profile ul {
    gap: 15px;
  }
}
header.header-1 .top-header .header-right-socail .social-profile ul li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
header.header-1 .top-header .header-right-socail .social-profile ul li a:hover,
header.header-1 .top-header .header-right-socail .social-profile ul li a:focus {
  color: #000;
}
header.header-1 .main-header-wraper {
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 1030;
  padding: 0 15px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed !important;
}
header.header-1 .main-header-wraper.main-header-wraper-2 {
  background: #0e0a01 !important;
}
@media (max-width: 1199px) {
  header.header-1 .main-header-wraper {
    padding: 18px 0;
  }
}
@media (max-width: 767px) {
  header.header-1 .main-header-wraper {
    top: 0;
  }
}
header.header-1 .main-header-wraper .header-menu {
  margin-right: auto;
  padding-left: 76px !important;
}
@media (max-width: 1299px) {
  header.header-1 .main-header-wraper .header-menu {
    padding-left: 60px !important;
  }
}
header.header-1 .main-header-wraper .header-right {
  gap: 30px;
  padding-right: 55px !important;
}
@media (max-width: 1299px) {
  header.header-1 .main-header-wraper .header-right {
    gap: 20px;
    padding-right: 0 !important;
  }
}
header.header-1 .main-header-wraper .header-right .header-search {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search.header-search_2
  .search-toggle:hover {
  color: #4069ff !important;
}
@media (max-width: 575px) {
  header.header-1 .main-header-wraper .header-right .header-search {
    margin-right: 15px;
  }
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle {
  cursor: pointer;
  color: #0e1e36;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle:hover {
  color: #4069ff;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle
  span {
  font-size: 20px;
  font-weight: 500;
}
header.header-1 .main-header-wraper .header-right .header-search .search-box {
  width: 0;
  left: 50%;
  border: 0;
  opacity: 0;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  background: #fff;
  top: calc(100% + 30px);
  -webkit-filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
  -webkit-transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 1200px) {
  header.header-1 .main-header-wraper .header-right .header-search .search-box {
    top: calc(100% + 45px);
  }
}
@media (max-width: 767px) {
  header.header-1 .main-header-wraper .header-right .header-search .search-box {
    left: -285%;
  }
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box:before {
  left: 0;
  right: 0;
  top: -5px;
  z-index: -1;
  content: "";
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 2px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 480px) {
  header.header-1
    .main-header-wraper
    .header-right
    .header-search
    .search-box:before {
    right: -132px;
  }
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group {
  margin: 0;
  padding: 0;
  position: relative;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group:before {
  left: 0;
  width: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 1px;
  margin-left: auto;
  background: #25415d;
  margin-right: auto;
  position: absolute;
  -webkit-transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input {
  width: 100%;
  border: 0 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
  line-height: 22px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-right: 22px !important;
  color: #243342 !important;
  background: transparent !important;
  border-radius: 0 !important;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-webkit-input-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-moz-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-ms-input-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-moz-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit {
  right: 0;
  top: auto;
  border: 0;
  bottom: auto;
  font-size: 16px;
  color: #243342;
  position: absolute;
  background: transparent;
  -webkit-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease ease-in-out;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:hover {
  color: #0e0a01;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search.show
  .search-toggle {
  color: #4069ff;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search.show
  .search-box {
  opacity: 1;
  width: 530px;
  visibility: visible;
}
@media (max-width: 767px) {
  header.header-1
    .main-header-wraper
    .header-right
    .header-search.show
    .search-box {
    width: 280px;
  }
}
header.header-1
  .main-header-wraper
  .header-right
  .header-search.show
  .search-box
  .form-group:before {
  width: 100%;
}
header.header-1 .main-header-wraper .header-right .horizontal-bar {
  width: 1px;
  height: 40px;
  background: #d9d9d9;
}
header.header-1 .main-header-wraper .header-right .header-contact {
  gap: 15px;
  color: #0e1e36;
}
header.header-1 .main-header-wraper .header-right .header-contact:hover .icon i,
header.header-1
  .main-header-wraper
  .header-right
  .header-contact:focus
  .icon
  i {
  color: #ff9c00;
}
header.header-1
  .main-header-wraper
  .header-right
  .header-contact:hover
  .text
  span,
header.header-1
  .main-header-wraper
  .header-right
  .header-contact:hover
  .text
  h5,
header.header-1
  .main-header-wraper
  .header-right
  .header-contact:focus
  .text
  span,
header.header-1
  .main-header-wraper
  .header-right
  .header-contact:focus
  .text
  h5 {
  color: #ff9c00;
}
header.header-1 .main-header-wraper .header-right .header-contact .icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
header.header-1 .main-header-wraper .header-right .header-contact .icon i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-1 .main-header-wraper .header-right .header-contact .icon img {
  width: 100%;
  height: 100%;
}
header.header-1 .main-header-wraper .header-right .header-contact .text h5 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-1 .main-header-wraper .header-right .header-contact .text span {
  font-size: 14px;
  line-height: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customClsWhite {
  color: #fff !important;
}
.ch:hover .customClsWhite {
  color: #ff9c00 !important
}
.customClsWhite:hover {
  color:#4069ff !important
}
header.header-1.stop .top-header {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
header.header-1.stop .main-header-wraper {
  top: 0;
  background: #fff !important;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.16));
}
header.bg_dark .main-header-wraper {
  background: #eff0f6;
}
header.bg_dark .main-menu ul > li > ul {
  background-color: #eff0f6;
}
header.bg_dark .header-right .header-search .search-box {
  background: #eff0f6 !important;
}
header.bg_dark .header-right .header-search .search-box:before {
  background: #eff0f6 !important;
}
header.header-3 .top-header {
  background: #0e1e36;
}
header.header-3 .top-header .header-right-socail .social-profile ul li a:hover,
header.header-3 .top-header .header-right-socail .social-profile ul li a:focus {
  color: #4069ff;
}
header.header-3 .top-header .header-cta ul {
  gap: 34px;
}
header.header-3 .top-header .header-cta ul li::before {
  content: inherit;
}
header.header-3 .top-header .header-cta ul li a i {
  color: #4069ff;
}
header.header-3 .top-header .header-cta.header-cta-2 a i {
  color: #ff9c00;
}
header.header-3 .main-header-wraper .main_wrapper {
  padding: 5px 10px;
  border-radius: 4px;
  width: 1386px;
  position: absolute;
  z-index: 999;
  max-width: 100%;
  background-color: rgb(255, 255, 255);
  margin: 0 auto !important;
}
@media (max-width: 1400px) {
  header.header-3 .main-header-wraper .main_wrapper {
    width: 1320px;
    margin: 0 auto !important;
  }
}
@media (max-width: 1300px) {
  header.header-3 .main-header-wraper .main_wrapper {
    width: 1140px;
  }
}
@media (max-width: 1200px) {
  header.header-3 .main-header-wraper .main_wrapper {
    position: relative;
    width: 100%;
    max-width: none;
  }
}
header.header-3 .main-header-wraper .header-right .horizontal-bar {
  background: rgba(14, 30, 54, 0.15);
}
header.header-2.stop .main-header-wraper {
  background: #000 !important;
}
header.header-2 .top-header {
  padding: 27.5px 0;
  background: transparent;
}
header.header-2 .top-header .header-right-socail {
  gap: 50px;
}
@media (max-width: 1399px) {
  header.header-2 .top-header .header-right-socail {
    gap: 30px;
  }
}
header.header-2 .top-header .header-right-socail .header-contact {
  gap: 10px;
  display: block;
  cursor: default;
  color: #0e1e36;
}
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:-moz-any-link,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:-moz-any-link {
  cursor: pointer;
}
header.header-2 .top-header .header-right-socail .header-contact:hover:any-link,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:any-link {
  cursor: pointer;
}
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:-moz-any-link
  .icon
  i,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:-moz-any-link
  .icon
  i {
  color: #4069ff;
}
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:any-link
  .icon
  i,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:any-link
  .icon
  i {
  color: #4069ff;
}
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:-moz-any-link
  .text
  span,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:-moz-any-link
  .text
  h5,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:-moz-any-link
  .text
  span,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:-moz-any-link
  .text
  h5 {
  color: #4069ff !important;
}
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:any-link
  .text
  span,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:hover:any-link
  .text
  h5,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:any-link
  .text
  span,
header.header-2
  .top-header
  .header-right-socail
  .header-contact:focus:any-link
  .text
  h5 {
  color: #4069ff !important;
}
header.header-2 .top-header .header-right-socail .header-contact .icon i {
  font-size: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-2
  .top-header
  .header-right-socail
  .header-contact
  .icon
  i:not(.icon-phone) {
  font-size: 22px;
}
header.header-2 .top-header .header-right-socail .header-contact .icon img {
  width: 100%;
  height: 100%;
}
header.header-2 .top-header .header-right-socail .header-contact .text h5 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1399px) {
  header.header-2 .top-header .header-right-socail .header-contact .text h5 {
    font-size: 18px;
    line-height: 24px;
  }
}
header.header-2 .top-header .header-right-socail .header-contact .text span {
  font-size: 14px;
  line-height: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-2 .main-menu ul > li > a {
  color: #fff;
  font-weight: 600;
  padding: 28px 24px;
}
header.header-2 .main-menu ul > li > a:hover,
header.header-2 .main-menu ul > li > a:focus {
  color: #ff9c00;
}
header.header-2 .main-menu ul > li > a i {
  color: #ff9c00;
}
header.header-2 .main-header-wraper {
  top: 100px;
}
@media (max-width: 1199px) {
  header.header-2 .main-header-wraper {
    top: 0;
  }
}
header.header-2 .main-header-wraper .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important;
}
@media (max-width: 1199px) {
  header.header-2 .main-header-wraper .border-top {
    border: inherit !important;
  }
}
header.header-2 .main-header-wraper .social-profile ul {
  gap: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
header.header-2 .main-header-wraper .social-profile ul li {
  position: relative;
}
header.header-2 .main-header-wraper .social-profile ul li.no_bullet:after {
  content: inherit;
}
header.header-2 .main-header-wraper .social-profile ul li:after {
  content: "";
  width: 1px;
  right: -16px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  background: rgba(255, 255, 255, 0.1);
}
header.header-2 .main-header-wraper .social-profile ul li a {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
header.header-2 .main-header-wraper .social-profile ul li a:hover,
header.header-2 .main-header-wraper .social-profile ul li a:focus {
  color: #ff9c00;
}
header.header-2 .main-header-wraper .header-menu {
  margin-left: auto;
}
header.header-2 .main-header-wraper .header-menu ul > li > ul {
  background: #000;
}
header.header-2 .main-header-wraper .header-menu ul > li > ul li a {
  color: #fff;
}
header.header-2 .main-header-wraper .header-menu ul > li > ul li a:hover {
  color: #ff9c00;
}
header.header-2 .main-header-wraper .header-right {
  gap: 40px;
  padding-right: 0 !important;
}
@media (max-width: 1399px) {
  header.header-2 .main-header-wraper .header-right {
    gap: 30px;
  }
}
@media (max-width: 420px) {
  header.header-2 .main-header-wraper .header-right {
    gap: 20px;
  }
}
@media (max-width: 360px) {
  header.header-2 .main-header-wraper .header-right {
    gap: 15px;
  }
}
header.header-2 .main-header-wraper .header-right .header-search {
  margin-right: 0 !important;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search.show
  .search-toggle {
  color: #ff9c00;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle {
  color: #fff;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle
  span {
  font-weight: 300;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle:hover {
  color: #ff9c00;
}
header.header-2 .main-header-wraper .header-right .header-search .search-box {
  left: -678%;
  top: calc(100% + 30px);
}
@media (max-width: 767px) {
  header.header-2 .main-header-wraper .header-right .header-search .search-box {
    left: -157%;
  }
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box:before {
  right: -296px;
}
@media (max-width: 767px) {
  header.header-2
    .main-header-wraper
    .header-right
    .header-search
    .search-box:before {
    right: -78px;
  }
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group::before {
  background: #ff9c00;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit,
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input {
  color: #ff9c00 !important;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-webkit-input-placeholder,
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-webkit-input-placeholder {
  color: #ff9c00;
  font-size: 16px;
  font-weight: 400;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-moz-placeholder,
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-moz-placeholder {
  color: #ff9c00;
  font-size: 16px;
  font-weight: 400;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:-ms-input-placeholder,
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-ms-input-placeholder {
  color: #ff9c00;
  font-size: 16px;
  font-weight: 400;
}
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:-moz-placeholder,
header.header-2
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-moz-placeholder {
  color: #ff9c00;
  font-size: 16px;
  font-weight: 400;
}
header.header-2 .main-header-wraper .header-right .horizontal-bar {
  height: 25px;
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 350px) {
  header.header-2 .main-header-wraper .header-right .horizontal-bar {
    display: none;
  }
}
header.header-2 .main-header-wraper .header-right .shop-cart {
  color: #fff;
  margin-right: 7px;
  position: relative;
}
header.header-2 .main-header-wraper .header-right .shop-cart i {
  font-size: 20px;
}
header.header-2 .main-header-wraper .header-right .shop-cart .number {
  top: -4px;
  right: -7px;
  width: 15px;
  height: 15px;
  font-size: 10px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  line-height: 15px;
  text-align: center;
  position: absolute;
  border-radius: 500px;
  background: #ff9c00;
}
header.header-2 #hamburger {
  color: #ff9c00 !important;
}
header.header-3 .top-header {
  padding-top: 0;
  padding-bottom: 0;
}
header.header-3 .top-header .header-right-socail {
  gap: 20px;
}
header.header-3 .top-header .header-right-socail .social-profile ul {
  gap: 0;
}
header.header-3 .top-header .header-right-socail .social-profile ul li a {
  display: block;
  font-size: 15px;
  padding: 8px 20.5px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
header.header-3 .top-header .header-right-socail .social-profile ul li a:hover,
header.header-3 .top-header .header-right-socail .social-profile ul li a:focus {
  background: #ef0548;
  color: #fff;
}
header.header-3 .top-header .header-cta ul {
  gap: 40px;
}
header.header-3 .top-header .header-cta ul li {
  z-index: 1;
  position: relative;
}
header.header-3 .top-header .header-cta ul li:last-child:after {
  content: inherit;
}
header.header-3 .top-header .header-cta ul li:after {
  width: 1px;
  top: -13px;
  z-index: -1;
  height: 50px;
  right: -20px;
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.15);
}
header.header-3 .top-header .header-cta ul li a {
  color: #fff;
  cursor: default;
  font-size: 15px;
  font-weight: 400;
}
header.header-3 .top-header .header-cta ul li a:hover:-moz-any-link,
header.header-3 .top-header .header-cta ul li a:focus:-moz-any-link {
  color: #ef0548;
  cursor: pointer;
}
header.header-3 .top-header .header-cta ul li a:hover:any-link,
header.header-3 .top-header .header-cta ul li a:focus:any-link {
  color: #ef0548;
  cursor: pointer;
}
header.header-3 .top-header .header-cta ul li a i {
  color: #ef0548;
  font-size: 15px;
}
header.header-3 .top-header .header-cta ul li a i.icon-phone {
  font-size: 16px !important;
}
header.header-3 .top-header .header-cta ul li a i.icon-email {
  font-size: 12px !important;
}
header.header-3 .header-menu {
  margin-left: auto;
}
header.header-3 .header-menu ul > li:hover > a,
header.header-3 .header-menu ul > li:focus > a,
header.header-3 .header-menu ul > li.active > a {
  color: #ef0548;
}
header.header-3 .header-menu ul > li a {
  color: #04213f;
  padding: 33px 20px;
}
header.header-3 .header-menu ul > li > ul li a:hover {
  color: #ef0548;
}
@media (max-width: 1199px) {
  header.header-3 .main-header-wraper {
    top: 0;
  }
}
header.header-3 .main-header-wraper .header-right {
  padding-right: 0 !important;
}
header.header-3 .main-header-wraper .header-right .header-search {
  margin-right: 0 !important;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search.show
  .search-toggle {
  color: #ef0548;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle {
  color: #04213f;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle
  span {
  font-weight: 300;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle:hover {
  color: #ef0548;
}
header.header-3 .main-header-wraper .header-right .header-search .search-box {
  left: -678%;
  top: calc(100% + 37px);
}
@media (max-width: 767px) {
  header.header-3 .main-header-wraper .header-right .header-search .search-box {
    left: -157%;
  }
}
@media (max-width: 500px) {
  header.header-3 .main-header-wraper .header-right .header-search .search-box {
    left: -344%;
    top: calc(100% + 40px);
  }
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box:before {
  right: -296px;
}
@media (max-width: 767px) {
  header.header-3
    .main-header-wraper
    .header-right
    .header-search
    .search-box:before {
    right: -78px;
  }
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group::before {
  background: #ef0548;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit,
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input {
  color: #ef0548 !important;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-webkit-input-placeholder,
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-webkit-input-placeholder {
  color: #ef0548;
  font-size: 16px;
  font-weight: 500;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-moz-placeholder,
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-moz-placeholder {
  color: #ef0548;
  font-size: 16px;
  font-weight: 500;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:-ms-input-placeholder,
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-ms-input-placeholder {
  color: #ef0548;
  font-size: 16px;
  font-weight: 500;
}
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:-moz-placeholder,
header.header-3
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-moz-placeholder {
  color: #ef0548;
  font-size: 16px;
  font-weight: 500;
}
header.header-3 .main-header-wraper .header-btn {
  border: 0;
  z-index: 1;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 15px 28px;
  background-color: #ef0548;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1199px) {
  header.header-3 .main-header-wraper .header-btn {
    padding: 12.5px 28px;
  }
}
@media (max-width: 500px) {
  header.header-3 .main-header-wraper .header-btn {
    display: none;
  }
}
header.header-3 .main-header-wraper .header-btn:after {
  top: 50%;
  left: 50%;
  height: 0%;
  width: 100%;
  content: "";
  z-index: -1;
  position: absolute;
  background: #ff9c00;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
header.header-3 .main-header-wraper .header-btn i {
  font-size: 12px;
  margin-left: 10px;
}
header.header-3 .main-header-wraper .header-btn:hover,
header.header-3 .main-header-wraper .header-btn:focus {
  background: #ff9c00;
}
header.header-3 .main-header-wraper .header-btn:hover:after,
header.header-3 .main-header-wraper .header-btn:focus:after {
  height: 380%;
}
header.header-3 .main-header-wraper #hamburger {
  color: #ef0548;
}
header.header-4 .main-nav-menu {
  background: #000000;
  padding: 0 0;
}
@media (max-width: 1200px) {
  header.header-4 .main-nav-menu {
    padding: 0px 15px;
  }
}
header.header-4 .social-profile a:hover i {
  color: #ff9c00 !important;
}
header.header-4 .main-menu li a {
  color: #fff !important;
  padding: 15px 10px !important;
}
header.header-4 .main-menu li:hover a {
  color: #ff9c00 !important;
}
header.header-4 .main-menu li ul {
  min-width: 200px !important;
}
header.header-4 .main-menu li ul > li a {
  color: #4069ff !important;
}
header.header-4 .main-menu li ul > li:hover a {
  color: #ff9c00 !important;
}
header.header-4 .header-right {
  gap: 20px;
  padding-right: 0px !important;
}
@media (max-width: 1299px) {
  header.header-4 .header-right {
    gap: 20px;
    padding-right: 0 !important;
  }
}
header.header-4 .header-right .header-search {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@media (max-width: 575px) {
  header.header-4 .header-right .header-search {
    margin-right: 15px;
  }
}
header.header-4 .header-right .header-search .search-toggle {
  cursor: pointer;
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: #ff9c00;
}
header.header-4 .header-right .header-search .search-toggle:hover {
  background-color: #fff;
  color: #ff9c00;
}
header.header-4 .header-right .header-search .search-toggle span {
  font-size: 20px;
  font-weight: 500;
}
header.header-4 .header-right .header-search .search-box {
  width: 0;
  left: 50%;
  border: 0;
  opacity: 0;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  background: #fff;
  top: calc(100% + 30px);
  -webkit-filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
  -webkit-transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 1200px) {
  header.header-4 .header-right .header-search .search-box {
    top: calc(100% + 45px);
  }
}
@media (max-width: 767px) {
  header.header-4 .header-right .header-search .search-box {
    left: -285%;
  }
}
header.header-4 .header-right .header-search .search-box:before {
  left: 0;
  right: 0;
  top: -5px;
  z-index: -1;
  content: "";
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 2px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 480px) {
  header.header-4 .header-right .header-search .search-box:before {
    right: -132px;
  }
}
header.header-4 .header-right .header-search .search-box .form-group {
  margin: 0;
  padding: 0;
  position: relative;
}
header.header-4 .header-right .header-search .search-box .form-group:before {
  left: 0;
  width: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 1px;
  margin-left: auto;
  background: #25415d;
  margin-right: auto;
  position: absolute;
  -webkit-transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input {
  width: 100%;
  border: 0 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
  line-height: 22px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-right: 22px !important;
  color: #243342 !important;
  background: transparent !important;
  border-radius: 0 !important;
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-webkit-input-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-moz-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-ms-input-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-moz-placeholder {
  color: #243342;
  font-size: 16px;
  font-weight: 400;
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit {
  right: 0;
  top: auto;
  border: 0;
  bottom: auto;
  font-size: 16px;
  color: #243342;
  position: absolute;
  background: transparent;
  -webkit-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease ease-in-out;
}
header.header-4
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:hover {
  color: #0e0a01;
}
header.header-4 .header-right .header-search.show .search-toggle {
  color: #4069ff;
}
header.header-4 .header-right .header-search.show .search-box {
  opacity: 1;
  width: 530px;
  visibility: visible;
}
@media (max-width: 767px) {
  header.header-4 .header-right .header-search.show .search-box {
    width: 280px;
  }
}
header.header-4
  .header-right
  .header-search.show
  .search-box
  .form-group:before {
  width: 100%;
}
header.header-4 .header-right .horizontal-bar {
  width: 1px;
  height: 40px;
  background: #d9d9d9;
}
header.header-4 .header-right .header-contact {
  gap: 15px;
  color: #0e1e36;
}
header.header-4 .header-right .header-contact:hover .icon i,
header.header-4 .header-right .header-contact:focus .icon i {
  color: #ff9c00;
}
header.header-4 .header-right .header-contact:hover .text span,
header.header-4 .header-right .header-contact:hover .text h5,
header.header-4 .header-right .header-contact:focus .text span,
header.header-4 .header-right .header-contact:focus .text h5 {
  color: #ff9c00;
}
header.header-4 .header-right .header-contact .icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
header.header-4 .header-right .header-contact .icon i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-4 .header-right .header-contact .icon img {
  width: 100%;
  height: 100%;
}
header.header-4 .header-right .header-contact .text h5 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-4 .header-right .header-contact .text span {
  font-size: 14px;
  line-height: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
header.header-4 .header-right .theme-btn {
  padding: 20px 40px;
}
header.hover-color-green .header-menu ul > li:hover a,
header.hover-color-green .header-menu ul > li:focus a {
  color: #4069ff;
}
header.hover-color-green .header-menu ul > li > ul li a {
  color: #04213f !important;
}
header.hover-color-green .header-menu ul > li > ul li a:hover,
header.hover-color-green .header-menu ul > li > ul li a:focus {
  color: #4069ff !important;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search.show
  .search-toggle,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search:hover
  .search-toggle {
  color: #4069ff;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group:before {
  background: #4069ff;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit {
  color: #4069ff !important;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-webkit-input-placeholder,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-webkit-input-placeholder {
  color: #4069ff;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-moz-placeholder,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-moz-placeholder {
  color: #4069ff;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input:-ms-input-placeholder,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit:-ms-input-placeholder {
  color: #4069ff;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::-ms-input-placeholder,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::-ms-input-placeholder {
  color: #4069ff;
}
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-input::placeholder,
header.hover-color-green
  .main-header-wraper
  .header-right
  .header-search
  .search-box
  .form-group
  .search-submit::placeholder {
  color: #4069ff;
}

.header-gutter {
  top: 150px;
  min-height: 150px;
}
.header-gutter.home {
  top: 135px;
  min-height: 135px;
}
@media (max-width: 1199px) {
  .header-gutter.home {
    top: 85px;
    min-height: 85px;
  }
}
@media (max-width: 1199px) {
  .header-gutter {
    top: 132px;
    min-height: 132px;
  }
}
@media (max-width: 767px) {
  .header-gutter {
    top: 76px;
    min-height: 76px;
  }
}

.page-banner {
  z-index: 1;
  position: relative;
  background-color: #0e1e36;
}
.page-banner:before {
  left: -28px;
  width: 486px;
  content: "";
  z-index: -1;
  bottom: -280px;
  height: 486.71px;
  position: absolute;
  background-image: url("../images/pattern.svg");
}
@media (max-width: 767px) {
  .page-banner:before {
    bottom: -12px;
  }
}
@media (max-width: 480px) {
  .page-banner:before {
    left: -181px;
  }
}
.page-banner__content {
  position: relative;
}
.page-banner__content .transparent-text {
  left: 0;
  top: -34px;
  z-index: -1;
  opacity: 0.3;
  font-size: 128px;
  font-weight: 700;
  line-height: 100px;
  position: absolute;
  white-space: nowrap;
  -webkit-text-stroke: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
}
@media (max-width: 1346px) {
  .page-banner__content .transparent-text {
    top: -26px;
    font-size: 120px;
    line-height: 80px;
  }
}
@media (max-width: 1299px) {
  .page-banner__content .transparent-text {
    font-size: 106px;
  }
}
@media (max-width: 1199px) {
  .page-banner__content .transparent-text {
    top: -15px;
    font-size: 80px;
    line-height: 60px;
  }
}
@media (max-width: 991px) {
  .page-banner__content .transparent-text {
    top: -18px;
    font-size: 60px;
  }
}
@media (max-width: 380px) {
  .page-banner__content .transparent-text {
    font-size: 54px;
  }
}
.page-banner__content h1 {
  color: #fff;
  font-weight: 700;
}
.page-banner__content h1 span {
  font-weight: 300;
}
@media (max-width: 1199px) {
  .page-banner__content h1 {
    font-size: 54px;
    line-height: 68px;
  }
}
@media (max-width: 991px) {
  .page-banner__content h1 {
    font-size: 40px;
    line-height: 54px;
  }
}
@media (max-width: 380px) {
  .page-banner__content h1 {
    font-size: 35px;
    line-height: 49px;
  }
}
.page-banner .breadcrumb {
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
}
.page-banner .breadcrumb .breadcrumb-item {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.page-banner .breadcrumb .breadcrumb-item a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.page-banner .breadcrumb .breadcrumb-item a:hover {
  color: #4069ff;
}
.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 15px;
}
.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #4069ff;
  padding-right: 15px;
  content: var(--bs-breadcrumb-divider, "|");
}
.page-banner .breadcrumb .breadcrumb-item.active {
  color: #4069ff;
}
.page-banner__media {
  max-height: 450px;
  position: relative;
  margin-right: calc(-50vw + 50%) !important;
}
.page-banner__media:after {
  top: 0;
  width: 100%;
  left: -1px;
  content: "";
  z-index: 1;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-image: url(../images/page-banner-overly.png);
}
.page-banner__media:before {
  content: "";
  top: -395px;
  z-index: 1;
  left: 790px;
  width: 144.86px;
  height: 1194.97px;
  position: absolute;
  -webkit-transform: rotate(-30.99deg);
  transform: rotate(-30.99deg);
  background: rgba(64, 105, 255, 0.5);
}
@media (max-width: 767px) {
  .page-banner__media:before {
    top: 0;
    height: 100%;
    left: -9999px;
    width: 9999px;
    -webkit-transform: inherit;
    transform: inherit;
    background-color: #0e1e36;
  }
}
.page-banner__media .start {
  z-index: 2;
  left: 86px;
  bottom: 67px;
  position: absolute;
}
@media (max-width: 1199px) {
  .page-banner__media .start {
    left: 72px;
  }
}
@media (max-width: 1199px) {
  .page-banner__media .start {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .page-banner__media .start {
    left: -5px;
  }
}
@media (max-width: 575px) {
  .page-banner__media .start {
    bottom: 75px;
    max-width: 100px;
  }
}
@media (max-width: 400px) {
  .page-banner__media .start {
    bottom: 40px;
    max-width: 70px;
  }
}

.mobile-nav {
  top: 0;
  width: 320px;
  right: -380px;
  max-width: 100%;
  padding: 20px;
  z-index: 1032;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: #4069ff;
  -webkit-transition: right 0.5s ease-in;
  transition: right 0.5s ease-in;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}
.mobile-nav.show {
  right: 0px;
}
.mobile-nav-yellow {
  background-color: #ff9c00;
}
.mobile-nav-yellow
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu {
  background-color: #ff9c00 !important;
}
.mobile-nav-red {
  background-color: #ef0548;
}
.mobile-nav-red
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu {
  background-color: #ef0548 !important;
}
.mobile-nav .close-nav {
  border: 0;
  left: 20px;
  top: 10px;
  color: #fff;
  font-size: 25px;
  position: absolute;
  border-radius: 30px;
  background: transparent;
}
.mobile-nav .navigation {
  padding-top: 30px;
}
.mobile-nav .navigation .consulter-mobile-nav {
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-nav .navigation ul:not(.dropdown-menu) {
  position: relative;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li {
  width: 100%;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li:last-child {
  border-bottom: inherit;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li > a,
.navigation ul:not(.dropdown-menu) > li > span {
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: transparent;
  text-transform: capitalize;
  cursor: pointer;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li.dropdown {
  float: none;
  position: static !important;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li.dropdown > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  > span
  .dropdown-toggle {
  width: 30px;
  text-align: center;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  > span
  .dropdown-toggle:after {
  vertical-align: 0;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li.dropdown .dropdown-menu {
  top: 0;
  border: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  width: 100%;
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-top: 15px;
  position: absolute;
  background: #4069ff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translate3d(110%, 0, 0);
  transform: translate3d(110%, 0, 0);
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu
  .control
  svg {
  width: 20px;
  height: 20px;
  color: #fff;
  margin-left: 7px;
  display: inline-block;
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li.dropdown .dropdown-menu li {
  margin-bottom: 15px;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu
  li
  > a {
  color: #fff;
  display: block;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: transparent;
  text-transform: capitalize;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu
  li.dropdown-back {
  cursor: pointer;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu
  li.dropdown-back
  a {
  font-size: 18px;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu
  li:hover
  a,
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown
  .dropdown-menu
  li:focus
  a {
  color: #ff9c00;
}
.mobile-nav
  .navigation
  ul:not(.dropdown-menu)
  > li.dropdown.is-open
  > .dropdown-menu {
  display: block;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mobile-nav .navigation ul:not(.dropdown-menu) > li:hover a,
.mobile-nav .navigation ul:not(.dropdown-menu) > li:focus a {
  color: #000;
}

.sidebar-nav__bottom-contact-infos ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sidebar-nav__bottom-contact-infos ul li i {
  margin-right: 5px;
}
.sidebar-nav__bottom-contact-infos ul li a .icon i {
  font-size: 40px;
  margin-right: 10px;
}
.sidebar-nav__bottom-contact-infos ul li a .text h5 {
  color: #000;
}
.sidebar-nav__bottom-contact-infos ul li:hover a,
.sidebar-nav__bottom-contact-infos ul li:focus a {
  color: #fff !important;
}
.sidebar-nav__bottom-contact-infos ul li:hover a:-moz-any-link,
.sidebar-nav__bottom-contact-infos ul li:focus a:-moz-any-link {
  color: #000 !important;
}
.sidebar-nav__bottom-contact-infos ul li:hover a:any-link,
.sidebar-nav__bottom-contact-infos ul li:focus a:any-link {
  color: #000 !important;
}
.sidebar-nav__bottom-social ul {
  gap: 20px;
  margin-top: 10px;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.sidebar-nav__bottom-social ul > li {
  padding: 0 !important;
  border: 0 !important;
}
.sidebar-nav__bottom-social ul > li a {
  font-weight: 400 !important;
}

#hamburger {
  display: none;
  line-height: 1;
  color: #4069ff;
  font-size: 36px;
  text-align: right;
}
#hamburger i {
  cursor: pointer;
}
@media (max-width: 1199px) {
  #hamburger {
    display: block;
  }
}

.banner {
  z-index: 1;
  position: relative;
  padding: 240px 0 196px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 1200px) {
  .banner {
    margin-top: 50px;
  }
}
@media (max-width: 1299px) {
  .banner {
    padding-top: 200px;
  }
}
@media (max-width: 1199px) {
  .banner {
    padding-top: 180px;
  }
}
@media (max-width: 991px) {
  .banner {
    padding: 150px 0;
  }
}
@media (max-width: 767px) {
  .banner {
    margin-top: 0;
    padding: 130px 0 140px;
  }
}
@media (max-width: 575px) {
  .banner {
    background-position: 52% 50%;
  }
}
@media (max-width: 480px) {
  .banner {
    padding: 120px 0 130px;
  }
}
.banner:after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 100%;
  z-index: -1;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0.85)),
    color-stop(61.17%, rgba(255, 255, 255, 0))
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.85) 0%,
    rgba(255, 255, 255, 0) 61.17%
  );
}
.banner.banner__2 {
  margin-top: 0;
  position: relative;
}
.banner.banner__2:after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 100%;
  z-index: -1;
  position: absolute;
  background: rgba(64, 105, 255, 0.6) !important;
}
.banner.banner__2 .banner__content span,
.banner.banner__2 .banner__content h1,
.banner.banner__2 .banner__content p {
  color: #fff;
}
.banner.banner__2 .banner__content a {
  background-color: #fff;
  color: #4069ff;
  font-family: "Poppins", sans-serif;
}
.banner__content {
  max-width: 563px;
}
@media (max-width: 991px) {
  .banner__content {
    max-width: 485px;
  }
}
@media (max-width: 767px) {
  .banner__content {
    max-width: 400px;
  }
}
.banner__content h1 {
  font-weight: 700;
}
.banner__content h1 span {
  font-weight: 300;
}
@media (max-width: 1199px) {
  .banner__content h1 {
    font-size: 54px;
    line-height: 68px;
  }
}
@media (max-width: 991px) {
  .banner__content h1 {
    font-size: 40px;
    line-height: 54px;
  }
}
@media (max-width: 380px) {
  .banner__content h1 {
    font-size: 35px;
    line-height: 49px;
  }
}
.banner__content .description p {
  font-size: 18px;
  color: #002e5b;
  line-height: 26px;
}
.banner__content .theme-btn:after {
  background: #ff9c00;
}
.banner__content .theme-btn:hover,
.banner__content .theme-btn:focus {
  background: #4069ff;
}
.banner-slider__wrapper {
  z-index: 1;
  margin-top: 0;
  position: relative;
}
.banner-slider__wrapper.banner-slider__wrapper_2 {
  z-index: 1;
}
.banner-slider__wrapper.banner-slider__wrapper_2:after {
  display: none !important;
}
.banner-slider__wrapper.banner-slider__wrapper_2 .slider-item {
  padding: 160px 0 250px;
  background-position: center;
  z-index: 40;
  overflow-x: hidden !important;
}
@media (max-width: 991px) {
  .banner-slider__wrapper.banner-slider__wrapper_2 .slider-item {
    padding: 140px 0 200px;
  }
}
@media (max-width: 767px) {
  .banner-slider__wrapper.banner-slider__wrapper_2 .slider-item {
    padding: 100px 0 100px;
  }
}
.banner-slider__wrapper.banner-slider__wrapper_2
  .slider-item
  .shape-element-left {
  bottom: 0;
  left: 0;
  z-index: -1;
  position: absolute;
}
.banner-slider__wrapper.banner-slider__wrapper_2
  .slider-item
  .shape-element-rignt {
  top: 0;
  right: 0;
  z-index: -1;
  position: absolute;
}
.banner-slider__wrapper.banner-slider__wrapper_2
  .slider-item
  .banner__content
  h1
  span {
  font-weight: 400 !important;
}
.banner-slider__wrapper.banner-slider__wrapper_2
  .slider-item
  .banner__content
  a {
  background-color: #fff;
  color: #ff9c00;
}
.banner-slider__wrapper:after {
  z-index: 0;
  width: 526px;
  content: "";
  bottom: -388px;
  height: 526px;
  position: absolute;
  border-radius: 500px;
  left: calc(50% - 263px);
  background: rgba(255, 156, 0, 0.4);
}
@media (max-width: 1199px) {
  .banner-slider__wrapper:after {
    width: 400px;
    height: 400px;
    bottom: -300px;
    left: calc(50% - 200px);
  }
}
.banner-slider__wrapper .slider-item {
  z-index: -1;
  position: relative;
  background-size: cover;
  padding: 355px 0 304px;
  background-position: center;
}
@media (max-width: 1399px) {
  .banner-slider__wrapper .slider-item {
    padding: 300px 0 260px;
  }
}
@media (max-width: 1199px) {
  .banner-slider__wrapper .slider-item {
    padding: 200px 180px;
  }
}
@media (max-width: 991px) {
  .banner-slider__wrapper .slider-item {
    padding: 170px 140px;
  }
}
@media (max-width: 767px) {
  .banner-slider__wrapper .slider-item {
    padding: 160px 130px;
  }
}
@media (max-width: 575px) {
  .banner-slider__wrapper .slider-item {
    padding: 140px 115px;
  }
}
.banner-slider__wrapper .slider-item:after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  content: "";
  height: 100%;
  position: absolute;
  /* background-image: url(assets/img/banner/banner-slider-overly.png); */
}
.banner-slider__wrapper .slider-item .number {
  right: 50px;
  font-size: 160px;
  position: absolute;
  line-height: 121px;
  font-family: "Pathway Gothic One";
  top: calc(50% - 60.5px);
  -webkit-text-stroke: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px !important;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 767px) {
  .banner-slider__wrapper .slider-item .number {
    right: 25px;
    font-size: 130px;
    line-height: 100px;
  }
}
.banner-slider__wrapper .banner__content {
  max-width: 893px;
  margin-left: auto;
  margin-right: auto;
}
.banner-slider__wrapper .banner__content .sub-title span {
  color: #ff9c00;
}
.banner-slider__wrapper .banner__content .theme-btn {
  font-weight: 600;
  padding: 15px 33.5px;
  background: #ff9c00;
}
.banner-slider__wrapper .banner__content .theme-btn::after {
  background: #fff;
}
.banner-slider__wrapper .banner__content .theme-btn:hover,
.banner-slider__wrapper .banner__content .theme-btn:focus {
  color: #ff9c00;
  background: #fff;
}
.banner-slider__wrapper .banner__content .theme-btn__wrapper {
  gap: 20px;
}
.banner-slider__wrapper .banner__content .theme-btn.btn-white {
  color: #ff9c00;
  background: #fff;
}
.banner-slider__wrapper .banner__content .theme-btn.btn-white::after {
  background: #ff9c00;
}
.banner-slider__wrapper .banner__content .theme-btn.btn-white:hover,
.banner-slider__wrapper .banner__content .theme-btn.btn-white:focus {
  color: #fff;
  background: #ff9c00;
}
.banner-slider__wrapper .slider-controls {
  z-index: 2;
  left: 100px;
  position: absolute;
  top: calc(50% - 58px);
}
.banner-slider__wrapper .slider-controls.slider-controls-2 {
  top: calc(50% - 58px);
  right: 0px !important;
}
.banner-slider__wrapper
  .slider-controls.slider-controls-2
  .banner-slider-arrows {
  position: absolute;
  right: 100px !important;
}
@media (max-width: 1399px) {
  .banner-slider__wrapper
    .slider-controls.slider-controls-2
    .banner-slider-arrows {
    right: 50px !important;
  }
}
@media (max-width: 1199px) {
  .banner-slider__wrapper
    .slider-controls.slider-controls-2
    .banner-slider-arrows {
    right: 30px !important;
  }
}
@media (max-width: 767px) {
  .banner-slider__wrapper
    .slider-controls.slider-controls-2
    .banner-slider-arrows {
    right: 15px !important;
  }
}
@media (max-width: 600px) {
  .banner-slider__wrapper
    .slider-controls.slider-controls-2
    .banner-slider-arrows {
    display: none !important;
  }
}
@media (max-width: 1399px) {
  .banner-slider__wrapper .slider-controls {
    left: 50px;
  }
}
@media (max-width: 1199px) {
  .banner-slider__wrapper .slider-controls {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .banner-slider__wrapper .slider-controls {
    left: 15px;
    top: calc(50% - 48px);
  }
}
@media (max-width: 650px) {
  .banner-slider__wrapper .slider-controls {
    top: calc(85% - 45px);
  }
}
.banner-slider__wrapper .slider-controls .banner-slider-arrows {
  gap: 16px;
}
@media (max-width: 650px) {
  .banner-slider__wrapper .slider-controls .banner-slider-arrows {
    gap: 10px;
  }
}
.banner-slider__wrapper .slider-controls .banner-slider-arrows .slick-arrow {
  width: 50px;
  height: 50px;
  color: #fff;
  line-height: 55px;
  text-align: center;
  border-radius: 500px;
  border: 1px solid #fff;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .banner-slider__wrapper .slider-controls .banner-slider-arrows .slick-arrow {
    width: 40px;
    height: 40px;
    line-height: 45px;
  }
}
.banner-slider__wrapper
  .slider-controls
  .banner-slider-arrows
  .slick-arrow.slick-prev {
  color: #ff9c00;
  border-color: #ff9c00;
}
.banner-slider__wrapper
  .slider-controls
  .banner-slider-arrows
  .slick-arrow.slick-prev:hover {
  color: #fff;
  background: #ff9c00;
}
.banner-slider__wrapper .slider-controls .banner-slider-arrows .slick-arrow i {
  opacity: 0.9;
  font-size: 20px;
}
@media (max-width: 767px) {
  .banner-slider__wrapper
    .slider-controls
    .banner-slider-arrows
    .slick-arrow
    i {
    font-size: 18px;
  }
}
.banner-slider__wrapper
  .slider-controls
  .banner-slider-arrows
  .slick-arrow:hover {
  color: #ff9c00;
  background: #fff;
}
.banner-slider__wrapper .slick-dots {
  gap: 22px;
  z-index: 1;
  bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: calc(50% - 40px);
}
@media (max-width: 1199px) {
  .banner-slider__wrapper .slick-dots {
    bottom: 30px;
  }
}
.banner-slider__wrapper .slick-dots li {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  border-radius: 500px;
  background: transparent;
  border: 2px solid #fff;
}
@media (max-width: 1199px) {
  .banner-slider__wrapper .slick-dots li {
    width: 16px;
    height: 16px;
  }
}
.banner-slider__wrapper .slick-dots li.slick-active:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.banner-slider__wrapper .slick-dots li:after {
  content: "";
  width: 8px;
  height: 8px;
  line-height: 20px;
  position: absolute;
  background: #fff;
  left: calc(50% - 4px);
  border-radius: 500px;
  top: calc(50% - 4px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}
@media (max-width: 1199px) {
  .banner-slider__wrapper .slick-dots li:after {
    width: 6px;
    height: 6px;
    top: calc(50% - 3px);
    left: calc(50% - 3px);
  }
}
.banner-slider__wrapper .slick-dots li button {
  display: none;
}

.banner-home {
  z-index: 1;
  background-color: #04213f;
}
.banner-home:before {
  left: -28px;
  content: "";
  z-index: -1;
  bottom: -280px;
  width: 545.24px;
  height: 545.85px;
  position: absolute;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/banner/pattern.svg); */
}
@media (max-width: 1199px) {
  .banner-home:before {
    bottom: 340px;
  }
}
@media (max-width: 767px) {
  .banner-home:before {
    bottom: 150px;
  }
}
.banner-home .container {
  z-index: 1;
  position: relative;
}
.banner-home .container:after {
  top: 42px;
  content: "";
  z-index: -1;
  width: 841px;
  right: 747px;
  height: 208px;
  position: absolute;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/banner/banner-overly.svg); */
}
.banner-home__content .sub-title {
  border-radius: 2px;
  padding: 10.5px 19px;
  background-color: #052648;
}
.banner-home__content .sub-title span {
  color: #ef0548;
}
.banner-home__content .title span {
  font-weight: 300;
}
@media (max-width: 1199px) {
  .banner-home__content .title {
    font-size: 56px;
    line-height: 70px;
  }
}
@media (max-width: 1199px) {
  .banner-home__content .title {
    font-size: 54px;
    line-height: 68px;
  }
}
@media (max-width: 991px) {
  .banner-home__content .title {
    font-size: 40px;
    line-height: 54px;
  }
}
@media (max-width: 380px) {
  .banner-home__content .title {
    font-size: 35px;
    line-height: 49px;
  }
}
.banner-home__content .description p {
  font-size: 18px;
  line-height: 28px;
}
.banner-home__content .theme-btn {
  padding: 20px 37.8px;
}
@media (max-width: 767px) {
  .banner-home__content .theme-btn {
    padding: 18px 35px;
  }
}
@media (max-width: 575px) {
  .banner-home__content .theme-btn {
    padding: 15px 30px;
  }
}
.banner-home__content .theme-btn__wrapper {
  gap: 30px;
}
.banner-home__content .theme-btn.btn-white-border {
  color: #fff;
  padding: 18.5px 37.8px;
  background: transparent;
  border: 1.5px solid #fff;
}
@media (max-width: 767px) {
  .banner-home__content .theme-btn.btn-white-border {
    padding: 16.5px 35px;
  }
}
@media (max-width: 575px) {
  .banner-home__content .theme-btn.btn-white-border {
    padding: 13.5px 30px;
  }
}
.banner-home__content .theme-btn.btn-white-border:after {
  background: #ff9c00;
}
.banner-home__content .theme-btn.btn-white-border:hover,
.banner-home__content .theme-btn.btn-white-border:focus {
  background: #ff9c00;
  border-color: #ff9c00;
}
.banner-home__content .theme-btn:hover,
.banner-home__content .theme-btn:focus {
  background: #ef0548;
}
.banner-home__media {
  z-index: -1;
  max-height: 815px;
  position: relative;
  margin-right: calc(-50vw + 50%) !important;
}
@media (min-width: 1200px) {
  .banner-home__media {
    margin-left: -239px;
  }
}
.banner-home__media:after {
  top: 0;
  width: 100%;
  left: -1px;
  content: "";
  z-index: 2;
  height: 100%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/banner/banner-home-overly.png); */
}
.banner-home__media:before {
  left: 1074px;
  content: "";
  top: -340px;
  z-index: 2;
  width: 144.86px;
  height: 1194.97px;
  position: absolute;
  background: rgba(239, 5, 72, 0.2);
  -webkit-transform: rotate(-30.99deg);
  transform: rotate(-30.99deg);
}
@media (max-width: 1199px) {
  .banner-home__media:before {
    top: 0;
    height: 100%;
    left: -9999px;
    width: 9999px;
    -webkit-transform: inherit;
    transform: inherit;
    background-color: #04213f;
  }
}
.banner-home__media .start {
  z-index: 3;
  left: 301px;
  bottom: 110px;
  position: absolute;
}
@media (max-width: 1199px) {
  .banner-home__media .start {
    left: 247px;
    bottom: 96px;
  }
}
@media (max-width: 991px) {
  .banner-home__media .start {
    left: 200px;
    bottom: 70px;
  }
}
@media (max-width: 767px) {
  .banner-home__media .start {
    left: 100px;
    bottom: 40px;
  }
}
@media (max-width: 480px) {
  .banner-home__media .start {
    left: 50px;
    bottom: 30px;
  }
}

.contact-us__content {
  max-width: 495px;
}
@media (max-width: 991px) {
  .contact-us__content .sub-title {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 575px) {
  .contact-us__content .sub-title {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 991px) {
  .contact-us__content .title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 575px) {
  .contact-us__content .title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 480px) {
  .contact-us__content .title {
    font-size: 26px;
    line-height: 34px;
  }
}
.contact-us__item {
  max-width: 254px;
}
.contact-us__item-wrapper {
  margin-bottom: -40px;
}
@media (min-width: 992px) {
  .contact-us__item-wrapper {
    margin-left: -11px;
  }
}
.contact-us__item-wrapper .col-6:nth-child(even) .contact-us__item {
  margin-left: auto;
}
.contact-us__item .icon {
  font-size: 28px;
}
.contact-us__item .icon .icon-email {
  font-size: 20px;
}
@media (max-width: 575px) {
  .contact-us__item .title {
    font-size: 16px;
    line-height: 26px;
  }
}
.contact-us__item ul li a {
  font-size: 16px;
  line-height: 28px;
  color: #243342;
  font-family: "Lato", sans-serif;
}
.contact-us__item ul li a:hover,
.contact-us__item ul li a:focus {
  color: #4069ff;
}
.contact-us hr {
  opacity: 1;
  border-radius: 5px;
  border: 1px solid #e2e9ff !important;
}

.contact-form #contact-map {
  width: 100%;
  height: 300px;
}
@media (min-width: 768px) {
  .contact-form #contact-map {
    height: 100%;
    position: absolute;
  }
}
.contact-form .contact-form {
  max-width: 550px;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
  box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
}
.contact-form .contact-form__header .sub-title {
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 991px) {
  .contact-form .contact-form__header .title {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .contact-form .contact-form__header .title {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .contact-form .contact-form__header .title {
    font-size: 26px;
    line-height: 38px;
  }
}
.contact-form .contact-form .single-personal-info {
  margin-bottom: 20px;
}
.contact-form .contact-form input,
.contact-form .contact-form textarea {
  width: 100%;
  outline: none;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  max-width: 100%;
  font-weight: 400;
  line-height: 26px;
  border-radius: 4px;
  padding: 10.5px 20px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1.5px solid rgba(14, 30, 54, 0.2);
}
.contact-form .contact-form input::-webkit-input-placeholder,
.contact-form .contact-form textarea::-webkit-input-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.contact-form .contact-form input::-moz-placeholder,
.contact-form .contact-form textarea::-moz-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.contact-form .contact-form input:-ms-input-placeholder,
.contact-form .contact-form textarea:-ms-input-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.contact-form .contact-form input::-ms-input-placeholder,
.contact-form .contact-form textarea::-ms-input-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.contact-form .contact-form input::placeholder,
.contact-form .contact-form textarea::placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.contact-form .contact-form textarea {
  resize: none;
  height: 120px;
}
.contact-form .contact-form .theme-btn {
  margin-top: -8px;
}

.testimonial-bg {
  background-position: 41% 75%;
}

.our-team.our-team-home-3 .container:after {
  top: -460px;
  content: "";
  z-index: -1;
  left: inherit;
  right: -917px;
  width: 4030.94px;
  height: 2170.42px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  background-image: url("../images/our-team-home-3.png");
}
.our-team .mb-minus-30 {
  margin-bottom: -30px;
}
.our-team .container {
  position: relative;
}
.our-team .container:after {
  top: -345px;
  content: "";
  left: -592px;
  z-index: -1;
  width: 2523.97px;
  height: 1404.66px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/about/bg-team.png); */
}
@media (max-width: 1199px) {
  .our-team__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .our-team__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .our-team__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .our-team__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.our-team__content .title span {
  font-weight: 300;
}

@media (max-width: 1199px) {
  .employee-friendly__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .employee-friendly__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .employee-friendly__content .title {
    font-size: 32px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  .employee-friendly__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.employee-friendly__content .title span {
  font-weight: 300;
}
.employee-friendly .slider-controls .slider-arrows {
  gap: 10px;
}
.employee-friendly .slider-controls .slider-arrows .slick-arrow {
  font-size: 46px;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.employee-friendly .slider-controls .slider-arrows .slick-arrow.slick-prev {
  color: #e2e9ff;
}
.employee-friendly .slider-controls .slider-arrows .slick-arrow.slick-next {
  color: #4069ff;
}
.employee-friendly .slider-controls .slider-arrows .slick-arrow:hover {
  color: #000;
}

.our-company .container {
  position: relative;
}
.our-company .container:after {
  top: -587px;
  content: "";
  left: -564px;
  z-index: -1;
  width: 2048.04px;
  height: 1285.06px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  background-image: url("../images/why-counter-overly-3.png");
}
.our-company__meida {
  overflow: hidden;
  position: relative;
}
.our-company__meida.border-radius {
  margin-top: 60px;
  padding-bottom: 6px;
  border-radius: 20px 20px 0px 0px;
}
@media (max-width: 575px) {
  .our-company__meida.border-radius {
    margin-top: 25px;
  }
}
@media (max-width: 575px) {
  .our-company__meida img {
    width: 100%;
  }
}
.our-company__meida .horizental-bar {
  left: 10px;
  width: 232px;
  height: 15px;
  bottom: 0px;
  max-width: 100%;
  position: absolute;
  background: #4069ff;
  -webkit-animation: move-right 3s linear infinite;
  animation: move-right 3s linear infinite;
}
@media (min-width: 1200px) {
  .our-company__content {
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .our-company__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .our-company__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .our-company__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .our-company__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.our-company__content .title span {
  font-weight: 300;
}

.years-experience {
  background: #4069ff;
  padding: 20px 15px 31px;
  border-radius: 0px 0px 20px 20px;
}
.years-experience.years-experience_tow {
  border-radius: 0px 0px 0px 0px;
  background: #fff5e5;
}
.years-experience.years-experience_tow .icons i {
  font-size: 50px;
  margin-right: 30px;
}
.years-experience.years-experience_tow .number {
  color: #ff9c00;
}
.years-experience.years-experience_tow .number sup {
  color: #ff9c00;
}
.years-experience.years-experience_tow h5 {
  color: #243342;
}
.years-experience .number {
  font-size: 64px;
  font-weight: 700;
  line-height: 60px;
}
@media (max-width: 767px) {
  .years-experience .number {
    font-size: 45px;
  }
}
.years-experience .number sup {
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
}
@media (max-width: 767px) {
  .years-experience .number sup {
    font-size: 35px;
  }
}

.client-feedback__item {
  width: 100%;
  border: 1px solid #eff0f6;
}
@media (min-width: 576px) {
  .client-feedback__item:nth-child(odd) {
    border-right: 0;
  }
}
@media (min-width: 576px) {
  .client-feedback__item:nth-child(odd) .client-feedback__item-header {
    border-right: 1px solid #cad6ff;
  }
}
.client-feedback__item.client-feedback__item-two {
  border-color: #fdf6f8;
}
@media (min-width: 576px) {
  .client-feedback__item.client-feedback__item-two:nth-child(odd)
    .client-feedback__item-header {
    border-right: 1px solid #fbeef2;
  }
}
.client-feedback__item.client-feedback__item-two
  .client-feedback__item-header:after {
  background: #fdf6f8;
}
.client-feedback__item.client-feedback__item-two
  .client-feedback__item-body
  .starts
  ul
  li
  span {
  /* background-image: url(assets/img/testimonial/star-red.png); */
}
.client-feedback__item-header {
  position: relative;
  padding: 17px 15px;
}
.client-feedback__item-header:after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  content: "";
  height: 100%;
  position: absolute;
  background: #eff0f6;
}
.client-feedback__item-body {
  max-width: 275px;
  margin-left: auto;
  margin-right: auto;
  padding: 26.1px 15px;
}
.client-feedback__item-body .number {
  font-size: 30px;
  line-height: 36px;
}
.client-feedback__item-body .starts ul {
  gap: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.client-feedback__item-body .starts ul li span {
  display: block;
  width: 19.96px;
  height: 19.91px;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/about/star.png); */
}

.company-skill .container {
  position: relative;
}
.company-skill .container:after {
  top: -305px;
  content: "";
  z-index: -1;
  left: -414px;
  height: 1117px;
  width: 2552.29px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  /* background-image: url(assets/img/about/company-skill-bg.png); */
}
@media (min-width: 1200px) {
  .company-skill__content {
    max-width: 635px;
  }
}
@media (max-width: 1199px) {
  .company-skill__content .title:not(h6) {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .company-skill__content .title:not(h6) {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .company-skill__content .title:not(h6) {
    font-size: 32px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  .company-skill__content .title:not(h6) {
    font-size: 28px;
    line-height: 42px;
  }
}
.company-skill__content .title:not(h6) span {
  font-weight: 300;
}
.company-skill__media-wrapper {
  position: relative;
}
.company-skill__media-wrapper .popup-video {
  left: 27%;
  z-index: 1;
  width: 60px;
  bottom: 20%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4069ff;
  font-size: 22px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  background: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
  box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.5);
  -webkit-animation: playVideo2 3s linear infinite;
  animation: playVideo2 3s linear infinite;
}
@media (max-width: 575px) {
  .company-skill__media-wrapper .popup-video {
    left: 22%;
    width: 40px;
    bottom: 15%;
    height: 40px;
    font-size: 18px;
  }
}

.service_element {
  border-bottom: 1px dashed #e2e9ff;
  padding: 30px 0px;
}
.service_element .service_info > li {
  font-family: "Lato", sans-serif;
  margin: 5px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding-left: 25px;
  position: relative;
}
.service_element .service_info > li::after {
  content: "\f14a";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  clear: both;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  color: #4069ff;
}
.service_element .area__item {
  padding: 20px;
  background-color: #e2e9ff;
}
.service_element .area__item .icon {
  font-size: 50px;
  min-height: 100%;
  line-height: 60px;
  padding-right: 20px;
  border-right: 1px solid #ffffff;
  color: #4069ff;
}
.service_element .area__item .text_2 {
  padding-left: 20px;
}
.service_element .area__item .text_2 .number {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  color: #4069ff;
}
.service_element .area__item .text_2 .description {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.author_element .author .author_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}
.author_element .author .author_name {
  margin: 30px 0px;
}
.author_element .author .author_name h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000;
}
.author_element .author .author_name p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  color: #000;
}

.about__wrapper .about_content .icons {
  margin: 30px 0px;
}
.about__wrapper .about_content .icons i {
  font-size: 40px;
  padding: 10px 20px;
  margin-right: 20px;
  background: #fff5e5;
  color: #ff9c00;
}
.about__wrapper p {
  margin: 10px 0px;
}
.about__wrapper a {
  margin-top: 30px;
  background: transparent;
  border: 1.5px solid rgba(255, 156, 0, 0.3);
  border-radius: 4px;
  color: #ff9c00;
}

.our-project .mb-minus-30 {
  margin-bottom: -30px;
}
.our-project__item {
  height: 400px;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 3px solid #3e66f82e;
}
@media (max-width: 1199px) {
  .our-project__item {
    height: 350px;
  }
}
@media (max-width: 767px) {
  .our-project__item {
    height: 300px;
  }
}
.our-project__item:hover img:not(.theme-btn img),
.our-project__item:focus img:not(.theme-btn img) {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.our-project__item:hover .content,
.our-project__item:focus .content {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.our-project__item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.our-project__item .content {
  left: 0;
  z-index: 1;
  width: 100%;
  bottom: 20px;
  max-width: 434px;
  position: absolute;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 0px 10px 0px 0px;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}
.our-project__item .content .text {
  padding: 24.6px 90px 24.6px 30px;
}
@media (max-width: 1199px) {
  .our-project__item .content .text {
    padding: 13px 70px 13px 15px;
  }
}
.our-project__item .content .text span {
  font-size: 12px;
  line-height: 15px;
}
@media (max-width: 1199px) {
  .our-project__item .content .text span {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 5px;
  }
}
@media (max-width: 1199px) {
  .our-project__item .content .text h5 {
    font-size: 18px;
    line-height: 24px;
  }
}
.our-project__item .content .theme-btn {
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 24px 10px 18px;
}
@media (max-width: 1199px) {
  .our-project__item .content .theme-btn {
    padding: 5px 15px;
  }
}
.our-project__item .content .theme-btn img {
  height: auto;
  max-width: 44px;
}
@media (max-width: 1199px) {
  .our-project__item .content .theme-btn img {
    max-width: 35px;
  }
}

.process__widget {
  padding: 0px 15px;
}
.process__widget.process__widget_2 .icon__widget {
  border: 1px dashed #ff9c00;
}
.process__widget.process__widget_2 .icons {
  -webkit-box-shadow: 0px 0px 10px rgba(255, 153, 0, 0.15);
  box-shadow: 0px 0px 10px rgba(255, 153, 0, 0.15);
  font-size: 40px;
}
.process__widget.process__widget_2 .icons i {
  color: #ff9c00;
}
.process__widget .icon__widget {
  margin: 0 auto;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px dashed #4069ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}
.process__widget .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  height: 100px;
  width: 100px;
  font-size: 50px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 10px rgba(64, 105, 255, 0.15);
  box-shadow: 0px 0px 10px rgba(64, 105, 255, 0.15);
}
.process__widget .icons i {
  color: #4069ff;
}
.process__widget h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
}
.process__widget p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.arrow__element {
  position: absolute;
  top: 15%;
  right: -15%;
}

.similar-work .mb-minus-30 {
  margin-bottom: -30px;
}
.similar-work.similar-work-services {
  padding-top: 226px;
}
@media (max-width: 1199px) {
  .similar-work.similar-work-services {
    padding-top: 210px;
  }
}
@media (max-width: 991px) {
  .similar-work.similar-work-services {
    padding-top: 290px;
  }
}
@media (max-width: 575px) {
  .similar-work.similar-work-services {
    padding-top: 260px;
  }
}
.similar-work__content {
  gap: 15px 30px;
}
@media (max-width: 767px) {
  .similar-work__content {
    gap: 10px 30px;
  }
}
.similar-work__content-text .sub-title {
  font-size: 16px;
  line-height: 26px;
}
@media (max-width: 767px) {
  .similar-work__content-text .sub-title {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 24px;
  }
}
.similar-work__content-text .title span {
  font-weight: 300;
}
@media (max-width: 1199px) {
  .similar-work__content-text .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .similar-work__content-text .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .similar-work__content-text .title {
    font-size: 32px;
    line-height: 46px;
  }
}
.similar-work__item {
  overflow: hidden;
  background: #fff;
  height: calc(100% - 30px);
}
.similar-work__item:hover .media img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.similar-work__item.reade-more-hidden {
  position: relative;
  background: transparent;
}
.similar-work__item.reade-more-hidden .top {
  border: 1px solid rgba(64, 105, 255, 0.2);
  border-bottom: none;
}
.similar-work__item.reade-more-hidden .media {
  height: 300px;
}
.similar-work__item.reade-more-hidden .theme-btn {
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
}
.similar-work__item.reade-more-hidden:hover .theme-btn {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.similar-work__item .top {
  gap: 15px 30px;
  padding: 40px 20px 40px 30px;
}
@media (max-width: 1300px) {
  .similar-work__item .top {
    gap: 10px 20px;
    padding: 25px 15px;
  }
}
.similar-work__item .top .icon {
  width: 80px;
  height: 80px;
  font-size: 45px;
  min-width: 80px;
  line-height: 80px;
  background: #4069ff;
}
@media (max-width: 1199px) {
  .similar-work__item .top .icon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    font-size: 35px;
    line-height: 60px;
  }
}
@media (max-width: 1300px) {
  .similar-work__item .top .title {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 1199px) {
  .similar-work__item .top .title {
    font-size: 18px;
    line-height: 24px;
  }
}
.similar-work__item .top .title a:hover,
.similar-work__item .top .title a:focus {
  color: #4069ff;
}
.similar-work__item .media {
  width: 100%;
  height: 250px;
}
.similar-work__item .media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.similar-work__item .theme-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 13px 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.similar-work__item .theme-btn i {
  opacity: 0.9;
  font-size: 20px;
  margin-left: 10px;
}
.similar-work__item .theme-btn.btn-yellow:hover,
.similar-work__item .theme-btn.btn-yellow:focus {
  color: #0e0a01;
  background: #ff9c00;
}
.similar-work__item .theme-btn:hover,
.similar-work__item .theme-btn:focus {
  color: #0e1e36;
  background: #4069ff;
}

.pagination-item {
  width: 100%;
  z-index: 1;
  height: 100%;
  gap: 30px;
  position: relative;
  background-size: cover;
  padding: 41px 60px 41px 30px;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .pagination-item {
    padding: 30px 25px !important;
  }
}
@media (max-width: 380px) {
  .pagination-item {
    gap: 12px;
    padding: 20px 20px !important;
  }
}
.pagination-item:after {
  left: 0;
  width: 100%;
  z-index: -1;
  content: "";
  height: 100%;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4069ff),
    to(rgba(64, 105, 255, 0.2))
  );
  background: linear-gradient(90deg, #4069ff 0%, rgba(64, 105, 255, 0.2) 100%);
}
.pagination-item:hover .left,
.pagination-item:focus .left {
  color: #ff9c00 !important;
}
.pagination-item:hover .right span,
.pagination-item:hover .right .title,
.pagination-item:focus .right span,
.pagination-item:focus .right .title {
  color: #ff9c00 !important;
}
.pagination-item.flex-row-reverse {
  padding-left: 60px;
  padding-right: 30px;
}
.pagination-item.flex-row-reverse:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(64, 105, 255, 0.2)),
    to(#4069ff)
  );
  background: linear-gradient(90deg, rgba(64, 105, 255, 0.2) 0%, #4069ff 100%);
}
.pagination-item.flex-row-reverse .left i {
  margin-right: 0;
  margin-left: 10px;
}
@media (max-width: 380px) {
  .pagination-item.flex-row-reverse .left i {
    margin-left: 5px;
  }
}
.pagination-item.flex-row-reverse .right {
  text-align: left !important;
}
.pagination-item__wrapper {
  gap: 30px;
}
@media (max-width: 767px) {
  .pagination-item__wrapper {
    gap: 20px 30px;
  }
}
.pagination-item .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  line-height: 26px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .pagination-item .left {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 380px) {
  .pagination-item .left {
    font-size: 13px;
    line-height: 17px;
  }
}
.pagination-item .left i {
  font-size: 20px;
  font-weight: 400;
  margin-right: 10px;
}
@media (max-width: 575px) {
  .pagination-item .left i {
    font-size: 18px;
  }
}
@media (max-width: 380px) {
  .pagination-item .left i {
    font-size: 16px;
    margin-right: 5px;
  }
}
.pagination-item .right span {
  font-size: 16px;
  line-height: 26px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .pagination-item .right span {
    font-size: 14px;
    line-height: 18px;
  }
}
.pagination-item .right .title {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .pagination-item .right .title {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 380px) {
  .pagination-item .right .title {
    font-size: 18px;
    line-height: 22px;
  }
}

.our-project-details .mb-minus-30 {
  margin-bottom: -30px;
}
.our-project-details .media {
  width: 100%;
  height: 730px;
}
@media (max-width: 991px) {
  .our-project-details .media {
    height: 650px;
  }
}
@media (max-width: 767px) {
  .our-project-details .media {
    height: 500px;
  }
}
@media (max-width: 575px) {
  .our-project-details .media {
    height: 480px;
  }
}
@media (max-width: 480px) {
  .our-project-details .media {
    height: 400px;
  }
}
@media (max-width: 380px) {
  .our-project-details .media {
    height: 380px;
  }
}
.our-project-details .media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.our-project-details-meta {
  margin-top: -80px;
  position: relative;
  background: #eff0f6;
}
@media (max-width: 1300px) {
  .our-project-details-meta {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.our-project-details-meta .meta-item {
  padding: 10px;
  gap: 10px 20px;
}
@media (max-width: 1300px) {
  .our-project-details-meta .meta-item {
    gap: 10px 15px;
  }
}
.our-project-details-meta .meta-item .icon {
  width: 60px;
  height: 60px;
  font-size: 20px;
  min-width: 60px;
  color: #4069ff;
  line-height: 60px;
  border-radius: 500px;
  background: #e2e9ff;
}
@media (max-width: 1300px) {
  .our-project-details-meta .meta-item .icon {
    width: 50px;
    height: 50px;
    font-size: 18px;
    min-width: 50px;
    line-height: 50px;
  }
}
.our-project-details-meta .meta-item .text h6 {
  font-size: 16px;
  line-height: 26px;
}
.our-project-details__content h6 {
  color: #4069ff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .our-project-details__content h6 {
    margin-bottom: 10px;
  }
}
.our-project-details__content h2 {
  color: #0e1e36;
  margin-bottom: 20px;
}
.our-project-details__content h2 span {
  font-family: "Lato", sans-serif;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #243342;
  white-space: nowrap;
}
@media (max-width: 1199px) {
  .our-project-details__content h2 {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .our-project-details__content h2 {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .our-project-details__content h2 {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .our-project-details__content h2 {
    font-size: 28px;
    line-height: 42px;
  }
}
.our-project-details__content p {
  font-family: "Lato", sans-serif;
  max-width: 1330px;
  margin-bottom: 20px;
}
.our-project-details__content blockquote {
  margin-top: 30px;
  margin-bottom: 50px;
}
.our-project-details__content blockquote p {
  font-size: 20px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
  font-style: italic;
}
.our-project-details__content figure {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.our-project-details__content ul {
  gap: 30px 94px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  margin: 30px 0 30px 0;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}
@media (max-width: 991px) {
  .our-project-details__content ul {
    gap: 30px 40px;
  }
}
@media (max-width: 575px) {
  .our-project-details__content ul {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    margin-bottom: 20px;
  }
}
.our-project-details__content ul li {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #0e1e36;
  padding-left: 50px;
  position: relative;
  margin-bottom: 20px;
}
.our-project-details__content ul li:after {
  left: 0;
  width: 30px;
  content: "\f00c";
  height: 30px;
  color: #4069ff;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  border-radius: 500px;
  top: calc(50% - 13px);
  background: #eff0f6;
  font-family: "Font Awesome 5 Pro";
}
.our-project-details__content hr {
  margin-top: 65px;
}
@media (max-width: 1199px) {
  .our-project-details__content hr {
    margin-top: 40px;
  }
}
@media (max-width: 991px) {
  .our-project-details__content hr {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .our-project-details__content hr {
    margin-top: 20px;
  }
}
@media (max-width: 575px) {
  .our-project-details__content hr {
    margin-top: 15px;
  }
}

.client-brand__item {
  padding: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.client-brand__item-media img {
  width: 100%;
  height: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.client-brand__item:-moz-any-link:hover .client-brand__item-media img,
.client-brand__item:-moz-any-link:focus .client-brand__item-media img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.client-brand__item:any-link:hover .client-brand__item-media img,
.client-brand__item:any-link:focus .client-brand__item-media img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.slick-list {
  margin-left: -15px;
  margin-right: -15px;
}
.slick-list .slick-track {
  height: 100%;
  display: table !important;
}
.slick-list .slick-track .slick-slide {
  height: initial;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  float: none !important;
  display: table-cell !important;
}

.testimonial .mb-minus-30 {
  margin-bottom: -30px;
}
@media (max-width: 1199px) {
  .testimonial__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .testimonial__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .testimonial__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .testimonial__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.testimonial__content .title span {
  font-weight: 300;
}
.testimonial__item {
  background: #f5f8ff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 40px 36px 35px 40px;
  border-top: 4px solid #ff9c00;
}
.testimonial__item.testimonial-item-two {
  background: transparent;
  padding: 40px 33px 20px 40px;
  border: 1px solid rgba(255, 156, 0, 0.2);
}
.testimonial__item.testimonial-item-two .testimonial__item-header .right i {
  font-size: 60px;
  color: rgba(255, 156, 0, 0.1);
}
.testimonial__item.testimonial-item-two .description {
  max-width: 308px;
}
.testimonial__item.testimonial-item-two:hover {
  background: #ff9c00;
}
.testimonial__item.testimonial-item-two:hover
  .testimonial__item-header
  .right
  i {
  color: rgba(255, 255, 255, 0.2);
}
.testimonial__item.testimonial-item-two:hover
  .testimonial__item-header
  .left
  .meta
  .name,
.testimonial__item.testimonial-item-two:hover
  .testimonial__item-header
  .left
  .meta
  .position {
  color: #fff !important;
}
.testimonial__item.testimonial-item-two:hover .description {
  color: #fff !important;
}
.testimonial__item.testimonial-item-three {
  background: transparent;
  padding: 40px 33px 30px 40px;
  border: 1px solid rgba(4, 33, 63, 0.1);
}
.testimonial__item.testimonial-item-three .testimonial__item-header .right i {
  font-size: 60px;
  color: rgba(239, 5, 72, 0.1);
}
.testimonial__item.testimonial-item-three .testimonial__item-footer .name {
  font-size: 16px;
  font-weight: 500;
  color: #04213f;
  line-height: 28px;
  text-transform: uppercase;
}
.testimonial__item.testimonial-item-three .testimonial__item-footer .name span {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #1b3c54;
  line-height: 28px;
  text-transform: capitalize;
}
.testimonial__item.testimonial-item-three .starts ul {
  gap: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonial__item.testimonial-item-three .starts ul li span {
  display: block;
  width: 19.96px;
  height: 19.91px;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/testimonial/star-red.png); */
}
.testimonial__item.testimonial-item-three .description {
  max-width: 308px;
}
.testimonial__item.testimonial-item-three:hover {
  background: #ef0548;
}
.testimonial__item.testimonial-item-three:hover
  .testimonial__item-header
  .right
  i {
  color: rgba(255, 255, 255, 0.2);
}
.testimonial__item.testimonial-item-three:hover
  .testimonial__item-header
  .left
  .meta
  .name,
.testimonial__item.testimonial-item-three:hover
  .testimonial__item-header
  .left
  .meta
  .position {
  color: #fff;
}
.testimonial__item.testimonial-item-three:hover
  .testimonial__item-footer
  .socail-link
  span {
  color: #fff;
}
.testimonial__item.testimonial-item-three:hover .starts ul li span {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.testimonial__item.testimonial-item-three:hover .description {
  color: #fff !important;
}
@media (max-width: 1420px) {
  .testimonial__item {
    padding: 30px 25px;
  }
}
.testimonial__item.border-primary {
  border-color: #4069ff;
}
.testimonial__item.border-red {
  border-color: #ef0548;
}
.testimonial__item.testimonial {
  border-top: none;
  border-bottom: 4px solid #ff9c00;
}
.testimonial__item.testimonial .starts ul {
  gap: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonial__item.testimonial .starts ul li span {
  display: block;
  width: 19.96px;
  height: 19.91px;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(../assets/img/testimonial/star.png); */
}
.testimonial__item.testimonial .quote::after {
  display: none;
}
.testimonial__item-header {
  gap: 10px 20px;
}
@media (max-width: 1420px) {
  .testimonial__item-header {
    gap: 10px 15px;
  }
}
@media (max-width: 450px) {
  .testimonial__item-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
}
.testimonial__item-header .left {
  gap: 10px 15px;
}
@media (max-width: 1420px) {
  .testimonial__item-header .left {
    gap: 8px 12px;
  }
}
.testimonial__item-header .left .media {
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 500px;
}
.testimonial__item-header .left .media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.testimonial__item-header .left .meta .name {
  font-size: 16px;
  line-height: 24px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial__item-header .left .meta .position {
  font-size: 14px;
  line-height: 28px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial__item-header.item-header-2 .name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.testimonial__item-header.item-header-2 .name span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}
.testimonial__item-header.item-header-2 .position {
  margin-top: 15px;
}
.testimonial__item-header .right .starts ul {
  gap: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonial__item-header .right .starts ul li span {
  display: block;
  width: 19.96px;
  height: 19.91px;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/testimonial/star.png); */
}
.testimonial__item .description {
  max-width: 432px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial__item-footer .socail-link span {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.testimonial__item-footer .socail-link ul {
  gap: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.testimonial__item-footer .socail-link ul li a {
  display: block;
}
.testimonial__item-footer .quote {
  z-index: 1;
  font-size: 30px;
  position: relative;
}
.testimonial__item-footer .quote:after {
  top: 7px;
  left: 12px;
  z-index: -1;
  width: 28px;
  height: 28px;
  content: "";
  position: absolute;
  position: absolute;
  border-radius: 500px;
  background: #e2e9ff;
}
.testimonial .slider-controls .testimonial-slider-arrows {
  gap: 20px;
}
.testimonial .slider-controls .testimonial-slider-arrows .slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 20px;
  min-width: 50px;
  line-height: 50px;
  border-radius: 500px;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid #0e0a01;
}
.testimonial .slider-controls .testimonial-slider-arrows .slick-arrow i {
  opacity: 0.9;
  color: #0e0a01;
}
.testimonial .slider-controls .testimonial-slider-arrows .slick-arrow:hover,
.testimonial .slider-controls .testimonial-slider-arrows .slick-arrow:focus {
  background: #0e0a01;
}
.testimonial .slider-controls .testimonial-slider-arrows .slick-arrow:hover i,
.testimonial .slider-controls .testimonial-slider-arrows .slick-arrow:focus i {
  color: #fff;
}
.testimonial
  .slider-controls
  .testimonial-slider-arrows
  .slick-arrow.slick-next {
  border-color: #ff9c00;
}
.testimonial
  .slider-controls
  .testimonial-slider-arrows
  .slick-arrow.slick-next
  i {
  color: #ff9c00;
}
.testimonial
  .slider-controls
  .testimonial-slider-arrows
  .slick-arrow.slick-next:hover,
.testimonial
  .slider-controls
  .testimonial-slider-arrows
  .slick-arrow.slick-next:focus {
  background: #ff9c00;
}
.testimonial
  .slider-controls
  .testimonial-slider-arrows
  .slick-arrow.slick-next:hover
  i,
.testimonial
  .slider-controls
  .testimonial-slider-arrows
  .slick-arrow.slick-next:focus
  i {
  color: #fff;
}
.testimonial .slider-controls.slider-controls-two .testimonial-slider-arrows {
  gap: 10px;
}
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow {
  border: none;
  width: inherit;
  line-height: 0;
  height: inherit;
  font-size: 46px;
  min-width: inherit;
  border-radius: inherit;
  background: inherit !important;
}
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow
  i {
  opacity: 1;
  color: #f8dde4;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow:hover
  i,
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow:focus
  i {
  color: #ef0548;
}
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow.slick-next
  i {
  color: #ef0548;
}
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow.slick-next:hover
  i,
.testimonial
  .slider-controls.slider-controls-two
  .testimonial-slider-arrows
  .slick-arrow.slick-next:focus
  i {
  color: #f8dde4;
}

.pricing {
  background-repeat: repeat-x;
  background-position: -189% 0;
}
.pricing .mb-minus-30 {
  margin-bottom: -30px;
}
@media (max-width: 1199px) {
  .pricing__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .pricing__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .pricing__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .pricing__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.pricing__content .description {
  font-size: 18px;
  line-height: 26px;
}
.pricing__content .description span {
  color: #4069ff;
}
.pricing__controller {
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pricing__controller .theme-btn {
  color: #4069ff;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  border-radius: 500px;
  padding: 5.7px 23.7px;
  background: transparent;
  border: 1.5px solid #e2e9ff;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(14, 10, 1, 0.1));
  filter: drop-shadow(0px 0px 10px rgba(14, 10, 1, 0.1));
}
.pricing__controller .theme-btn:after {
  background: #4069ff;
}
.pricing__controller .theme-btn.border-yellow {
  color: #ff9c00;
  border-color: #ff9c00;
}
.pricing__controller .theme-btn.border-yellow:after {
  background: #ff9c00;
}
.pricing__controller .theme-btn.border-yellow.active,
.pricing__controller .theme-btn.border-yellow:hover {
  background: #ff9c00;
}
.pricing__controller .theme-btn.active,
.pricing__controller .theme-btn:hover {
  color: #fff;
  background: #4069ff;
}
.pricing__card {
  background: #e2e9ff;
  height: calc(100% - 30px);
}
.pricing__card-yellow {
  bottom: #f3f3f2;
}
.pricing__card-yellow.standard-plan .pricing__card-body:after {
  /* background-image: url(assets/img/price/standard-plan-2.svg) !important; */
}
.pricing__card-yellow.professional .pricing__card-body:after {
  /* background-image: url(assets/img/price/professional-2.svg) !important; */
}
.pricing__card-yellow .pricing__card-header:after {
  /* background-image: url(assets/img/price/circle-2.svg); */
}
.pricing__card-yellow .pricing__card-header .title {
  color: #0e0a01 !important;
}
.pricing__card-yellow .pricing__card-header .price {
  background: #ff9c00;
}
.pricing__card-yellow .pricing__card-body:after {
  /* background-image: url(assets/img/price/basic-plan-2.svg); */
}
.pricing__card-yellow .pricing__card-body ul li i {
  color: #ff9c00;
}
.pricing__card-yellow .pricing__card-body .theme-btn {
  color: #ff9c00;
  border-color: rgba(255, 156, 0, 0.3);
}
.pricing__card-yellow .pricing__card-body .theme-btn:after {
  background: #4069ff;
}
.pricing__card-yellow .pricing__card-body .theme-btn:hover,
.pricing__card-yellow .pricing__card-body .theme-btn:focus {
  background: #4069ff;
  border-color: #4069ff;
}
.pricing__card.standard-plan .pricing__card-header:after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.pricing__card.standard-plan .pricing__card-body:after {
  bottom: -30px;
  right: -473px;
  width: 649.27px;
  height: 753.04px;
  /* background-image: url(assets/img/price/standard-plan.svg); */
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.pricing__card.professional .pricing__card-body:after {
  bottom: -30px;
  right: -470px;
  width: 644.51px;
  height: 715.97px;
  /* background-image: url(assets/img/price/professional.svg); */
}
.pricing__card-header {
  position: relative;
  padding: 39px 40px 29px;
  border-bottom: 1px solid rgba(64, 105, 255, 0.1);
}
@media (max-width: 991px) {
  .pricing__card-header {
    padding: 29px 35px;
  }
}
.pricing__card-header:after {
  top: 16px;
  right: 19px;
  content: "";
  width: 80.97px;
  height: 80.97px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/price/circle.svg); */
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
}
.pricing__card-header .title {
  font-size: 30px;
  line-height: 36px;
}
@media (max-width: 991px) {
  .pricing__card-header .title {
    font-size: 26px;
    line-height: 36px;
  }
}
.pricing__card-header .sub-title {
  font-size: 14px;
  line-height: 24px;
}
.pricing__card-header .sub-title img {
  margin-left: 8px;
}
.pricing__card-header .price {
  min-width: 348px;
  max-width: 100px;
  font-size: 36px;
  margin-left: -40px;
  font-weight: 600;
  line-height: 46px;
  padding: 20px 40px;
  position: relative;
  background: #4069ff;
  display: inline-block;
  border-radius: 0px 10px 10px 0px;
}
.pricing__card-header .price:after {
  top: -8px;
  right: -13px;
  content: "";
  height: 56px;
  width: 54.76px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/price/price-bg.svg); */
}
.pricing__card-header .price span {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 26px;
}
.pricing__card-header p {
  font-family: "Lato", sans-serif;
  max-width: 230px;
}
.pricing__card-body {
  position: relative;
  padding: 29px 40px 39px;
}
@media (max-width: 991px) {
  .pricing__card-body {
    padding: 29px 35px;
  }
}
.pricing__card-body:after {
  content: "";
  right: -285px;
  height: 482px;
  bottom: -157px;
  width: 481.89px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/price/basic-plan.svg); */
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.pricing__card-body ul li {
  font-family: "Lato", sans-serif;
  margin-bottom: 15px;
}
.pricing__card-body ul li:last-child {
  margin-bottom: 0;
}
.pricing__card-body ul li i {
  color: #4069ff;
  font-size: 15px;
  margin-right: 20px;
}
.pricing__card-body ul li i.icon-close-3 {
  color: #243342;
}
.pricing__card-body .theme-btn {
  color: #4069ff;
  font-weight: 600;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 13.5px 30.2px;
  background: transparent;
  border: 1.5px solid rgba(64, 105, 255, 0.3);
}
.pricing__card-body .theme-btn:after {
  background: #4069ff;
}
.pricing__card-body .theme-btn i {
  font-size: 20px;
}
.pricing__card-body .theme-btn:hover,
.pricing__card-body .theme-btn:focus {
  color: #fff;
  background: #4069ff;
}

.hide {
  display: none;
}

.why-choose ~ .cta-banner {
  z-index: 1;
  position: relative;
}
.why-choose ~ .cta-banner:after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  content: "";
  position: absolute;
  background: #eff0f6;
  height: calc(100% - 93px);
}
@media (max-width: 1199px) {
  .why-choose ~ .cta-banner:after {
    height: calc(100% - 70px);
  }
}
@media (max-width: 991px) {
  .why-choose ~ .cta-banner:after {
    height: calc(100% - 101px);
  }
}
@media (max-width: 767px) {
  .why-choose ~ .cta-banner:after {
    height: calc(100% - 90px);
  }
}
@media (max-width: 506px) {
  .why-choose ~ .cta-banner:after {
    height: calc(100% - 100px);
  }
}
@media (max-width: 365px) {
  .why-choose ~ .cta-banner:after {
    height: calc(100% - 120px);
  }
}
.why-choose__media {
  max-width: 580px;
}
@media (max-width: 1199px) {
  .why-choose__media {
    width: 100%;
    max-width: 100%;
  }
  .why-choose__media img {
    width: 100%;
  }
}
.why-choose__media-wrapper {
  position: relative;
}
@media (min-width: 1200px) {
  .why-choose__media-wrapper {
    margin-right: -30px;
  }
}
.why-choose__media-wrapper .global-country {
  margin-left: auto;
  margin-top: -210px;
  display: inline-block;
  background-size: auto;
  padding: 59.8px 68.5px;
  background-color: #4069ff;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px 0px 20px 0px;
  /* background-image: url(assets/img/services/map.png); */
}
@media (max-width: 767px) {
  .why-choose__media-wrapper .global-country {
    padding: 40px 50px;
    margin-top: -145px;
  }
}
@media (max-width: 575px) {
  .why-choose__media-wrapper .global-country {
    padding: 30px 35px;
    margin-top: -130px;
  }
}
@media (max-width: 480px) {
  .why-choose__media-wrapper .global-country {
    padding: 25px 30px;
    margin-top: -100px;
  }
}
.why-choose__media-wrapper .global-country .number {
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
}
@media (max-width: 767px) {
  .why-choose__media-wrapper .global-country .number {
    font-size: 36px;
  }
}
@media (max-width: 575px) {
  .why-choose__media-wrapper .global-country .number {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .why-choose__media-wrapper .global-country .number {
    font-size: 26px;
  }
}
.why-choose__media-wrapper .global-country .title {
  max-width: 125px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  .why-choose__media-wrapper .global-country .title {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .why-choose__content:not(.why-choose__content-home) {
    padding-left: 85px;
  }
}
.why-choose__content-home {
  max-width: 635px;
}
@media (max-width: 1199px) {
  .why-choose__text .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .why-choose__text .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .why-choose__text .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .why-choose__text .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.why-choose.why-choose__home .container {
  z-index: 1;
  position: relative;
}
.why-choose.why-choose__home .container:after {
  top: -43px;
  left: -25px;
  content: "";
  width: 291px;
  height: 279px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/home/why-choose__home-overly.png); */
}
.why-choose .why-choose__item-two-wrapper {
  gap: 30px 30px;
  -ms-grid-columns: minmax(210px, 318px) 30px minmax(210px, 318px) 30px
    minmax(210px, 318px) 30px minmax(210px, 318px);
  grid-template-columns: minmax(210px, 318px) minmax(210px, 318px) minmax(
      210px,
      318px
    ) minmax(210px, 318px);
}
@media (max-width: 1199px) {
  .why-choose .why-choose__item-two-wrapper {
    -ms-grid-columns: minmax(210px, 318px) minmax(210px, 318px)
      minmax(210px, 318px);
    grid-template-columns: minmax(210px, 318px) minmax(210px, 318px) minmax(
        210px,
        318px
      );
  }
}
@media (max-width: 991px) {
  .why-choose .why-choose__item-two-wrapper {
    -ms-grid-columns: minmax(210px, 318px) minmax(210px, 318px);
    grid-template-columns: minmax(210px, 318px) minmax(210px, 318px);
  }
}
@media (max-width: 575px) {
  .why-choose .why-choose__item-two-wrapper {
    -ms-grid-columns: minmax(210px, 318px);
    grid-template-columns: minmax(210px, 318px);
  }
}
.why-choose__item.why-choose__item-two {
  position: relative;
  padding: 40px 30px 30px;
  background-size: cover;
  background-position: right;
  -webkit-box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
}
.why-choose__item.why-choose__item-two a {
  font-size: 16px;
  line-height: 24px;
}
.why-choose__item.why-choose__item-two a:hover,
.why-choose__item.why-choose__item-two a:focus {
  color: #04213f !important;
}
.why-choose__item.why-choose__item-two a i {
  font-size: 12px;
  margin-left: 8px;
}
.why-choose__item-wrapper {
  gap: 50px 30px;
  -ms-grid-columns: minmax(210px, 262px) 30px minmax(210px, 262px);
  grid-template-columns: minmax(210px, 262px) minmax(210px, 262px);
}
@media (max-width: 480px) {
  .why-choose__item-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
.why-choose__item .icon {
  font-size: 50px;
}
.why-choose__item .description {
  max-width: 262px;
}
.why-choose .video_btn {
  border-radius: 0px;
  position: absolute;
  border: none;
  top: 5%;
  left: -10%;
  width: 150px;
  height: 150px;
  background-color: #ff9c00;
}
.why-choose .video_btn i {
  color: #ff9c00 !important;
}
@media (max-width: 768px) {
  .why-choose .video_btn {
    left: 0%;
    top: 0%;
  }
}

.faq.faq_2 button {
  background: rgba(255, 156, 0, 0.1019607843) !important;
  color: #0e0a01 !important;
}
.faq.faq_2 button:hover,
.faq.faq_2 button:focus {
  background-color: #ff9c00 !important;
  color: #fff !important;
}
.faq .accordion-item {
  margin-bottom: 20px;
  border: 0 !important;
  background: transparent;
  border-radius: 0 !important;
}
.faq .accordion-item:last-child {
  margin-bottom: 0 !important;
}
.faq .accordion-item .accordion-header button {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  background: #4069ff;
  border-radius: 0;
}
@media (max-width: 575px) {
  .faq .accordion-item .accordion-header button {
    font-size: 16px;
    line-height: 26px;
  }
}
.faq .accordion-item .accordion-header button:after {
  content: "\f067" !important;
  background-image: inherit;
  font-family: "Font Awesome 5 Pro";
}
.faq .accordion-item .accordion-header button[aria-expanded="true"]:after {
  content: "\f068" !important;
}
.faq .accordion-item .accordion-header button span {
  margin-right: 8px;
  color: #0e1e36;
}
.faq .accordion-item .accordion-header button i {
  font-size: 20px;
  margin-right: 8px;
}
.faq .accordion-item .accordion-header button:hover,
.faq .accordion-item .accordion-header button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.faq .accordion-item .accordion-body {
  font-family: "Lato", sans-serif;
  padding: 20px 17px 5px;
}
@media (max-width: 575px) {
  .faq .accordion-item .accordion-body p {
    font-size: 16px;
    line-height: 26px;
  }
}

.video-play-btn {
  z-index: 1;
  top: 60px;
  left: -74px;
  width: 160px;
  height: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #4069ff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: move-bottom 3s linear infinite;
  animation: move-bottom 3s linear infinite;
}
@media (max-width: 1550px) {
  .video-play-btn {
    top: 50px;
    left: -20px;
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  .video-play-btn {
    top: 30px;
    width: 100px;
    height: 100px;
  }
}
.video-play-btn a {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4069ff;
  font-size: 22px;
  line-height: 60px;
  text-align: center;
  background: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.2);
  -webkit-animation: playVideo1 3s linear infinite;
  animation: playVideo1 3s linear infinite;
}
@media (max-width: 1550px) {
  .video-play-btn a {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}

.mfp-move-from-top {
  -webkit-transition: initial;
  transition: initial;
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-move-from-top.mfp-bg {
  opacity: 0.7 !important;
  background-color: #000;
}
.mfp-move-from-top .mfp-container {
  padding: 0;
}
.mfp-move-from-top .mfp-container .mfp-content {
  height: 100%;
  vertical-align: middle;
}
.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(300px);
  transform: translateY(300px);
}
.mfp-move-from-top.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-move-from-top.mfp-removing .mfp-with-anim {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
}
.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-move-from-top .mfp-container {
  right: 0;
  width: 100%;
  max-width: 1212px;
  margin-left: auto;
  margin-right: auto;
}
.mfp-move-from-top .mfp-container .mfp-content {
  height: auto;
  padding: 0 15px;
}
.mfp-move-from-top .mfp-container .mfp-content .mfp-figure:after {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}
.mfp-move-from-top .mfp-container .mfp-content .mfp-figure .mfp-close {
  opacity: 1;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  line-height: 20px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mfp-move-from-top .mfp-container .mfp-content .mfp-figure .mfp-close span {
  font-size: 13px;
  margin-left: 7px;
  vertical-align: 1px;
  display: inline-block;
}
.mfp-move-from-top .mfp-container .mfp-content .mfp-figure .mfp-close:hover,
.mfp-move-from-top .mfp-container .mfp-content .mfp-figure .mfp-close:focus {
  color: #e6e6e6;
}
.mfp-move-from-top
  .mfp-container
  .mfp-content
  .mfp-figure
  .mfp-bottom-bar
  .mfp-title {
  color: red;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.mfp-move-from-top
  .mfp-container
  .mfp-content
  .mfp-figure
  .mfp-bottom-bar
  .mfp-counter {
  color: red;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.mfp-move-from-top .mfp-container .mfp-arrow {
  opacity: 1;
  font-size: 18px;
}
.mfp-move-from-top .mfp-container .mfp-arrow:before {
  border: 0;
  color: red;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: "consulter";
}
.mfp-move-from-top .mfp-container .mfp-arrow:after {
  content: none;
}
.mfp-move-from-top .mfp-container .mfp-arrow.mfp-arrow-left:before {
  content: "\e809";
}
.mfp-move-from-top .mfp-container .mfp-arrow.mfp-arrow-left:hover:before {
  color: #4069ff;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}
.mfp-move-from-top .mfp-container .mfp-arrow.mfp-arrow-right:before {
  content: "î Ÿ";
}
.mfp-move-from-top .mfp-container .mfp-arrow.mfp-arrow-right:hover:before {
  color: #4069ff;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.mfp-move-from-top .mfp-container .mfp-bottom-bar {
  margin-top: -20px;
}
.mfp-move-from-top .mfp-container .mfp-bottom-bar .mfp-title {
  color: #4069ff;
}
.mfp-move-from-top .mfp-container .mfp-bottom-bar .mfp-counter {
  color: #4069ff;
}
.mfp-move-from-top .mfp-iframe-scaler {
  overflow: inherit;
  vertical-align: middle;
}
.mfp-move-from-top .mfp-iframe-scaler .mfp-iframe {
  padding: 0;
  margin: 0;
  background: #fff;
}
.mfp-move-from-top .mfp-iframe-scaler .mfp-close {
  opacity: 1;
  color: #fff;
  font-size: 35px;
  cursor: pointer;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "consulter";
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mfp-move-from-top .mfp-iframe-scaler .mfp-close:hover,
.mfp-move-from-top .mfp-iframe-scaler .mfp-close:focus {
  color: #e6e6e6;
}

.gallery-bar {
  top: 10px;
  width: 30px;
  left: -15px;
  height: 300px;
  position: absolute;
  background: #4069ff;
  -webkit-animation: move-bottom 3s linear infinite;
  animation: move-bottom 3s linear infinite;
}
@media (max-width: 767px) {
  .gallery-bar {
    width: 20px;
    left: -10px;
    height: 200px;
  }
}
@media (max-width: 480px) {
  .gallery-bar {
    width: 15px;
    left: -7.5px;
    height: 150px;
  }
}

.services-map {
  margin-top: -250px;
}
@media (max-width: 991px) {
  .services-map {
    margin-top: -200px;
  }
}
@media (max-width: 991px) {
  .services-map {
    margin-top: -190px;
  }
}
.services-map__map {
  width: 100%;
  height: 500px;
}
@media (max-width: 991px) {
  .services-map__map {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .services-map__map {
    height: 380px;
  }
}

.counter-area .mb-minus-30 {
  margin-bottom: -30px;
}
.counter-area__item {
  margin-bottom: 30px;
  background: #e2e9ff;
  height: calc(100% - 30px);
}
.counter-area__item.counter-area__item-two {
  background: rgba(255, 156, 0, 0.1);
}
.counter-area__item.counter-area__item-two .icon {
  background: rgba(255, 156, 0, 0.1);
}
.counter-area__item.counter-area__item-three {
  background-color: #fff;
  border-radius: 6px !important;
}
.counter-area__item.counter-area__item-three .icon {
  background: rgba(64, 105, 255, 0.1);
}
.counter-area__item.counter-area__item-fore {
  background-color: transparent;
  margin-bottom: 0;
  border-radius: 6px !important;
}
.counter-area__item.counter-area__item-fore .text {
  padding-left: 15px;
}
.counter-area__item.counter-area__item-fore .number {
  border-bottom: none !important;
  padding-bottom: 0;
}
.counter-area__item.counter-area__item-fore .icon {
  background: transparent;
}
.counter-area__item.counter-area__item-fore .icon i {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0px 28px;
}
.counter-area__item .icon {
  font-size: 60px;
  min-height: 100%;
  line-height: 120px;
  background: rgba(64, 105, 255, 0.1);
}
@media (max-width: 1299px) {
  .counter-area__item .icon {
    font-size: 50px;
  }
}
.counter-area__item .icon i {
  padding: 30px 28px;
}
@media (max-width: 1299px) {
  .counter-area__item .icon i {
    padding: 20px;
  }
}
.counter-area__item .text {
  width: 100%;
  padding: 17px 0 14px;
}
.counter-area__item .text .number {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(64, 105, 255, 0.1);
}
@media (max-width: 1299px) {
  .counter-area__item .text .number {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
}

.why-counter__overly .container {
  position: relative;
}
.why-counter__overly .overly:after {
  top: -411px;
  content: "";
  right: -1529px;
  width: 2622.94px;
  height: 1343.56px;
  position: absolute;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  /* background-image: url(assets/img/services/why-counter-overly-1.png); */
}
.why-counter__overly .overly:before {
  top: 493px;
  content: "";
  left: -1388px;
  height: 1117px;
  width: 2552.29px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-animation: move-bottom 3s linear infinite;
  animation: move-bottom 3s linear infinite;
  /* background-image: url(assets/img/services/why-counter-overly-2.png); */
}
@media (max-width: 1199px) {
  .why-counter__overly .overly:before {
    top: 1602px;
  }
}

.get-free {
  z-index: 2;
  position: relative;
  margin-bottom: -100px;
}
@media (max-width: 1300px) {
  .get-free {
    margin-bottom: -138.5px;
  }
}
@media (max-width: 991px) {
  .get-free {
    margin-bottom: -207.5px;
  }
}
.get-free.get-free-home .get-free__content-wrapper {
  background: #ef0548;
}
.get-free__content {
  gap: 30px;
  max-width: 1377px;
  margin-left: auto;
  padding: 52px 116px 52px 15px;
}
@media (max-width: 1300px) {
  .get-free__content {
    max-width: 1131px;
    padding-right: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 1199px) {
  .get-free__content {
    max-width: 951px;
  }
}
@media (max-width: 991px) {
  .get-free__content {
    max-width: 711px;
  }
}
@media (max-width: 767px) {
  .get-free__content {
    max-width: 531px;
  }
}
@media (max-width: 575px) {
  .get-free__content {
    padding-right: 15px;
  }
}
.get-free__content-wrapper {
  background: #4069ff;
  background-position: 89% -1%;
  margin-left: calc(-50vw + 50%) !important;
}
.get-free__content-text {
  font-size: 20px;
  line-height: 26px;
}
@media (min-width: 1300px) {
  .get-free__content-text {
    max-width: 361px;
  }
}
@media (max-width: 1300px) {
  .get-free__content-text .title {
    font-size: 46px;
    line-height: 54px;
  }
}
@media (max-width: 1199px) {
  .get-free__content-text .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .get-free__content-text .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .get-free__content-text .title {
    font-size: 32px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  .get-free__content-text .title {
    font-size: 28px;
    line-height: 42px;
  }
}
@media (max-width: 991px) {
  .get-free__content-form {
    width: 100%;
  }
}
.get-free__content-form form {
  gap: 20px;
}
.get-free__content-form form .single-personal-info input {
  width: 263px;
  max-width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: #243342;
  padding: 15px 30px;
  border-radius: 2px;
}
@media (max-width: 991px) {
  .get-free__content-form form .single-personal-info input {
    width: 100%;
  }
}
.get-free__content-form
  form
  .single-personal-info
  input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #243342;
}
.get-free__content-form form .single-personal-info input::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #243342;
}
.get-free__content-form form .single-personal-info input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #243342;
}
.get-free__content-form
  form
  .single-personal-info
  input::-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #243342;
}
.get-free__content-form form .single-personal-info input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #243342;
}
.get-free__content-form form .theme-btn {
  font-size: 15px;
  line-height: 23px;
  background: #0e1e36;
}
.get-free__content-form form .theme-btn:hover,
.get-free__content-form form .theme-btn:focus {
  background: #ff9c00;
}

.digital-area .mb-minus-30 {
  margin-bottom: -30px;
}
.digital-area .container {
  position: relative;
}
.digital-area .container:after {
  top: -587px;
  content: "";
  left: -564px;
  z-index: -1;
  width: 2048.04px;
  height: 1285.06px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  /* background-image: url(assets/img/services/why-counter-overly-3.png); */
}
.digital-area .why-choose__item {
  background: #fff;
  margin-bottom: 30px;
  padding: 40px 30px 30px;
  height: calc(100% - 30px);
  -webkit-box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
  box-shadow: 0px 10px 20px rgba(4, 33, 63, 0.05);
}
@media (max-width: 991px) {
  .digital-area .why-choose__item {
    padding: 25px 20px;
  }
}
.digital-area .why-choose__item a {
  display: block;
  font-size: 16px;
  margin-top: 20px;
  color: #4069ff;
  font-weight: 500;
  line-height: 24px;
}
.digital-area .why-choose__item a:hover,
.digital-area .why-choose__item a:focus {
  color: #000;
}

.services-details .media {
  height: 450px;
  width: 100%;
}
@media (max-width: 575px) {
  .services-details .media {
    height: 400px;
  }
}
@media (max-width: 480px) {
  .services-details .media {
    height: 380px;
  }
}
@media (max-width: 380px) {
  .services-details .media {
    height: 350px;
  }
}
.services-details .media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.services-details__content h1,
.services-details__content h2,
.services-details__content h3,
.services-details__content h4,
.services-details__content h5,
.services-details__content h6 {
  color: #0e1e36;
  margin-bottom: 20px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .services-details__content h1,
  .services-details__content h2,
  .services-details__content h3,
  .services-details__content h4,
  .services-details__content h5,
  .services-details__content h6 {
    margin-bottom: 10px;
  }
}
.services-details__content h2 {
  color: #0e1e36;
}
.services-details__content h2 span {
  font-family: "Lato", sans-serif;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #243342;
  white-space: nowrap;
}
@media (max-width: 1199px) {
  .services-details__content h2 {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .services-details__content h2 {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .services-details__content h2 {
    font-size: 32px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  .services-details__content h2 {
    font-size: 28px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .services-details__content h4 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 575px) {
  .services-details__content h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
.services-details__content h6 {
  font-size: 16px;
  line-height: 24px;
}
.services-details__content p {
  font-family: "Lato", sans-serif;
  max-width: 840px;
  margin-bottom: 20px;
}
.services-details__content ul {
  gap: 30px 94px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  margin: 30px 0 30px 0;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}
@media (max-width: 991px) {
  .services-details__content ul {
    gap: 30px 40px;
  }
}
@media (max-width: 575px) {
  .services-details__content ul {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    margin-bottom: 20px;
  }
}
.services-details__content ul li {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #0e1e36;
  padding-left: 50px;
  position: relative;
  margin-bottom: 20px;
}
.services-details__content ul li:after {
  left: 0;
  width: 30px;
  content: "\f00c";
  height: 30px;
  color: #4069ff;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  border-radius: 500px;
  top: calc(50% - 13px);
  background: #eff0f6;
  font-family: "Font Awesome 5 Pro";
}
.services-details__content blockquote {
  margin-top: 30px;
  margin-bottom: 50px;
}
.services-details__content blockquote p {
  font-size: 20px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
  font-style: italic;
}
.services-details__content figure {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.services-details__content figure ul {
  margin: 0;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}
.services-details__content hr {
  height: 1px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-color: #e2e9ff;
}
@media (max-width: 1199px) {
  .services-details__content hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 991px) {
  .services-details__content hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .services-details__content hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blog .mb-minus-30 {
  margin-bottom: -30px;
}
.blog-item {
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0px 6px 30px rgba(4, 33, 63, 0.04);
  box-shadow: 0px 6px 30px rgba(4, 33, 63, 0.04);
}
.blog-item:hover .blog-featured-thumb img,
.blog-item:focus .blog-featured-thumb img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.blog-item .blog-featured-thumb {
  height: 300px;
  position: relative;
}
@media (max-width: 480px) {
  .blog-item .blog-featured-thumb {
    height: 280px;
  }
}
.blog-item .blog-featured-thumb .media {
  width: 100%;
  height: 100%;
}
.blog-item .blog-featured-thumb .media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-item .blog-featured-thumb .date {
  color: #fff;
  left: 30px;
  bottom: -20px;
  font-size: 16px;
  min-width: 80px;
  padding: 19px 12px;
  border-radius: 10px;
  position: absolute;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  background-color: #4069ff;
}
@media (max-width: 575px) {
  .blog-item .blog-featured-thumb .date {
    min-width: 70px;
  }
}
.blog-item .blog-featured-thumb .date span {
  display: block;
}
.blog-item .blog-featured-thumb .date span:first-child {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}
.blog-item .blog-featured-thumb .date span:nth-child(2) {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.blog-item .content .post-author a {
  font-size: 12px;
  color: #4069ff;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.blog-item .content .post-author a:hover,
.blog-item .content .post-author a:focus {
  color: #ff9c00;
}
.blog-item .content .post-meta {
  gap: 5px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.blog-item .content .post-meta a {
  font-size: 14px;
  color: #1b3c54;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
.blog-item .content .post-meta a i,
.blog-item .content .post-meta a img {
  color: #4069ff;
  margin-right: 10px;
}
.blog-item .content .post-meta a:hover,
.blog-item .content .post-meta a:focus {
  color: #4069ff;
}
.blog-item .content h4 {
  color: #0e0a01;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .blog-item .content h4 {
    font-size: 22px;
    line-height: 28px;
  }
}
@media (max-width: 575px) {
  .blog-item .content h4 {
    font-size: 20px;
    line-height: 26px;
  }
}
.blog-item .content h4 a:hover,
.blog-item .content h4:focus {
  color: #4069ff;
}
.blog-item .content h3 {
  color: #243342;
  margin-bottom: 20px;
  text-transform: capitalize;
}
@media (max-width: 1199px) {
  .blog-item .content h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 991px) {
  .blog-item .content h3 {
    font-size: 28px;
    line-height: 36px;
  }
}
@media (max-width: 767px) {
  .blog-item .content h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 575px) {
  .blog-item .content h3 {
    font-size: 22px;
    line-height: 28px;
  }
}
.blog-item .content h3 a:hover,
.blog-item .content h3:focus {
  color: #4069ff;
}
.blog-item .content .btn-link-share .theme-btn.btn-border {
  color: #4069ff;
  font-size: 15px;
  line-height: 22px;
  padding: 9px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  border: 1.5px solid #e2e9ff;
}
.blog-item .content .btn-link-share .theme-btn.btn-border:after {
  background: #4069ff;
}
.blog-item .content .btn-link-share .theme-btn.btn-border i {
  opacity: 0.9;
  font-size: 20px;
  font-weight: 500;
}
.blog-item .content .btn-link-share .theme-btn.btn-border:hover,
.blog-item .content .btn-link-share .theme-btn.btn-border:focus {
  color: #fff;
  background-color: #4069ff;
}
.blog-item .content .btn-link-share .theme-btn.btn-border.btn-fill {
  border: 0;
  color: #fff;
  padding: 10.5px 21.4px;
  background-color: #4069ff;
}
.blog-item .content .btn-link-share .theme-btn.btn-border.btn-fill:hover,
.blog-item .content .btn-link-share .theme-btn.btn-border.btn-fill:focus {
  color: #fff;
  background-color: #ff9c00;
}
.blog-item.blog-standard {
  border: 1px solid #e2e9ff;
}
.blog-item.blog-standard .blog-featured-thumb {
  height: 450px;
}
@media (max-width: 767px) {
  .blog-item.blog-standard .blog-featured-thumb {
    height: 350px;
  }
}
@media (max-width: 575px) {
  .blog-item.blog-standard .blog-featured-thumb {
    height: 300px;
  }
}
@media (max-width: 480px) {
  .blog-item.blog-standard .blog-featured-thumb {
    height: 250px;
  }
}
.blog-item.blog-standard .content {
  font-family: "Lato", sans-serif;
}
.blog-item.blog-standard .btn-link-share .theme-btn.btn-border:after {
  background: #ff9c00;
}
.blog-item.blog-item-two .blog-featured-thumb .date {
  top: 0;
  left: 10px;
  bottom: inherit;
  background-color: #ff9c00;
  border-radius: 0px 0px 10px 10px;
}
.blog-item.blog-item-two .content .post-author a {
  color: #ff9c00;
}
.blog-item.blog-item-two .content .post-author a:hover,
.blog-item.blog-item-two .content .post-author a:focus {
  color: #4069ff;
}
.blog-item.blog-item-two .content h4 a:hover,
.blog-item.blog-item-two .content h4 a:focus {
  color: #ff9c00;
}
.blog-item.blog-item-two .content .btn-link-share .theme-btn.btn-border {
  color: #ff9c00;
  border-color: #ff9c00;
}
.blog-item.blog-item-two .content .btn-link-share .theme-btn.btn-border:after {
  background: #ff9c00;
}
.blog-item.blog-item-two .content .btn-link-share .theme-btn.btn-border:hover,
.blog-item.blog-item-two .content .btn-link-share .theme-btn.btn-border:focus {
  color: #fff;
  background-color: #ff9c00;
}
.blog-item.blog-item-three .blog-featured-thumb .date {
  top: 0;
  left: 10px;
  bottom: inherit;
  background-color: #ef0548;
  border-radius: 0px 0px 10px 10px;
}
.blog-item.blog-item-three .content .post-author a {
  color: #ef0548;
}
.blog-item.blog-item-three .content .post-author a:hover,
.blog-item.blog-item-three .content .post-author a:focus {
  color: #ff9c00;
}
.blog-item.blog-item-three .content a:hover,
.blog-item.blog-item-three .content a:focus {
  color: #ef0548;
}
.blog-item.blog-item-three .content .btn-link-share .theme-btn.btn-border {
  color: #ef0548;
  border-color: transparent;
}
.blog-item.blog-item-three
  .content
  .btn-link-share
  .theme-btn.btn-border:after {
  background-color: #ef0548;
}
.blog-item.blog-item-three .content .btn-link-share .theme-btn.btn-border:hover,
.blog-item.blog-item-three
  .content
  .btn-link-share
  .theme-btn.btn-border:focus {
  color: #fff;
  background-color: #ef0548;
}

.page-nav-wrap ul {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-nav-wrap ul li.arrow a {
  line-height: 32px;
}
.page-nav-wrap ul li a {
  width: 40px;
  height: 40px;
  color: #4069ff;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  display: inline-block;
  border: 1px solid #e2e9ff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.page-nav-wrap ul li a i {
  font-size: 10px;
  font-weight: 600;
}
.page-nav-wrap ul li a:hover {
  color: #fff;
  background: #4069ff;
}

.blog-post-details h1,
.blog-post-details h2,
.blog-post-details h3,
.blog-post-details h4,
.blog-post-details h5,
.blog-post-details h6,
.blog-post-details p {
  margin-bottom: 20px;
}
.blog-post-details blockquote {
  margin: 40px 0 40px;
}
.blog-post-details figure {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (min-width: 991px) {
  .blog-post-details figure {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.blog-post-details blockquote {
  padding-top: 50px;
  position: relative;
}
.blog-post-details blockquote:after {
  top: 0;
  left: 0;
  content: "\f10e";
  color: #4069ff;
  font-size: 40px;
  font-weight: 600;
  position: absolute;
  font-family: "Font Awesome 5 Pro";
}
.blog-post-details blockquote p {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  font-style: italic;
}
.blog-post-details blockquote h6 {
  margin: 0;
  color: #0e1e36;
}
.blog-post-details blockquote h6 span {
  font-size: 16px;
  margin-left: 5px;
  color: #243342;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 26px;
  font-style: normal;
}
.blog-post-details:hover .media img,
.blog-post-details:focus .media img {
  -webkit-transform: inherit;
  transform: inherit;
}

.tag-share_wrapper {
  gap: 20px 30px;
}
.tag-share_wrapper .tags ul li a {
  background: #eff0f6;
}
.tag-share_wrapper .social-profile ul {
  gap: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tag-share_wrapper .social-profile ul li a {
  padding: 0;
  width: 40px;
  height: 40px;
  color: #4069ff;
  display: block;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  font-weight: 500;
  background-color: #e2e9ff;
  border-radius: 500px;
}
.tag-share_wrapper .social-profile ul li a::before {
  content: inherit;
}
.tag-share_wrapper .social-profile ul li a:hover,
.tag-share_wrapper .social-profile ul li a:focus {
  color: #fff;
  background-color: #4069ff;
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}

.comments-section-wrap h4 {
  font-weight: 500;
  color: #243342;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e2e9ff;
}
.comments-section-wrap h4 span {
  font-size: 18px;
  line-height: 26px;
}

.comments-item-list li {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e9ff;
}
@media (max-width: 767px) {
  .comments-item-list li {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}
.comments-item-list li .author-img {
  float: left;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 15px;
  border-radius: 500px;
}
@media (max-width: 585px) {
  .comments-item-list li .author-img {
    float: none;
    margin-bottom: 20px;
  }
}
.comments-item-list li .author-info-comment {
  overflow: auto;
}
.comments-item-list li .author-info-comment .info h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #0e1e36;
  margin-bottom: 3px;
}
.comments-item-list li .author-info-comment .info span {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #243342;
}
.comments-item-list li .author-info-comment .comment-text {
  max-width: 715px;
}
.comments-item-list li .author-info-comment .comment-text p {
  margin-bottom: 17px;
}
.comments-item-list li .author-info-comment .comment-text a {
  color: #4069ff;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.comments-item-list li .author-info-comment .comment-text a:hover,
.comments-item-list li .author-info-comment .comment-text a:focus {
  color: #ff9c00;
}

.comment-form {
  width: 100%;
}
.comment-form input,
.comment-form textarea {
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: #243342;
  padding: 13px 29px;
  margin-bottom: 20px;
  background: #f8f9fb;
}
.comment-form input::-webkit-input-placeholder,
.comment-form textarea::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 26px;
  color: #243342;
}
.comment-form input::-moz-placeholder,
.comment-form textarea::-moz-placeholder {
  font-size: 16px;
  line-height: 26px;
  color: #243342;
}
.comment-form input:-ms-input-placeholder,
.comment-form textarea:-ms-input-placeholder {
  font-size: 16px;
  line-height: 26px;
  color: #243342;
}
.comment-form input::-ms-input-placeholder,
.comment-form textarea::-ms-input-placeholder {
  font-size: 16px;
  line-height: 26px;
  color: #243342;
}
.comment-form input::placeholder,
.comment-form textarea::placeholder {
  font-size: 16px;
  line-height: 26px;
  color: #243342;
}
.comment-form textarea {
  resize: none;
  height: 250px;
  border: 1px solid #e2e9ff;
}
@media (max-width: 767px) {
  .comment-form textarea {
    height: 120px;
  }
}
.comment-form .input__wrapper {
  gap: 0 20px;
}
.comment-form .single-form-input {
  width: 100%;
}
.comment-form .submit-btn {
  font-size: 15px;
  margin-top: 10px;
  line-height: 22px;
  display: inline-block;
  background: #4069ff;
  padding: 10.5px 20.1px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.comment-form .submit-btn:hover,
.comment-form .submit-btn:focus {
  background-color: #ff9c00;
}

@media (min-width: 1199px) {
  .main-sidebar {
    margin-left: 20px;
  }
}
@media (max-width: 1199px) {
  .main-sidebar {
    margin-top: 50px;
  }
}
.main-sidebar .single-sidebar-widget {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #eff0f6;
}
.main-sidebar .single-sidebar-widget:last-child {
  margin-bottom: 0;
}

.search_widget form {
  width: 100%;
  position: relative;
}
.search_widget form input {
  width: 100%;
  font-family: "Lato", sans-serif;
  color: #243342;
  padding: 17px 18px;
  background-color: #fff;
}
.search_widget form input::-webkit-input-placeholder {
  color: #243342;
}
.search_widget form input::-moz-placeholder {
  color: #243342;
}
.search_widget form input:-ms-input-placeholder {
  color: #243342;
}
.search_widget form input::-ms-input-placeholder {
  color: #243342;
}
.search_widget form input::placeholder {
  color: #243342;
}
.search_widget form button {
  top: 0;
  right: 0;
  width: 56px;
  height: 100%;
  font-size: 20px;
  color: #4069ff;
  text-align: center;
  position: absolute;
  background-color: #e2e9ff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.search_widget form button:hover,
.search_widget form button:focus {
  color: #fff;
  background-color: #4069ff;
}

.widget_categories ul {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.widget_categories ul li a {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  background: #fff;
  color: #0e1e36;
  border-radius: 2px;
  overflow: hidden;
  padding: 18px 22px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget_categories ul li a i {
  color: #4069ff;
  font-size: 20px;
  font-weight: 400;
}
.widget_categories ul li a:hover,
.widget_categories ul li a:focus {
  color: #fff;
  background-color: #4069ff;
}
.widget_categories ul li a:hover i,
.widget_categories ul li a:focus i {
  color: #fff;
}

.resent-posts .single-post-item {
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.resent-posts .single-post-item::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(14, 30, 54, 0)),
    to(#0e1e36)
  );
  background: linear-gradient(180deg, rgba(14, 30, 54, 0) 0%, #0e1e36 100%);
}
.resent-posts .single-post-item:hover .thumb img,
.resent-posts .single-post-item:focus .thumb img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.resent-posts .single-post-item .thumb {
  width: 100%;
  height: 250px;
}
.resent-posts .single-post-item .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.resent-posts .single-post-item .post-content {
  bottom: 0;
  z-index: 2;
  position: absolute;
  padding: 30px 24px;
}
.resent-posts .single-post-item .post-content .post-date {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
.resent-posts .single-post-item .post-content .post-date i {
  font-size: 15px;
  margin-right: 5px;
}
.resent-posts .single-post-item .post-content .post-date:hover,
.resent-posts .single-post-item .post-content .post-date:focus {
  color: #ff9c00;
}
.resent-posts .single-post-item .post-content h6 {
  color: #fff;
}
.resent-posts .single-post-item .post-content h6:hover,
.resent-posts .single-post-item .post-content h6:focus {
  color: #ff9c00;
}
.resent-posts .theme-btn {
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  padding: 15.5px 37.8px;
}
.resent-posts .theme-btn i {
  font-size: 14px;
  margin: 0 10px 0 0;
}

.widget__tags {
  padding-right: 29px;
}

.tags ul {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.tags ul li a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #4069ff;
  background: #fff;
  border-radius: 4px;
  padding: 8.5px 20.1px;
}
.tags ul li a:last-child {
  margin-right: 0;
}
.tags ul li a:hover,
.tags ul li a:focus {
  color: #fff;
  background-color: #4069ff;
}

.download-service-doc .theme-btn {
  gap: 10px;
  width: 100%;
  color: #0e1e36;
  background: #fff;
  margin-bottom: 20px;
  padding: 17.5px 20px;
}
@media (max-width: 575px) {
  .download-service-doc .theme-btn {
    font-size: 14px;
  }
}
.download-service-doc .theme-btn:last-child {
  margin-bottom: 0;
}
.download-service-doc .theme-btn.btn-fdf {
  color: #fff;
  background: #4069ff;
}
.download-service-doc .theme-btn.btn-fdf i {
  color: #fff;
}
.download-service-doc .theme-btn img {
  height: auto;
  max-width: 32px;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #e2e9ff;
}
@media (max-width: 575px) {
  .download-service-doc .theme-btn img {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.download-service-doc .theme-btn i {
  color: #4069ff;
  font-size: 25px;
}
@media (max-width: 575px) {
  .download-service-doc .theme-btn i {
    font-size: 22px;
  }
}

.have-any {
  padding: 0 !important;
  background: transparent !important;
}
.have-any .media {
  height: 400px;
  border-radius: 2px;
}
.have-any .media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.have-any__item {
  z-index: 1;
  max-width: 352px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  position: relative;
  margin-top: -156px;
  padding: 43.5px 15px;
  background-size: cover;
  border: 15px solid #fff;
  background-repeat: no-repeat;
}
@media (max-width: 380px) {
  .have-any__item {
    padding: 35px 10px;
    border-width: 10px;
  }
}
.have-any__item:after {
  left: 0;
  top: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  background: rgba(8, 19, 7, 0.7);
}
.have-any__item .theme-btn {
  border-radius: 4px;
  padding: 11.5px 22.3px;
}
.have-any__item .theme-btn i {
  font-size: 19px;
}

.team .mb-minus-30 {
  margin-bottom: -30px;
}
.team__content .title a {
  color: #4069ff;
  margin-left: 5px;
  text-decoration: underline;
}
.team__content .title a i {
  margin-left: 5px;
}
.team__content .title a:hover,
.team__content .title a:focus {
  color: #ff9c00;
}

.team-item {
  position: relative;
}
.team-item.team-about-item:hover .text,
.team-item.team-about-item:focus .text {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.team-item.team-about-item .media {
  height: 360px;
}
.team-item.team-about-item .media:after {
  height: 20px;
}
.team-item.team-about-item .text {
  text-align: left;
  padding-left: 21px;
  padding-right: 21px;
  bottom: 0 !important;
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.team-item.team-about-item .text .icon {
  width: 50px;
  left: 527px;
  height: 50px;
  color: #4069ff;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  border-radius: 500px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: rgba(64, 105, 255, 0.14);
}
.team-item.team-about-item .icons_team {
  border-radius: 6px;
  position: absolute !important;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  width: 90%;
  height: 90%;
  background-color: rgba(0, 44, 204, 0.6);
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.team-item.team-about-item .icons_team h5 {
  color: #fff;
  margin-bottom: 10px;
}
.team-item.team-about-item .icons_team p {
  color: #fff;
}
.team-item.team-about-item .icons_team .icon {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.team-item.team-about-item .icons_team a {
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  margin: 0px 3px;
  border-radius: 999px;
}
.team-item.team-about-item .icons_team a i {
  color: #7892d4;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.team-item.team-about-item .icons_team a:hover {
  background-color: #4069ff;
}
.team-item.team-about-item .icons_team a:hover i {
  color: #fff;
}
.team-item.team-about-item:hover .icons_team {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.team-item.team-item-two .media:after {
  height: 20px;
  background: #ff9c00;
}
.team-item.team-item-two .text {
  padding-top: 24px;
  padding-bottom: 24px;
}
.team-item.team-item-two .theme-btn {
  background: #ff9c00;
}
.team-item.team-item-two .theme-btn::after {
  background: #4069ff;
}
.team-item.team-item-two .theme-btn:hover,
.team-item.team-item-two .theme-btn:focus {
  background: #ff9c00;
}
.team-item.team-item-three {
  position: relative;
}
.team-item.team-item-three .media {
  position: relative;
}
.team-item.team-item-three .media:after {
  height: 20px;
  background: #ef0548;
}
.team-item.team-item-three .media:before {
  left: 10px;
  top: 10px;
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: calc(100% - 10px);
  background: rgba(4, 33, 63, 0);
}
.team-item.team-item-three .media .social-profile {
  opacity: 0;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 87.5px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.team-item.team-item-three .media .social-profile ul {
  gap: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.team-item.team-item-three .media .social-profile ul li a {
  width: 40px;
  height: 40px;
  display: block;
  font-size: 15px;
  color: #04213f;
  background: #fff;
  line-height: 40px;
  border-radius: 500px;
}
.team-item.team-item-three .media .social-profile ul li a:hover,
.team-item.team-item-three .media .social-profile ul li a:focus {
  color: #fff;
  background: #ef0548;
}
.team-item.team-item-three .text {
  bottom: 51px;
  padding: 22.5px 0;
  position: inherit;
  bottom: 0 !important;
  background: #ed5f6d;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.team-item.team-item-three .theme-btn {
  margin-top: 0;
  border-radius: 0;
  text-align: center;
  background: #ed5f6d;
}
.team-item.team-item-three .theme-btn::after {
  content: inherit;
}
.team-item.team-item-three .theme-btn i {
  font-size: 12px;
}
.team-item.team-item-three .theme-btn:hover,
.team-item.team-item-three .theme-btn:focus {
  background: #ef0548;
}
.team-item.team-item-three:hover .media:before,
.team-item.team-item-three:focus .media:before {
  background: rgba(4, 33, 63, 0.5);
}
.team-item.team-item-three:hover .media .social-profile,
.team-item.team-item-three:focus .media .social-profile {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.team-item.team-item-three:hover .text,
.team-item.team-item-three:focus .text {
  border-color: transparent;
}
@media (max-width: 767px) {
  .team-item {
    max-width: 318px;
    margin-left: auto;
    margin-right: auto;
  }
}
.team-item .media {
  z-index: 1;
  height: 277px;
  overflow: hidden;
  position: relative;
  padding: 10px 10px 0;
}
.team-item .media::after {
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 10px;
  position: absolute;
  background: #4069ff;
  -webkit-transition: height 1s;
  transition: height 1s;
}
.team-item .media.media_2 {
  padding: 10px;
}
.team-item .media.media_2::before {
  bottom: 0;
  left: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 20px;
  position: absolute;
  background: #4069ff;
  -webkit-transition: height 1s;
  transition: height 1s;
}
.team-item .media.media_2 img {
  border-radius: 10px;
}
.team-item .media img {
  width: 100%;
  height: 100%;
}
.team-item .text {
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding: 24px 0;
  position: absolute;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #0e1e36;
}
.team-item .text .position {
  font-size: 15px;
  line-height: 22px;
}
.team-item .theme-btn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  margin-top: 49px;
  font-weight: 600;
  line-height: 22px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.team-item .theme-btn i {
  font-size: 20px;
}
.team-item:hover .text,
.team-item:focus .text {
  bottom: 51px;
}

.single_card_item {
  border-radius: 10px;
  background-color: #fff;
}
.single_card_item .card_img {
  overflow: hidden;
  height: 220px;
  width: 100%;
  border-radius: 10px;
}
.single_card_item .card_img img {
  width: 100%;
  height: 100%;
}
.single_card_item .card_content {
  border-bottom: 1px solid rgba(64, 105, 255, 0.2);
  border-left: 1px solid rgba(64, 105, 255, 0.2);
  border-right: 1px solid rgba(64, 105, 255, 0.2);
}
.single_card_item .card_content.card_content_2 {
  border-bottom: 1px solid #fff5e5;
  border-left: 1px solid #fff5e5;
  border-right: 1px solid #fff5e5;
}
.single_card_item .card_content .content {
  padding: 25px;
}
.single_card_item .card_content .content h4 {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-transform: capitalize;
  color: #0e1e36;
  margin-bottom: 10px;
}
.single_card_item .card_content .content p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #1b3c54;
}
.single_card_item .card_content button {
  height: 50px;
  width: 100%;
  background: #e2e9ff;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4069ff;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.single_card_item .card_content button:hover {
  background-color: #4069ff;
  color: #fff;
}
.single_card_item .card_content button.btn_2 {
  background: #fff5e5;
  color: #ff9c00;
}
.single_card_item .card_content button.btn_2:hover {
  background-color: #ff9c00;
  color: #fff;
}

.team-details__media {
  position: relative;
}
@media (min-width: 1200px) {
  .team-details__media {
    padding-right: 16px;
  }
}
.team-details__media .social-profile {
  gap: 30px;
  left: 30px;
  bottom: 88px;
  position: absolute;
}
@media (max-width: 1300px) {
  .team-details__media .social-profile {
    bottom: 40px;
  }
}
@media (max-width: 1199px) {
  .team-details__media .social-profile {
    gap: 20px;
    left: 20px;
    bottom: 30px;
  }
}
@media (max-width: 380px) {
  .team-details__media .social-profile {
    bottom: 20px;
  }
}
.team-details__media .social-profile span {
  color: #4069ff;
  font-weight: 500;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  -webkit-transform-origin: 77px -46px;
  transform-origin: 77px -46px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@media (max-width: 1199px) {
  .team-details__media .social-profile span {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.3em;
    -webkit-transform-origin: 58px -29px;
    transform-origin: 58px -29px;
  }
}
@media (max-width: 450px) {
  .team-details__media .social-profile span {
    font-size: 13px;
    line-height: 16px;
    -webkit-transform-origin: 43px -23px;
    transform-origin: 43px -23px;
  }
}
@media (max-width: 380px) {
  .team-details__media .social-profile span {
    font-size: 11px;
  }
}
.team-details__media .social-profile ul {
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 380px) {
  .team-details__media .social-profile ul {
    gap: 5px;
  }
}
.team-details__media .social-profile ul li a {
  border: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  display: block;
  font-size: 15px;
  color: #04213f;
  line-height: 41px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #fff;
  border-radius: 500px;
}
@media (max-width: 1199px) {
  .team-details__media .social-profile ul li a {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
}
@media (max-width: 450px) {
  .team-details__media .social-profile ul li a {
    width: 30px;
    height: 30px;
    font-size: 10px;
    line-height: 30px;
  }
}
@media (max-width: 380px) {
  .team-details__media .social-profile ul li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}
.team-details__media .social-profile ul li a::before {
  content: inherit;
}
.team-details__media .social-profile ul li a:hover,
.team-details__media .social-profile ul li a:focus {
  color: #eff0f6;
  background-color: #4069ff;
  -webkit-transform: translate(5px, 0);
  transform: translate(5px, 0);
}
@media (max-width: 991px) {
  .team-details__media img {
    width: 100%;
  }
}
.team-details__content h6 {
  color: #4069ff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .team-details__content h6 {
    margin-bottom: 10px;
  }
}
.team-details__content h2 {
  color: #0e1e36;
  margin-bottom: 20px;
}
.team-details__content h2 span {
  font-family: "Lato", sans-serif;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #243342;
  white-space: nowrap;
}
@media (max-width: 1199px) {
  .team-details__content h2 {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .team-details__content h2 {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .team-details__content h2 {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .team-details__content h2 {
    font-size: 28px;
    line-height: 42px;
  }
}
.team-details__content p {
  font-family: "Lato", sans-serif;
  margin-bottom: 30px;
}
.team-details__content ul {
  margin: 0 0 30px 0;
}
.team-details__content ul li {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #0e1e36;
  padding-left: 35px;
  position: relative;
  margin-bottom: 15px;
}
.team-details__content ul li:last-child {
  margin-bottom: 0;
}
.team-details__content ul li:after {
  left: 0;
  width: 25px;
  content: "\f00c";
  height: 25px;
  color: #4069ff;
  line-height: 25px;
  font-size: 10px;
  text-align: center;
  position: absolute;
  border-radius: 500px;
  background: #eff0f6;
  font-family: "Font Awesome 5 Pro";
}
.team-details__content .contact-info ul {
  margin: 0 0 -15px 0;
}
@media (min-width: 576px) {
  .team-details__content .contact-info ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
}
.team-details__content .contact-info ul li {
  padding-left: 0;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  color: #243342;
  line-height: 24px;
}
@media (min-width: 1441px) {
  .team-details__content .contact-info ul li {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .team-details__content .contact-info ul li {
    font-size: 14px;
    line-height: 22px;
  }
}
.team-details__content .contact-info ul li:after {
  content: inherit;
}
.team-details__content .contact-info ul li span {
  font-weight: 700;
  color: #0e0a01;
  margin-right: 8px;
}
.team-details__content .contact-info ul li a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #243342;
}
@media (max-width: 1199px) {
  .team-details__content .contact-info ul li a {
    font-size: 14px;
    line-height: 22px;
  }
}
.team-details hr {
  height: 1px;
  background-color: #e2e9ff;
}
.team-details__block h3 {
  color: #000;
  margin-bottom: 7px;
}
@media (max-width: 991px) {
  .team-details__block h3 {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .team-details__block h3 {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 575px) {
  .team-details__block h3 {
    font-size: 25px;
    line-height: 39px;
  }
}
@media (max-width: 480px) {
  .team-details__block h3 {
    font-size: 24px;
    line-height: 34px;
  }
}
.team-details__block img {
  height: auto;
  max-width: 100%;
  margin-bottom: 30px;
}
.team-details__block p {
  font-family: "Lato", sans-serif;
}
.team-details__block .educational-item {
  gap: 20px;
}
.team-details__block .educational-item__row {
  gap: 30px;
  border-bottom: 1px solid #e2e9ff;
}
.team-details__block .educational-item__row:last-child {
  border: inherit;
  margin-bottom: 0;
  padding-bottom: 0;
}
.team-details__block .educational-item .number {
  width: 66px;
  height: 66px;
  color: #4069ff;
  min-width: 66px;
  line-height: 66px;
  text-align: center;
  border-radius: 500px;
  background: #e2e9ff;
}
.team-details__block .educational-item .number span {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.team-details__block .educational-item .text p span {
  color: #4069ff;
}

.can-help ~ .cta-banner {
  z-index: 1;
  position: relative;
}
.can-help ~ .cta-banner:after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  content: "";
  position: absolute;
  background: #eff0f6;
  height: calc(100% - 93px);
}
@media (max-width: 1199px) {
  .can-help ~ .cta-banner:after {
    height: calc(100% - 70px);
  }
}
@media (max-width: 991px) {
  .can-help ~ .cta-banner:after {
    height: calc(100% - 101px);
  }
}
@media (max-width: 767px) {
  .can-help ~ .cta-banner:after {
    height: calc(100% - 90px);
  }
}
@media (max-width: 506px) {
  .can-help ~ .cta-banner:after {
    height: calc(100% - 100px);
  }
}
@media (max-width: 365px) {
  .can-help ~ .cta-banner:after {
    height: calc(100% - 120px);
  }
}
.can-help.services-two {
  z-index: 1;
  position: relative;
  padding-bottom: 370px;
}
.can-help.services-two:after {
  left: -869px;
  z-index: -1;
  content: "";
  bottom: -323px;
  position: absolute;
  width: 2102.51px;
  height: 1068.22px;
  background-size: auto;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  /* background-image: url(assets/img/services/services-two-overly.png); */
}
@media (max-width: 991px) {
  .can-help.services-two {
    padding-bottom: 320px;
  }
}
@media (max-width: 767px) {
  .can-help.services-two {
    padding-bottom: 250px;
  }
}
.can-help.services-two ~ .cta-banner:after {
  content: inherit;
}
@media (min-width: 1200px) {
  .can-help.services-two .can-help__content .title {
    max-width: 611px;
  }
}
@media (min-width: 1200px) {
  .can-help.services-two .can-help__content .description {
    max-width: 520px;
  }
}
.can-help.can-help-home-3 {
  padding-top: 375px;
}
.can-help.can-help-home-3 .contaner {
  z-index: 1;
  position: relative;
}
.can-help.can-help-home-3:after {
  z-index: -1;
  left: -857px;
  content: "";
  bottom: -283px;
  position: absolute;
  width: 2102.51px;
  height: 1068.22px;
  background-size: auto;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  background-image: url(../images/can-help-home-3-bg.png);
}
@media (max-width: 1300px) {
  .can-help.can-help-home-3 {
    padding-top: 320px;
  }
}
@media (max-width: 1199px) {
  .can-help.can-help-home-3 {
    padding-top: 240px;
  }
}
@media (max-width: 1199px) {
  .can-help.can-help-home-3 {
    padding-top: 180px;
  }
}
@media (max-width: 767px) {
  .can-help.can-help-home-3 {
    padding-top: 130px;
  }
}
@media (max-width: 480px) {
  .can-help.can-help-home-3 {
    padding-top: 80px;
  }
}
.can-help.can-help-home-3 ~ .cta-banner:after {
  content: inherit;
}
@media (min-width: 1200px) {
  .can-help.can-help-home-3 .can-help__content .title {
    max-width: 611px;
  }
}
@media (min-width: 1200px) {
  .can-help.can-help-home-3 .can-help__content .description {
    max-width: 520px;
  }
}
.can-help.can-help-home-1 {
  z-index: 1;
  position: relative;
}
.can-help.can-help-home-1 .can-help-background {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  position: absolute;
  background-size: cover;
  height: calc(100% - 240px);
  background-position: center;
  background-repeat: no-repeat;
}
.can-help.can-help-home-1 .can-help-background:after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(4, 33, 63, 0.8);
}
@media (min-width: 1200px) {
  .can-help.can-help-home-1 .can-help__content .title {
    max-width: 614px;
  }
}
@media (min-width: 1200px) {
  .can-help.can-help-home-1 .can-help__content .description {
    max-width: 550px;
  }
}
.can-help.can-help-home-1 .can-help__content .theme-btn:hover .color-white,
.can-help.can-help-home-1 .can-help__content .theme-btn:focus .color-white {
  color: #ef0548 !important;
}
.can-help.can-help-home-1 .contact-form {
  -webkit-box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
  box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
}
@media (min-width: 1200px) {
  .can-help__content {
    max-width: 696px;
  }
}
@media (max-width: 1199px) {
  .can-help__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .can-help__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .can-help__content .title {
    font-size: 32px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  .can-help__content .title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (min-width: 1200px) {
  .can-help__content .description {
    max-width: 666px;
  }
}
.can-help__content .help-text a {
  display: block;
  color: #4069ff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}
@media (max-width: 991px) {
  .can-help__content .help-text a {
    font-size: 18px;
    line-height: 24px;
  }
}
.can-help__content .help-text a span {
  font-weight: 400;
}
.can-help__content .help-text a:hover,
.can-help__content .help-text a:focus {
  color: #ff9c00;
}
.can-help__content-btn-group {
  gap: 30px;
}
.can-help__content-btn-group .theme-btn {
  gap: 15px;
  width: 100%;
  border-radius: 4px;
  padding: 20.5px 22px;
  background: transparent;
  border: 1.5px solid rgba(64, 105, 255, 0.3);
}
.can-help__content-btn-group .theme-btn:after {
  content: inherit;
}
.can-help__content-btn-group .theme-btn .icon {
  color: #4069ff;
}
.can-help__content-btn-group .theme-btn .icon i {
  margin: 0;
  font-size: 50px;
}
.can-help__content-btn-group .theme-btn .text span {
  font-size: 15px;
  line-height: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.can-help__content-btn-group .theme-btn .text h5 {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.can-help__content-btn-group .theme-btn:hover .text span,
.can-help__content-btn-group .theme-btn:hover .text h5,
.can-help__content-btn-group .theme-btn:focus .text span,
.can-help__content-btn-group .theme-btn:focus .text h5 {
  color: #4069ff !important;
}
.can-help .contact-form {
  background: #fff;
  border-radius: 4px;
}
.can-help .contact-form__header .sub-title {
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 991px) {
  .can-help .contact-form__header .title {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .can-help .contact-form__header .title {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .can-help .contact-form__header .title {
    font-size: 26px;
    line-height: 38px;
  }
}
.can-help .contact-form .single-personal-info {
  margin-bottom: 20px;
}
.can-help .contact-form input,
.can-help .contact-form textarea {
  width: 100%;
  outline: none;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  max-width: 100%;
  font-weight: 400;
  line-height: 26px;
  border-radius: 4px;
  padding: 10.5px 20px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1.5px solid rgba(14, 30, 54, 0.2);
}
.can-help .contact-form input::-webkit-input-placeholder,
.can-help .contact-form textarea::-webkit-input-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.can-help .contact-form input::-moz-placeholder,
.can-help .contact-form textarea::-moz-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.can-help .contact-form input:-ms-input-placeholder,
.can-help .contact-form textarea:-ms-input-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.can-help .contact-form input::-ms-input-placeholder,
.can-help .contact-form textarea::-ms-input-placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.can-help .contact-form input::placeholder,
.can-help .contact-form textarea::placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.can-help .contact-form textarea {
  resize: none;
  height: 120px;
}
.can-help .contact-form .theme-btn {
  margin-top: -8px;
}

.progress-bar__wrapper .single-progress-bar {
  position: relative;
}
.progress-bar__wrapper .single-progress-bar:last-child {
  margin-bottom: 0;
}
.progress-bar__wrapper .single-progress-bar .progress {
  height: 10px;
  border-radius: 30px;
}
.progress-bar__wrapper .single-progress-bar .progress-bar {
  width: 0;
  border-radius: 30px;
  -webkit-animation: progress 1.5s ease-in-out forwards;
  animation: progress 1.5s ease-in-out forwards;
}
.progress-bar__wrapper .single-progress-bar .progress-bar .placeholder {
  z-index: 1;
  width: 40px;
  height: 40px;
  bottom: 6px;
  color: #4069ff;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  margin-left: -40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../images/comment.png);
  opacity: 0;
  -webkit-animation: show 0.35s forwards ease-in-out 0.5s;
  animation: show 0.35s forwards ease-in-out 0.5s;
}
.progress-bar__wrapper .single-progress-bar .progress-bar .placeholder:after {
  top: 4px;
  right: 2px;
  z-index: -1;
  width: 36px;
  height: 20px;
  content: "";
  background: #fff;
  position: absolute;
  border-radius: 7px;
}

.can-help-overly .container {
  z-index: 1;
  position: relative;
}
.can-help-overly .container:after {
  z-index: -1;
  content: "";
  top: 266px;
  left: -928px;
  height: 1117px;
  width: 2552.29px;
  position: absolute;
  background-size: auto;
  /* background-image: url(assets/img/home/can-overly-home.png); */
}

.our-team-home-1 .container:after {
  content: inherit;
}

.our-portfolio-home {
  z-index: 1;
  position: relative;
}
.our-portfolio-home:after {
  z-index: -1;
  top: -190px;
  left: -497px;
  content: "";
  height: 702px;
  opacity: 0.4;
  width: 2479.27px;
  position: absolute;
  background-size: auto;
  /* background-image: url(assets/img/home/our-portfolio-home-overly.png); */
}
.our-portfolio-home .mb-minus-30 {
  margin-bottom: -30px;
}
@media (max-width: 1199px) {
  .our-portfolio-home__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .our-portfolio-home__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .our-portfolio-home__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .our-portfolio-home__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.our-portfolio-home__item {
  -webkit-box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
  box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
}
.our-portfolio-home__item .featured-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.our-portfolio-home__item .content .left {
  padding: 25.5px 30px;
}
.our-portfolio-home__item .content .left .post-author a {
  color: #ef0548;
  font-size: 12px;
  line-height: 18px;
}
.our-portfolio-home__item .content .btn-link-share .theme-btn {
  gap: 20px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 17px 30.5px;
  background: #f8dde4;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-size: cover;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-repeat: no-repeat;
}
.our-portfolio-home__item .content .btn-link-share .theme-btn:hover,
.our-portfolio-home__item .content .btn-link-share .theme-btn:focus {
  background-color: #ef0548;
  color: #fff !important;
  /* background-image: url(assets/img/home/theme-btn-overly-hover.png) !important; */
}
.our-portfolio-home__item .content .btn-link-share .theme-btn i {
  margin: 0;
  font-size: 19px;
}
.our-portfolio-home__item:hover .featured-thumb img,
.our-portfolio-home__item:focus .featured-thumb img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.our-portfolio-home .theme-btn:after {
  content: inherit;
}

.planning-success {
  z-index: 1;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.planning-success:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  position: absolute;
  background-color: rgba(4, 33, 63, 0.8);
}
.planning-success__content {
  max-width: 635px;
}
@media (max-width: 1199px) {
  .planning-success__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .planning-success__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .planning-success__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .planning-success__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.planning-success__video {
  position: relative;
}
.planning-success__video:after {
  padding: 0;
  width: 250px;
  z-index: -2;
  left: 25px;
  top: -306px;
  content: "";
  height: 687px;
  position: absolute;
  -webkit-transform: rotate(-13.13deg);
  transform: rotate(-13.13deg);
  background: rgba(255, 255, 255, 0.5);
}
@media (max-width: 575px) {
  .planning-success__video:after {
    content: inherit;
  }
}
.planning-success__video .popup-video {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 80px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0px 20px rgba(255, 255, 255, 0.1);
  -webkit-animation: playVideo5 3s linear infinite;
  animation: playVideo5 3s linear infinite;
}
.planning-success__video .popup-video i {
  margin: 0;
  font-size: 27px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #ef0548 !important;
}
.planning-success__video .popup-video i:hover {
  background: transparent;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}
@media (max-width: 767px) {
  .planning-success__video .popup-video {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }
}

.financial .mb-minus-30 {
  margin-bottom: -30px;
}
.financial__item {
  gap: 30px;
  background-size: cover;
  padding: 38.5px 30px 38.5px;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
  box-shadow: 0px 10px 30px rgba(4, 33, 63, 0.05);
}
.financial__item .icon {
  font-size: 45px;
}
.financial__item .icon img {
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.our-company-financial .overly .container {
  z-index: 1;
  position: relative;
}
.our-company-financial .overly .container:after {
  top: 259px;
  content: "";
  z-index: -1;
  left: -450px;
  height: 738px;
  width: 2314.14px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/home/our-company-financial-overly.svg); */
}
.our-company-financial .our-company .container:after {
  content: inherit;
}

.work-process .mb-minus-30 {
  margin-bottom: -30px;
}
.work-process.work-process-home {
  padding-top: 220px;
}
@media (max-width: 1199px) {
  .work-process.work-process-home {
    padding-top: 210px;
  }
}
@media (max-width: 991px) {
  .work-process.work-process-home {
    padding-top: 290px;
  }
}
@media (max-width: 575px) {
  .work-process.work-process-home {
    padding-top: 260px;
  }
}
.work-process.work-process-home .container:after {
  content: inherit;
}
.work-process .container {
  z-index: 1;
  position: relative;
}
.work-process .container:after {
  top: 64px;
  left: 35px;
  z-index: -1;
  content: "";
  width: 1200px;
  height: 411.79px;
  position: absolute;
  /* background-image: url(assets/img/home-2/work-process-bg.png); */
}
.work-process .no_bullet .work-process__item-two:after {
  content: inherit;
}
.work-process__item {
  height: calc(100% - 70px);
}
.work-process__item.work-process__item-two {
  height: inherit;
  position: relative;
}
.work-process__item.work-process__item-two:after {
  right: -22px;
  color: #ef0548;
  font-size: 18px;
  content: "\f178";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: calc(50% - 13px);
}
.work-process__item.work-process__item-two .icon {
  width: 100px;
  height: 100px;
  background: #ef0548;
  position: inherit;
  margin-bottom: 0;
  line-height: 100px;
}
.work-process__item.work-process__item-two .text {
  padding: 40px 30px;
}
.work-process__item.work-process__item-two .theme-btn {
  margin-top: 0;
  background: #f8dde4;
  width: calc(100% - 100px);
}
.work-process__item.work-process__item-two .theme-btn:hover,
.work-process__item.work-process__item-two .theme-btn:focus {
  background: #f8dde4;
}
.work-process__item .icon {
  width: 80px;
  height: 80px;
  color: #fff;
  font-size: 50px;
  line-height: 80px;
  position: relative;
  background: #ff9c00;
  margin-bottom: -60px;
}
.work-process__item .text {
  height: 100%;
  background: #fff;
  padding: 100px 28px 20px 28px;
  -webkit-box-shadow: 0px 4px 25px rgba(14, 10, 1, 0.05);
  box-shadow: 0px 4px 25px rgba(14, 10, 1, 0.05);
}
.work-process__item .theme-btn {
  display: block;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
}
.work-process__item .theme-btn::after {
  content: inherit;
}
.work-process__item .theme-btn:hover,
.work-process__item .theme-btn:focus {
  cursor: inherit;
  background: #0e0a01;
}

.our-team.our-team-home-2 {
  z-index: 1;
  position: relative;
}
.our-team.our-team-home-2:after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  mix-blend-mode: screen;
  background-size: cover;
  background-position: center;
  /* background-image: url(assets/img/home-2/our-member-bg.png); */
}
.our-team.our-team-home-2 .container:after {
  content: inherit;
}
.our-team.our-porfolio {
  z-index: 1;
  position: relative;
}
.our-team.our-porfolio:after {
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 638px;
  overflow: hidden;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f3f3f2;
  /* background-image: url(assets/img/portfolio/our-portfolio-bg.png); */
}
@media (max-width: 991px) {
  .our-team.our-porfolio:after {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .our-team.our-porfolio:after {
    height: 400px;
  }
}
@media (max-width: 575px) {
  .our-team.our-porfolio:after {
    height: 350px;
  }
}
.our-team.our-porfolio .container:after {
  content: inherit;
}
.our-team.our-porfolio .our-team__content {
  max-width: 737px;
}
.our-team.our-porfolio .our-project__item {
  height: 489px;
}
@media (max-width: 1199px) {
  .our-team.our-porfolio .our-project__item {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .our-team.our-porfolio .our-project__item {
    height: 360px;
  }
}
.our-team.our-porfolio .our-project__item .content {
  bottom: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.our-team.our-porfolio .our-project__item .content .theme-btn {
  background: #fff5e6;
}
.our-team.our-porfolio .our-project__item .content .theme-btn::after {
  background: #4069ff;
}
.our-team.our-porfolio .our-project__item .content .theme-btn i {
  width: 50px;
  height: 50px;
  font-size: 12px;
  color: #ff9c00;
  line-height: 50px;
  text-align: center;
  border-radius: 500px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 156, 0, 0.1);
}
.our-team.our-porfolio .our-project__item .content .theme-btn:hover i,
.our-team.our-porfolio .our-project__item .content .theme-btn:focus i {
  color: #fff;
  background: #ff9c00;
}

.circle-chart__wrapper {
  gap: 15px;
  max-width: 516px;
}
.circle-chart__item .chart-wrapper {
  gap: 20px;
  width: 100%;
  height: 100%;
  width: 80px;
  height: 80px;
  color: #000;
  font-size: 18px;
  min-width: 80px;
  position: relative;
  text-align: center;
  line-height: 80px;
  font-family: "Poppins", sans-serif;
}
.circle-chart__item .title {
  max-width: 117px;
  margin-left: 20px;
}
.circle-chart__item canvas {
  top: 0;
  left: 0;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 992px) {
  .about-us__content {
    max-width: 606px;
  }
}
@media (min-width: 992px) {
  .about-us__content .description {
    max-width: 570px;
  }
}
@media (max-width: 1199px) {
  .about-us__content .title:not(h6) {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .about-us__content .title:not(h6) {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .about-us__content .title:not(h6) {
    font-size: 32px;
    line-height: 46px;
  }
}
@media (max-width: 575px) {
  .about-us__content .title:not(h6) {
    font-size: 28px;
    line-height: 42px;
  }
}
.about-us__content .title:not(h6) span {
  font-weight: 300;
}
.about-us__media {
  max-width: 666px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  margin-bottom: 180px;
}
@media (max-width: 1399px) {
  .about-us__media {
    margin-bottom: 170px;
  }
}
@media (max-width: 550px) {
  .about-us__media {
    margin-bottom: 150px;
  }
}

.expert-team {
  right: 0;
  z-index: 1;
  overflow: hidden;
  max-width: 202px;
  position: absolute;
  padding: 40px 18px;
  background-repeat: no-repeat;
}
@media (max-width: 1399px) {
  .expert-team {
    padding: 30px 10px;
  }
}
@media (max-width: 550px) {
  .expert-team {
    padding: 25px 10px;
  }
}
.expert-team-one {
  -webkit-animation: move-bottom 3s linear infinite;
  animation: move-bottom 3s linear infinite;
}
.expert-team-two {
  left: 0;
  bottom: -126px;
  max-width: 318px;
  padding: 77px 45px;
  border-radius: 50px 0px 0px 50px;
}
@media (max-width: 1399px) {
  .expert-team-two {
    bottom: -60px;
    max-width: 270px;
    padding: 50px 10px;
  }
}
@media (max-width: 550px) {
  .expert-team-two {
    max-width: 240px;
    padding: 30px 10px;
  }
}
@media (max-width: 480px) {
  .expert-team-two {
    max-width: 150px;
    padding: 28px 10px;
  }
}
.expert-team-two:after {
  background: rgba(14, 10, 1, 0.8) !important;
}
.expert-team-three {
  z-index: -1;
  bottom: -180px;
  max-width: 318px;
  padding: 51px 75px 30px;
  border-radius: 0px 0px 50px 50px;
}
@media (max-width: 1399px) {
  .expert-team-three {
    max-width: 279px;
    bottom: -170px;
    padding: 50px 35px;
  }
}
@media (max-width: 550px) {
  .expert-team-three {
    bottom: -150px;
    max-width: 240px;
    padding: 30px 20px;
  }
}
@media (max-width: 480px) {
  .expert-team-three {
    max-width: 150px;
    padding: 28px 10px;
  }
}
.expert-team-three:after {
  background: rgba(14, 10, 1, 0.8) !important;
}
.expert-team:after {
  top: 0;
  left: 0;
  content: "";
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 156, 0, 0.9);
}
.expert-team .number {
  font-size: 48px;
  line-height: 58px;
}
@media (max-width: 1399px) {
  .expert-team .number {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 1399px) {
  .expert-team .number {
    font-size: 35px;
  }
}
@media (max-width: 480px) {
  .expert-team .number {
    font-size: 25px;
  }
}
.expert-team .title {
  max-width: 168px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  .expert-team .title {
    font-size: 14px;
    max-width: 138px;
  }
}

.global-country {
  z-index: 9999999999;
}

.blog-news {
  background-position: 41% 75%;
}
.blog-news .mb-minus-30 {
  margin-bottom: -30px;
}
@media (max-width: 1199px) {
  .blog-news__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .blog-news__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .blog-news__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .blog-news__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.blog-news__content .title span {
  font-weight: 300;
}

.delivery {
  z-index: 2;
  position: relative;
  margin-bottom: -255px;
}
@media (max-width: 1300px) {
  .delivery {
    margin-bottom: -230px;
  }
}
@media (max-width: 1199px) {
  .delivery {
    margin-bottom: -200px;
  }
}
@media (max-width: 991px) {
  .delivery {
    margin-bottom: -140px;
  }
}
@media (max-width: 767px) {
  .delivery {
    margin-bottom: -100px;
  }
}
@media (max-width: 480px) {
  .delivery {
    margin-bottom: -50px;
  }
}
.delivery__content {
  max-width: 617px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1199px) {
  .delivery__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .delivery__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .delivery__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .delivery__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.delivery__content .title span {
  font-weight: 300;
}
.delivery__media {
  position: relative;
}
.delivery__media-theme-btn-wrapper {
  bottom: 60px;
  z-index: 1;
  position: absolute;
  left: calc(50% - 50px);
}
@media (max-width: 991px) {
  .delivery__media-theme-btn-wrapper {
    bottom: 20px;
    left: calc(50% - 35px);
  }
}
@media (max-width: 575px) {
  .delivery__media-theme-btn-wrapper {
    bottom: 10px;
    left: calc(50% - 25px);
  }
}
.delivery__media-theme-btn-wrapper:after {
  z-index: -1;
  content: "";
  width: 433px;
  height: 433px;
  bottom: -380px;
  position: absolute;
  border-radius: 500px;
  left: calc(50% - 216px);
  background: rgba(64, 105, 255, 0.9);
}
@media (max-width: 575px) {
  .delivery__media-theme-btn-wrapper:after {
    width: 300px;
    height: 300px;
    bottom: -280px;
    left: calc(50% - 150px);
  }
}
.delivery__media-theme-btn-wrapper .popup-video {
  width: 85.71px;
  height: 85.71px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4069ff;
  font-size: 27px;
  line-height: 85px;
  display: block;
  text-align: center;
  background: #fff;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: playscale 3s linear infinite;
  animation: playscale 3s linear infinite;
}
@media (max-width: 991px) {
  .delivery__media-theme-btn-wrapper .popup-video {
    width: 60px;
    height: 60px;
    font-size: 22px;
    line-height: 60px;
  }
}
@media (max-width: 575px) {
  .delivery__media-theme-btn-wrapper .popup-video {
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
  }
}
@media (max-width: 575px) {
  .delivery__media-theme-btn-wrapper .popup-video img {
    max-width: 11px;
  }
}
.delivery__media-theme-btn-wrapper .popup-video:after {
  content: "";
  z-index: 1;
  top: -5px;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 500px;
  left: calc(50% - 50px);
  border: 1px solid #fff;
}
@media (max-width: 991px) {
  .delivery__media-theme-btn-wrapper .popup-video:after {
    top: -5px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    left: calc(50% - 35px);
  }
}
@media (max-width: 575px) {
  .delivery__media-theme-btn-wrapper .popup-video:after {
    width: 50px;
    height: 50px;
    line-height: 50px;
    left: calc(50% - 25px);
  }
}
.delivery__media-theme-btn-wrapper-1:after {
  display: none;
}
.delivery__wrapper {
  z-index: 1;
  position: relative;
}
.delivery__wrapper:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(64, 105, 255, 0.7);
  -webkit-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease ease-in-out;
}

.company-skill-home-3 .container:after {
  content: inherit;
}
.company-skill-home-3 .popup-video {
  top: 17px;
  width: 85px;
  z-index: 1;
  left: 307px;
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4069ff;
  font-size: 27px;
  line-height: 85px;
  position: absolute;
  text-align: center;
  background: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 0px 17px #4069ff;
  box-shadow: 0 0 0px 17px #4069ff;
  -webkit-animation: playVideo3 3s linear infinite;
  animation: playVideo3 3s linear infinite;
}
@media (max-width: 1299px) {
  .company-skill-home-3 .popup-video {
    left: 235px;
  }
}
@media (max-width: 1199px) {
  .company-skill-home-3 .popup-video {
    left: 185px;
    width: 70px;
    height: 70px;
    font-size: 22px;
    line-height: 70px;
  }
}
@media (max-width: 991px) {
  .company-skill-home-3 .popup-video {
    left: 44%;
  }
}

.competitive-edge {
  margin-top: -130px;
}
.competitive-edge.competitive-edge_2 {
  margin-top: -100px;
}
@media (max-width: 1399px) {
  .competitive-edge {
    margin-top: -85px;
  }
}
.competitive-edge .mb-minus-30 {
  margin-bottom: -30px;
}
.competitive-edge__item {
  gap: 20px;
  margin-bottom: 30px;
  background: #f1f2f7;
  padding: 32.5px 10px 34px 30px;
  border: 1px solid rgba(64, 105, 255, 0.2);
}
@media (max-width: 480px) {
  .competitive-edge__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: start !important;
    padding: 25px;
  }
}
.competitive-edge__item.competitive-edge__item_2 {
  border: none;
  position: relative;
  padding: 35px;
  border-radius: 4px;
  z-index: 1;
}
.competitive-edge__item.competitive-edge__item_2 .icon {
  padding: 20px;
  border: 2px solid #e2e9ff;
  border-radius: 4px;
}
.competitive-edge__item.competitive-edge__item_2::after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  content: "";
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(64, 105, 255, 0.96)),
    color-stop(190.8%, rgba(64, 105, 255, 0.7))
  );
  background: linear-gradient(
    270deg,
    rgba(64, 105, 255, 0.96) 0%,
    rgba(64, 105, 255, 0.7) 190.8%
  );
  border-radius: 6px;
}
.competitive-edge__item-video {
  gap: 10px;
  border: none;
  padding: 33px 45px 39px 34px;
  background: linear-gradient(106.81deg, #4069ff 0%, #0125a9 100%);
}
@media (max-width: 480px) {
  .competitive-edge__item-video {
    padding: 25px 30px 25px 25px;
    -webkit-box-align: inherit !important;
    -ms-flex-align: inherit !important;
    align-items: inherit !important;
  }
}
.competitive-edge__item-video .left {
  max-width: 284px;
}
.competitive-edge__item-video .arrow-right {
  gap: 10px;
}
.competitive-edge__item-video .arrow-right span {
  color: #fff;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  line-height: 26px;
}
.competitive-edge__item-video .arrow-right span i {
  width: auto;
  height: auto;
  color: #fff;
  min-width: auto;
  margin-left: 15px;
  display: inline-block;
  background: transparent !important;
}
.competitive-edge__item-video .arrow-right span img {
  margin-left: 15px;
}
.competitive-edge__item-video .arrow-right .popup-video {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 50px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 500px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
  box-shadow: 0 0 0px 10px rgba(64, 105, 255, 0.2);
  -webkit-animation: playVideo4 3s linear infinite;
  animation: playVideo4 3s linear infinite;
}
.competitive-edge__item-video .arrow-right .popup-video i {
  margin: 0;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #4069ff !important;
}
.competitive-edge__item-video .arrow-right .popup-video i:hover {
  background: transparent;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}
@media (max-width: 575px) {
  .competitive-edge__item-video .arrow-right .popup-video {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}
.competitive-edge__item .left {
  gap: 20px;
}
.competitive-edge__item .icon {
  font-size: 50px;
}
.competitive-edge__item .arrow-right i {
  width: 50px;
  height: 50px;
  color: #4069ff;
  font-size: 12px;
  min-width: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
  border-radius: 500px;
  background: rgba(64, 105, 255, 0.14);
}
.competitive-edge__item .arrow-right i:hover,
.competitive-edge__item .arrow-right i:focus {
  color: #fff;
  background: #4069ff;
}

/**competitive-edge**/
.fauture__element {
  padding: 100px;
}
@media (max-width: 1200px) {
  .fauture__element {
    padding: 40px;
  }
}
@media (max-width: 500px) {
  .fauture__element {
    padding: 20px;
  }
}
.fauture__element .fauture__widget {
  background-color: #fff;
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.fauture__element .fauture__widget .fauture__icons {
  background: #fff5e6;
  padding: 15px;
}
.fauture__element .fauture__widget .fauture__icons .icon {
  padding-bottom: 15px;
  border-bottom: 1px solid #f0e8d7;
}
.fauture__element .fauture__widget .fauture__icons .icon i {
  font-size: 45px;
  color: #ff9c00 !important;
}
.fauture__element .fauture__widget .fauture__icons h4 {
  padding-top: 15px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  color: #f0e8d7;
}
.fauture__element .fauture__widget .fauture__content {
  padding-left: 20px;
}
.fauture__element .fauture__widget .fauture__content h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0e1e36;
  margin-bottom: 10px;
}
.fauture__element .staff__widget {
  text-align: center;
  background-color: #ff9c00;
  padding: 20px 0px;
}
.fauture__element .staff__widget span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
}
.fauture__element .staff__widget span a {
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.fauture__element .staff__widget span a:hover {
  color: #04213f;
}

/**team-wrapper start**/
.single_team_widget {
  text-align: center;
  border-radius: 6px;
  padding: 40px 10px;
  background-color: #fff;
}
.single_team_widget .man_img img {
  height: 150px;
  width: auto;
  border-radius: 50%;
}
.single_team_widget .author_name {
  margin-top: 30px;
}
.single_team_widget .author_name h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 5px;
}
.single_team_widget .author_name p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #1b3c54;
}
.single_team_widget .social_link {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.single_team_widget .social_link ul li {
  float: left;
  display: block;
}
.single_team_widget .social_link ul li a {
  margin: 0px 3px;
  padding: 0;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ff9c00;
  font-size: 15px;
  line-height: 32px;
  border-radius: 50%;
  background: rgba(255, 156, 0, 0.1);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.single_team_widget .social_link ul li a:hover {
  background: #ff9c00;
  color: #fff;
}

/**Our Testimonials**/
.slingle_agent .agent_author {
  margin-right: 30px;
}
.slingle_agent .agent_author .author {
  height: 120px;
  width: 120px;
}
.slingle_agent .agent_author .author img {
  height: 120px;
}
.slingle_agent .agent_author .quote {
  text-align: center;
  margin-top: -20px;
}
.slingle_agent .agent_author .quote i {
  font-size: 30px;
  line-height: 45px;
  color: #ff9c00;
}
.slingle_agent .agent_author .company_logo img {
  text-align: center !important;
  width: auto;
  height: auto;
}
.slingle_agent .agent_content h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: #0e1e36;
  margin-bottom: 5px;
}
.slingle_agent .agent_content h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: #ff9c00;
  margin-bottom: 15px;
}

.slick-dots {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .slick-dots {
    bottom: 30px;
  }
}
.slick-dots li {
  margin: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  border-radius: 500px;
  background: transparent;
  border: 2px solid #ff9c00;
}
@media (max-width: 1199px) {
  .slick-dots li {
    width: 16px;
    height: 16px;
  }
}
.slick-dots li.slick-active:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.slick-dots li:after {
  content: "";
  width: 8px;
  height: 8px;
  line-height: 20px;
  position: absolute;
  background: #ff9c00;
  left: calc(50% - 4px);
  border-radius: 500px;
  top: calc(50% - 4px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}
@media (max-width: 1199px) {
  .slick-dots li:after {
    width: 6px;
    height: 6px;
    top: calc(50% - 3px);
    left: calc(50% - 3px);
  }
}
.slick-dots li button {
  display: none;
}

/**Our Blog start**/
.blog_wrapper {
  position: relative;
  z-index: -1;
}
.blog_wrapper::after {
  z-index: -1;
  content: "";
  width: 100%;
  height: 50%;
  /* background-image: url(assets/img/home-5/bg_04.png); */
  background-position: 0 0;
  top: 0;
  left: 0;
  position: absolute;
  display: inline-block;
}
.blog_wrapper .single_blog {
  padding: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
  box-shadow: 0px 2px 32px rgba(14, 10, 1, 0.05);
}
.blog_wrapper .single_blog .author_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.blog_wrapper .single_blog .author_info .author_con {
  margin-left: 20px;
}
.blog_wrapper .single_blog .author_info .author_con li {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #1b3c54;
  margin: 5px 0px;
}
.blog_wrapper .single_blog .author_info .author_con li i {
  color: #ff9c00;
  margin-right: 10px;
}
.blog_wrapper .single_blog h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #0e0a01;
  margin-bottom: 10px;
}
.blog_wrapper .single_blog p {
  color: #243342;
}
.blog_wrapper .single_blog .theme-btn.btn-transparent::after {
  background: #ff9c00 !important;
}
.blog_wrapper .single_blog .theme-btn.btn-transparent:hover {
  color: #fff;
  border: 1px solid #ff9c00;
}

/**portfolio_wrapper  start**/
.portfolio_wrapper {
  margin-bottom: -100px;
  z-index: 5;
}

.single_portfolio {
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.single_portfolio .single_img {
  overflow: hidden;
  position: relative;
}
.single_portfolio .single_img .cta_btn a {
  position: absolute;
  margin-top: -21%;
  left: -45%;
  bottom: 0%;
  padding: 20px;
  background: #ff9c00;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  z-index: 1;
  color: #ffffff;
}
.single_portfolio .single_img .cta_btn a i {
  margin-left: 15px;
}
@media (max-width: 900px) {
  .single_portfolio .single_img .cta_btn a {
    padding: 10px 20px;
  }
}
.single_portfolio .single_img img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 100%;
}
.single_portfolio .content h5 {
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ff9c00;
  margin-bottom: 10px;
}
.single_portfolio .content h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #0e0a01;
  position: relative;
  padding-bottom: 20px;
}
.single_portfolio .content h3::after {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 1;
  content: "";
  clear: both;
  height: 3px;
  width: 60%;
  background-color: #ff9c00;
  bottom: 0;
  left: 0;
  position: absolute;
}
.single_portfolio:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.single_portfolio:hover a {
  left: 0% !important;
}
.single_portfolio:hover h3::after {
  width: 30%;
}

/**fun-fact_wrapper start**/
.fun-fact_wrapper {
  position: relative;
  z-index: -1;
  padding-top: 200px;
  z-index: 1;
  position: relative;
}
.fun-fact_wrapper:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  -webkit-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease ease-in-out;
}
.error__content .text h1 {
  font-size: 48px;
  line-height: 60px;
}
@media (max-width: 991px) {
  .error__content .text h1 {
    font-size: 40px;
    line-height: 52px;
  }
}
@media (max-width: 767px) {
  .error__content .text h1 {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 575px) {
  .error__content .text h1 {
    font-size: 28px;
    line-height: 40px;
  }
}
.error__content .text .description {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.error__content .text .theme-btn:hover,
.error__content .text .theme-btn:focus {
  background: #4069ff;
}

.cta-banner {
  margin-bottom: -93px;
}
@media (max-width: 1199px) {
  .cta-banner {
    margin-bottom: -70px;
  }
}
@media (max-width: 991px) {
  .cta-banner {
    margin-bottom: -110px;
  }
}
@media (max-width: 767px) {
  .cta-banner {
    margin-bottom: -90px;
  }
}
@media (max-width: 506px) {
  .cta-banner {
    margin-bottom: -100px;
  }
}
@media (max-width: 365px) {
  .cta-banner {
    margin-bottom: -120px;
  }
}
.cta-banner__content {
  gap: 30px;
  z-index: 1;
  padding-top: 52px;
  position: relative;
  padding-bottom: 52px;
  background-color: #4069ff;
}
@media (max-width: 1199px) {
  .cta-banner__content {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.cta-banner__content:after {
  top: 110px;
  z-index: -1;
  content: "";
  right: 497px;
  width: 208.67px;
  height: 187.32px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url(../images/cta-arrow.png);
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-name: movebounce;
  animation-name: movebounce;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.cta-banner__content-text {
  max-width: 558px;
}
@media (max-width: 991px) {
  .cta-banner__content-text .title {
    font-size: 30px;
    line-height: 42px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .cta-banner__content-text .title {
    font-size: 30px;
    line-height: 42px;
    text-align: center;
  }
}
@media (max-width: 1199px) {
  .cta-banner__content .theme-btn {
    padding: 16px 32px;
  }
}

.why-choose-cta-banner {
  margin-bottom: -93px;
}
@media (max-width: 1199px) {
  .why-choose-cta-banner {
    margin-bottom: -70px;
  }
}
@media (max-width: 991px) {
  .why-choose-cta-banner {
    margin-bottom: -110px;
  }
}
@media (max-width: 767px) {
  .why-choose-cta-banner {
    margin-bottom: -90px;
  }
}
@media (max-width: 506px) {
  .why-choose-cta-banner {
    margin-bottom: -100px;
  }
}
@media (max-width: 365px) {
  .why-choose-cta-banner {
    margin-bottom: -120px;
  }
}
.why-choose-cta-banner .overly .container {
  z-index: 1;
  position: relative;
}
.why-choose-cta-banner .overly .container:after {
  top: -907px;
  content: "";
  z-index: -1;
  left: -1544px;
  width: 2751.74px;
  height: 1725.79px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  /* background-image: url(assets/img/table/why-choose-cta-banner-overly.png); */
}
.why-choose-cta-banner .cta-banner {
  z-index: 2;
  margin-bottom: 0;
}
.why-choose-cta-banner .cta-banner:after {
  content: inherit;
}
.why-choose-cta-banner ~ .footer-1 {
  z-index: 1;
  position: relative;
}

/* hero section */
.hero_wrapper {
  padding: 150px 0px 100px 0px;
}
.hero_wrapper .hero_content {
  max-width: 630px;
  margin-left: auto;
}

.dark {
  background: #000 !important;
}
.dark .header-1 .stop .main-header-wraper {
  background: #000 !important;
}
.dark .header-1 .main-header-wraper {
  background: #000 !important;
}
.dark .header-1 .main-header-wraper ul > li > a {
  color: #fff !important;
}
.dark .header-1 .main-header-wraper ul > li ul {
  background: #000 !important;
}
.dark
  header.header-1
  .main-header-wraper
  .header-right
  .header-search
  .search-toggle {
  color: #fff !important;
}
.dark header.header-1 .main-header-wraper .header-right .header-contact {
  color: #fff !important;
}
.dark header.header-1 .main-header-wraper .header-right .header-contact h5 {
  color: #fff !important;
}
.dark header.header-3 .top-header {
  background: #000 !important;
}
.dark header .header-1 .top-header,
.dark header .header-2 .top-header,
.dark header .header-3 .top-header,
.dark header .header-4 .top-header,
.dark header .header-5 .top-header {
  background: #000 !important;
}
.dark .title {
  color: #fff !important;
}
.dark .banner-home {
  background: #000 !important;
}
.dark .banner-home__media:after {
  top: 42px;
  content: "";
  z-index: -1;
  width: 841px;
  right: 747px;
  height: 208px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: none !important;
}
.dark .financial .financial__item .icon svg {
  fill: #fff !important;
}
.dark .financial .financial__item h6,
.dark .financial .financial__item p {
  color: #fff !important;
}
.dark .our-company span,
.dark .our-company h5,
.dark .our-company h2,
.dark .our-company p {
  color: #fff !important;
}
.dark .our-company .number {
  color: #fff !important;
}
.dark .our-company .client-feedback__item-header span {
  color: #ef0548 !important;
}
.dark .planning-success .popup-video {
  background: #000 !important;
}
.dark .planning-success::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.dark .why-choose p,
.dark .why-choose i,
.dark .why-choose a {
  color: #fff !important;
}
.dark .work-process.bg-dark_red {
  background: #000 !important;
}
.dark .work-process .work-process__item::after {
  right: -22px;
  color: #ffffff;
  font-size: 18px;
  content: "\f178";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: calc(50% - 13px);
}
.dark .work-process .work-process__item button {
  color: #000 !important;
}
.dark .work-process .work-process__item .text h6,
.dark .work-process .work-process__item .text p {
  color: #000 !important;
}
.dark .our-portfolio-home .content a,
.dark .our-portfolio-home .content p {
  color: #fff !important;
}
.dark .our-portfolio-home .content .btn-link-share a {
  color: #000 !important;
}
.dark .our-portfolio-home .our-portfolio-home__read-more a {
  border: 1px solid #fff !important;
  color: #fff;
}
.dark .our-team.bg-dark_red {
  background: #000 !important;
}
.dark .testimonial-item-three {
  background: #ef0548;
}
.dark .testimonial-item-three .testimonial__item-header .right i {
  color: rgba(255, 255, 255, 0.2);
}
.dark .testimonial-item-three .testimonial__item-header .left .meta .name,
.dark .testimonial-item-three .testimonial__item-header .left .meta .position {
  color: #fff !important;
}
.dark .testimonial-item-three .testimonial__item-footer .socail-link span {
  color: #fff !important;
}
.dark .testimonial-item-three .starts ul li span {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.dark .testimonial-item-three .description {
  color: #fff !important;
}
.dark .can-help-overly-home .can-help-background::after {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.8) !important;
}
.dark .can-help-overly-home .contact-form {
  background: #000 !important;
}
.dark .can-help-overly-home .contact-form input,
.dark .can-help-overly-home .contact-form textarea {
  color: #fff !important;
  border: 1px solid #fff;
  background: transparent !important;
}
.dark .can-help-overly-home .contact-form input::-webkit-input-placeholder,
.dark .can-help-overly-home .contact-form textarea::-webkit-input-placeholder {
  color: #666666;
}
.dark .can-help-overly-home .contact-form input::-moz-placeholder,
.dark .can-help-overly-home .contact-form textarea::-moz-placeholder {
  color: #666666;
}
.dark .can-help-overly-home .contact-form input:-ms-input-placeholder,
.dark .can-help-overly-home .contact-form textarea:-ms-input-placeholder {
  color: #666666;
}
.dark .can-help-overly-home .contact-form input::-ms-input-placeholder,
.dark .can-help-overly-home .contact-form textarea::-ms-input-placeholder {
  color: #666666;
}
.dark .can-help-overly-home .contact-form input::placeholder,
.dark .can-help-overly-home .contact-form textarea::placeholder {
  color: #666666;
}
.dark .footer-1 {
  background-image: none !important;
  background: #000 !important;
}
.dark .footer-1::after {
  display: none !important;
}
.dark .footer-1 .footer-top__box-wrapper::after {
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
}
.dark .about-us p {
  color: #fff !important;
}
.dark .similar-work {
  background: #000;
}
.dark .similar-work .similar-work__item .title {
  color: #4069ff !important;
}
.dark .number,
.dark .description {
  color: #fff !important;
}
.dark .our-team.our-porfolio .content::after {
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 638px;
  overflow: hidden;
  position: absolute;
  background: #ffffff;
}
.dark .our-team.our-porfolio .text h5 {
  color: #000 !important;
}
.dark .our-team.our-porfolio::after {
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 638px;
  overflow: hidden;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background: #000;
  /* background-image: url(assets/img/portfolio/our-portfolio-bg.png); */
}
.dark .our-team {
  background: #000 !important;
}
.dark .work-process .work-process__item button {
  color: #ffffff !important;
}
.dark .testimonial {
  background: #000 !important;
}
.dark .testimonial .testimonial__item {
  background: #ef0548 !important;
}
.dark .testimonial .testimonial__item .description p {
  color: #fff !important;
}
.dark .testimonial .testimonial-item-two {
  background: #ff9c00;
}
.dark .testimonial .testimonial-item-two .testimonial__item-header .right i {
  color: rgba(255, 255, 255, 0.2);
}
.dark
  .testimonial
  .testimonial-item-two
  .testimonial__item-header
  .left
  .meta
  .name,
.dark
  .testimonial
  .testimonial-item-two
  .testimonial__item-header
  .left
  .meta
  .position {
  color: #fff !important;
}
.dark .testimonial .testimonial-item-two .description {
  color: #fff !important;
}
.dark .chart {
  color: #ffffff !important;
}
.dark .banner p {
  color: #ffffff !important;
}
.dark .banner::after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 100%;
  z-index: -1;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 8, 8, 0.85)),
    color-stop(61.17%, rgba(63, 56, 56, 0))
  );
  background: linear-gradient(
    90deg,
    rgba(8, 8, 8, 0.85) 0%,
    rgba(63, 56, 56, 0) 61.17%
  );
}
.dark .counter-area__item {
  background: #000 !important;
}
.dark .competitive-edge__item {
  background: #000 !important;
}
.dark .bg-dark_white {
  background: #000 !important;
}
.dark .bg-dark_white .our-project__item .content,
.dark .bg-dark_white .our-project__item .text {
  background: #000 !important;
}
.dark .dark_header .main_wrapper {
  background: #000 !important;
}
.dark .section-title p,
.dark .section-title h2,
.dark .section-title h3,
.dark .section-title h1 {
  color: #fff !important;
}
.dark .banner__2::after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  height: 100%;
  z-index: -1;
  position: absolute;
  background: rgba(0, 0, 0, 0.6) !important;
}
.dark .competitive-edge__item.competitive-edge__item_2::after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  content: "";
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.96)),
    color-stop(190.8%, rgba(64, 105, 255, 0.7))
  );
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.96) 0%,
    rgba(64, 105, 255, 0.7) 190.8%
  );
  border-radius: 6px;
}
.dark .service_info > li {
  color: #fff !important;
}
.dark .service_info > li::after {
  content: "\f14a";
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  clear: both;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff !important;
}
.dark .area__item {
  background: #ffffff;
}
.dark .area__item .number,
.dark .area__item .description {
  color: #000000 !important;
}
.dark .author_name h5,
.dark .author_name p {
  color: #fff !important;
}
.dark .protfolio_wrapper {
  background: #000 !important;
}
.dark .protfolio_wrapper h4,
.dark .protfolio_wrapper p {
  color: #fff !important;
}
.dark .slider-item .testimonial-slider,
.dark .slider-item .testimonial__item {
  background: #ffffff;
}
.dark .slider-item .testimonial-slider h5,
.dark .slider-item .testimonial-slider p,
.dark .slider-item .testimonial__item h5,
.dark .slider-item .testimonial__item p {
  color: #000 !important;
}
.dark .client-brand {
  background: #000 !important;
}
.dark .delivery__wrapper::before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: rgba(9, 9, 9, 0.7);
}
.dark .delivery__wrapper .counter-area__item.counter-area__item-three {
  background-color: #000 !important;
}
.dark .delivery__wrapper .theme-btn {
  color: #000 !important;
}
.dark .can-help {
  background: #000 !important;
}
.dark .can-help .text span,
.dark .can-help .text h5 {
  color: #fff !important;
}
.dark .our-porfolio__slider__2 .our-project__item .content,
.dark .our-porfolio__slider__2 .our-project__item .text {
  background: #000 !important;
}
.dark .fauture__widget {
  background: #000 !important;
}
.dark .fauture__widget .fauture__content h4,
.dark .fauture__widget .fauture__content p {
  color: #fff !important;
}
.dark .fauture__widget .fauture__icons h4 {
  color: #000 !important;
}
.dark .about__wrapper h5,
.dark .about__wrapper p {
  color: #ffffff !important;
}
.dark .about__wrapper .number span,
.dark .about__wrapper .number h5 {
  color: #000 !important;
}
.dark .process__widget h4,
.dark .process__widget p {
  color: #ffffff !important;
}
.dark .portfolio_wrapper .section-title h2 {
  color: #000 !important;
}
.dark .fun-fact_wrapper .counter-area__item {
  background: transparent !important;
}
.dark .why-choose .accordion-item {
  color: #fff !important;
}
.dark .why-choose .accordion-item button {
  background-color: #ff9c00 !important;
}
.dark .team_wrapper {
  background-image: none !important;
}
.dark .testimonial_wrapper .slingle_agent h4,
.dark .testimonial_wrapper .slingle_agent h5,
.dark .testimonial_wrapper .slingle_agent p {
  color: #fff !important;
}

.footer-1 {
  padding-top: 213px;
  background-size: cover;
  background-position: center;
  background-color: #0e1e36;
  /* background-image: url("assets/img/footer/footer-bg.png"); */
}
.footer-1.footer_wrapper {
  z-index: -1;
  padding-top: 0px;
  background-size: cover;
  background-position: center;
  background-color: #0e1e36;
  /* background-image: url("assets/img/home-5/footer_bg.png"); */
  position: relative;
}
.footer-1.footer_wrapper:before {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(-29.09%, rgba(14, 10, 1, 0)),
    color-stop(42.92%, #0e0a01)
  );
  background: linear-gradient(
    180deg,
    rgba(14, 10, 1, 0) -29.09%,
    #0e0a01 42.92%
  );
}
.footer-1.footer_wrapper .footer_top_content {
  padding: 100px 0px 80px 0px;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
}
.footer-1.footer_wrapper .footer_top_content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 120%;
  color: #fff;
  margin-bottom: 15px;
}
.footer-1.footer_wrapper .footer_top_content p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  font-family: "Lato", sans-serif;
  color: #fff;
  max-width: 100%;
  width: 656px;
  margin: 0 auto;
}
.footer-1.footer_wrapper .footer_top_content a {
  margin-top: 40px;
}
.footer-1.footer_wrapper .contact-wrapper {
  background: #1e1b15;
}
.footer-1.footer_wrapper .contact-wrapper .wid-contact .icon i {
  color: #ff9c00;
}
.footer-1.footer_wrapper .contact-wrapper .social-profile li a {
  border: 1.5px solid rgba(255, 156, 0, 0.3);
  color: #fff;
}
.footer-1.footer_wrapper .contact-wrapper .social-profile li a:hover {
  background-color: #ff9c00;
}
.footer-1.footer_wrapper .single-footer-wid {
  padding-top: 80px;
}
.footer-1.footer_wrapper .single-footer-wid ul li:hover a {
  color: #ff9c00 !important;
}
@media (max-width: 991px) {
  .footer-1 {
    padding-top: 200px;
  }
}
@media (max-width: 767px) {
  .footer-1 {
    padding-top: 170px;
  }
}
.footer-1 .single-footer-wid {
  margin-bottom: 40px;
}
@media (min-width: 1381px) {
  .footer-1 .single-footer-wid.pl-50 {
    margin-right: -16px;
  }
}
@media (max-width: 1199px) {
  .footer-1 .single-footer-wid.pl-50,
  .footer-1 .single-footer-wid.pl-65 {
    padding: 0;
  }
}
.footer-1 .single-footer-wid ul {
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.footer-1 .single-footer-wid ul li a {
  color: #fff;
  display: block;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
}
.footer-1 .single-footer-wid ul li a:before {
  left: 0;
  /* content: "\f101"; */
  font-size: 12px;
  position: absolute;
  top: calc(50% - 7px);
  font-family: "Font Awesome 5 Pro";
}
.footer-1 .single-footer-wid ul li:hover a,
.footer-1 .single-footer-wid ul li:focus a {
  color: #4069ff;
}
.footer-1 .site_info_box {
  max-width: 432px;
}
.footer-1 .recent_post_widget .recent-post-list .single-recent-post {
  gap: 20px;
  overflow: hidden;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2
  .post-data
  span
  i {
  color: #ff9c00 !important;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2:hover
  .post-data
  h5,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2:hover
  .post-data
  span,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2:hover
  .post-data
  i,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2:focus
  .post-data
  h5,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2:focus
  .post-data
  span,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post.single-footer-wid_2:focus
  .post-data
  i {
  color: #ff9c00 !important;
}
.footer-1 .recent_post_widget .recent-post-list .single-recent-post:after {
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  border-radius: 5px;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
}
.footer-1 .recent_post_widget .recent-post-list .single-recent-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:last-child:after {
  content: inherit;
}
.footer-1 .recent_post_widget .recent-post-list .single-recent-post .thumb {
  width: 80.7px;
  height: 80.7px;
  overflow: hidden;
  min-width: 80.7px;
  background-color: #fff;
}
.footer-1 .recent_post_widget .recent-post-list .single-recent-post .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 768px) {
  .footer-1
    .recent_post_widget
    .recent-post-list
    .single-recent-post
    .post-data {
    max-width: 186px;
  }
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  h5 {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  span {
  font-size: 14px;
  line-height: 15px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  span
  i {
  color: #4069ff;
  font-size: 15px;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  h5 {
  font-size: 16px;
  line-height: 24px;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  h5:hover {
  color: #4069ff;
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .thumb
  img,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .thumb
  img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  h5,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  span,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  i,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  h5,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  span,
.footer-1
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  i {
  color: #4069ff !important;
}
@media (min-width: 992px) {
  .footer-1 .newsletter_widget {
    max-width: 550px;
    margin-left: auto;
  }
}
.footer-1 .newsletter_widget .title img {
  padding-right: 10px;
}
.footer-1 .newsletter_widget .newsletter_box form {
  height: 61px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media (max-width: 480px) {
  .footer-1 .newsletter_widget .newsletter_box form {
    height: 50px;
  }
}
.footer-1 .newsletter_widget .newsletter_box form input {
  width: 100%;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  border-radius: 2px;
  background: transparent;
  padding: 21px 200px 21px 20px;
  border: 1px solid rgba(64, 105, 255, 0.3);
}
@media (max-width: 480px) {
  .footer-1 .newsletter_widget .newsletter_box form input {
    font-size: 14px;
    padding: 16px 148px 16px 15px;
  }
}
.footer-1
  .newsletter_widget
  .newsletter_box
  form
  input::-webkit-input-placeholder {
  color: #fff;
}
.footer-1 .newsletter_widget .newsletter_box form input::-moz-placeholder {
  color: #fff;
}
.footer-1 .newsletter_widget .newsletter_box form input:-ms-input-placeholder {
  color: #fff;
}
.footer-1 .newsletter_widget .newsletter_box form input::-ms-input-placeholder {
  color: #fff;
}
.footer-1 .newsletter_widget .newsletter_box form input::placeholder {
  color: #fff;
}
.footer-1 .newsletter_widget .newsletter_box form button {
  border: 0;
  right: 9px;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  position: absolute;
  font-family: "Poppins", sans-serif;
  border-radius: 2px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 9.5px 18px;
  top: calc(50% - 21.5px);
  background-color: #4069ff;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 480px) {
  .footer-1 .newsletter_widget .newsletter_box form button {
    right: 5px;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 10px;
    top: calc(50% - 16px);
  }
}
.footer-1 .newsletter_widget .newsletter_box form button i {
  font-size: 21px;
}
@media (max-width: 480px) {
  .footer-1 .newsletter_widget .newsletter_box form button i {
    font-size: 13px;
    margin-left: 5px;
  }
}
.footer-1 .newsletter_widget .newsletter_box form button:hover {
  color: #fff;
  background-color: #ff9c00;
}
.footer-1 .social-profile ul {
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (max-width: 575px) {
  .footer-1 .social-profile ul {
    padding: 5px 0;
  }
}
.footer-1 .social-profile ul li a {
  padding: 0;
  width: 36px;
  height: 36px;
  color: #fff;
  display: block;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  background-color: transparent;
  border-radius: 500px;
  -webkit-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease ease-in-out;
  border: 1.5px solid rgba(226, 233, 255, 0.15);
}
.footer-1 .social-profile ul li a::before {
  content: inherit;
}
.footer-1 .social-profile ul li a:hover,
.footer-1 .social-profile ul li a:focus {
  color: #fff;
  background-color: #4069ff;
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.footer-1 .contact-wrapper {
  background-color: #112441;
}
.footer-1 .wid-contact {
  position: relative;
}
.footer-1 .wid-contact:after {
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  border-radius: 5px;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
}
.footer-1 .wid-contact ul {
  gap: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-1 .wid-contact ul li {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-1 .wid-contact ul li .icon i {
  color: #4069ff;
  font-size: 15px;
}
.footer-1 .footer-bottom-content {
  gap: 15px;
  padding: 27px 0;
  position: relative;
}
.footer-1 .footer-bottom-content:after {
  top: 0;
  width: 100%;
  height: 2px;
  content: "";
  border-radius: 5px;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
}
.footer-1 .footer-bottom-content .coppyright {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
}
.footer-1 .footer-bottom-content .coppyright.coppyright-2 a {
  color: #ff9c00;
  font-weight: 500;
}
.footer-1 .footer-bottom-content .coppyright.coppyright-2 a + a {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
.footer-1 .footer-bottom-content .coppyright.coppyright-2 a:hover,
.footer-1 .footer-bottom-content .coppyright.coppyright-2 a:focus {
  color: #4069ff;
}
.footer-1 .footer-bottom-content .coppyright a {
  color: #4069ff;
  font-weight: 500;
}
.footer-1 .footer-bottom-content .coppyright a + a {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
.footer-1 .footer-bottom-content .coppyright a:hover,
.footer-1 .footer-bottom-content .coppyright a:focus {
  color: #ff9c00;
}
.footer-1 .footer-bottom-list.footer-bottom-list_2 li a {
  color: #ff9c00;
}
.footer-1 .footer-bottom-list.footer-bottom-list_2 li:hover a,
.footer-1 .footer-bottom-list.footer-bottom-list_2 li:focus a {
  color: #4069ff;
}
.footer-1 .footer-bottom-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 41.5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footer-1 .footer-bottom-list ul li.no_bullet a:before {
  content: inherit;
}
.footer-1 .footer-bottom-list ul li a {
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}
.footer-1 .footer-bottom-list ul li a:before {
  content: "";
  width: 1.5px;
  height: 100%;
  right: -21.75px;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
}
.footer-1 .footer-bottom-list ul li:hover a,
.footer-1 .footer-bottom-list ul li:focus a {
  color: #4069ff;
}
.footer-1 #map {
  width: 100%;
  height: 245px;
}

.footer-2 {
  z-index: 1;
  position: relative;
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1199px) {
  .footer-2 {
    padding-top: 80px;
  }
}
@media (max-width: 991px) {
  .footer-2 {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .footer-2 {
    padding-top: 50px;
  }
}
.footer-2:after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(-29.09%, rgba(14, 10, 1, 0)),
    color-stop(42.92%, #0e0a01)
  );
  background: linear-gradient(
    180deg,
    rgba(14, 10, 1, 0) -29.09%,
    #0e0a01 42.92%
  );
}
.footer-2 .footer-top__cta-content {
  max-width: 781px;
}
.footer-2 .footer-top__cta-content-wrapper {
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
}
.footer-2 .footer-top__cta-content h2 {
  font-size: 64px;
  line-height: 78px;
  font-weight: 700;
}
@media (max-width: 1199px) {
  .footer-2 .footer-top__cta-content h2 {
    font-size: 54px;
    line-height: 68px;
  }
}
@media (max-width: 991px) {
  .footer-2 .footer-top__cta-content h2 {
    font-size: 40px;
    line-height: 54px;
  }
}
@media (max-width: 380px) {
  .footer-2 .footer-top__cta-content h2 {
    font-size: 35px;
    line-height: 49px;
  }
}
.footer-2 .footer-top__cta-content .description {
  max-width: 694px;
}
.footer-2 .footer-top__cta-content .description p {
  font-size: 18px;
  line-height: 30px;
}
.footer-2 .newsletter_widget .newsletter_box.newsletter_box_2 {
  border: none !important;
  background: #1e1b15 !important;
  border-radius: 2px !important;
}
.footer-2 .newsletter_widget .newsletter_box form input {
  border: none;
  background: #1e1b15;
}
.footer-2 .newsletter_widget .newsletter_box form button.btn-yellow {
  background-color: #ff9c00;
}
.footer-2 .newsletter_widget .newsletter_box form button.btn-yellow:hover {
  background: #4069ff;
}
.footer-2 .contact-wrapper {
  background: #1e1b15;
}
.footer-2 .contact-wrapper .wid-contact ul li .icon i {
  color: #ff9c00;
}
.footer-2 .contact-wrapper .wid-contact ul li .icon i:hover,
.footer-2 .contact-wrapper .wid-contact ul li .icon i:focus {
  background: #4069ff;
}
.footer-2 .social-profile ul li a {
  border-color: #ff9c00;
}
.footer-2 .social-profile ul li a:hover,
.footer-2 .social-profile ul li a:focus {
  background: #ff9c00;
  border-color: #ff9c00;
  color: #fff !important;
}
.footer-2 .single-footer-wid ul li:hover a,
.footer-2 .single-footer-wid ul li:focus a {
  color: #4069ff;
}
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  h5,
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  span,
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  i,
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  h5,
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  span,
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  i {
  color: #4069ff !important;
}
.footer-2
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  span
  i {
  color: #ff9c00;
}
.footer-2 .footer-bottom-content .coppyright a {
  color: #ff9c00;
}
.footer-2 .footer-bottom-content .coppyright a:hover,
.footer-2 .footer-bottom-content .coppyright a:focus {
  color: #4069ff;
}

.footer-3 {
  z-index: 1;
  position: relative;
  padding-top: 100px;
}
@media (max-width: 1199px) {
  .footer-3 {
    padding-top: 80px;
  }
}
@media (max-width: 991px) {
  .footer-3 {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .footer-3 {
    padding-top: 50px;
  }
}
.footer-3:after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  background: rgba(4, 33, 63, 0.95);
}
.footer-3 .footer-top__box-wrapper {
  gap: 30px;
  z-index: 1;
  overflow: hidden;
  position: relative;
  padding: 45px 116px;
  border-radius: 147px;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1199px) {
  .footer-3 .footer-top__box-wrapper {
    padding: 40px 80px;
  }
}
@media (max-width: 991px) {
  .footer-3 .footer-top__box-wrapper {
    padding: 30px 50px;
    border-radius: 90px;
  }
}
@media (max-width: 767px) {
  .footer-3 .footer-top__box-wrapper {
    padding: 30px 40px;
    border-radius: 85px;
  }
}
.footer-3 .footer-top__box-wrapper:after {
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(239, 5, 72, 0.8);
}
.footer-3 .footer-top__box .text {
  max-width: 461px;
}
@media (max-width: 1199px) {
  .footer-3 .footer-top__box .text h3 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 991px) {
  .footer-3 .footer-top__box .text h3 {
    font-size: 26px;
    line-height: 36px;
  }
}
@media (max-width: 767px) {
  .footer-3 .footer-top__box .text h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
.footer-3 .footer-top__box .theme-btn {
  width: 100%;
  max-width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #243342;
  line-height: 26px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  padding: 12px 15px;
}
.footer-3 .footer-top__box .theme-btn:nth-child(even) {
  color: #fff;
  background: #04213f;
}
.footer-3 .footer-top__box .theme-btn:nth-child(even):hover,
.footer-3 .footer-top__box .theme-btn:nth-child(even):focus {
  color: #243342;
  background: #fff;
}
.footer-3 .footer-top__box .theme-btn:hover,
.footer-3 .footer-top__box .theme-btn:focus {
  color: #fff;
  background: #04213f;
}
.footer-3 .footer-top__box .theme-btn__wrapper {
  gap: 10px;
  width: 348px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .footer-3 .footer-top__box .theme-btn__wrapper {
    width: 320px;
  }
}
.footer-3 .footer-top__box input:hover,
.footer-3 .footer-top__box input:focus {
  color: #243342 !important;
  background: #fff !important;
}
.footer-3 .single-footer-wid ul li:hover a,
.footer-3 .single-footer-wid ul li:focus a {
  color: #ff9c00;
}
@media (min-width: 1199px) {
  .footer-3 .widget-description {
    max-width: 298px;
  }
}
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  h5,
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  span,
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post:hover
  .post-data
  i,
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  h5,
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  span,
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post:focus
  .post-data
  i {
  color: #ff9c00 !important;
}
.footer-3
  .recent_post_widget
  .recent-post-list
  .single-recent-post
  .post-data
  span
  i {
  color: #ef0548;
}
.footer-3 .footer-bottom-list ul li:hover a,
.footer-3 .footer-bottom-list ul li:focus a {
  color: #ff9c00;
}
.footer-3 .footer-bottom-content .coppyright a {
  color: #ef0548;
}
.footer-3 .footer-bottom-content .coppyright a:hover,
.footer-3 .footer-bottom-content .coppyright a:focus {
  color: #ff9c00;
}
.footer-3 .overly .container {
  z-index: 1;
  position: relative;
}
.footer-3 .overly .container:after,
.footer-3 .overly .container:before {
  z-index: -1;
  content: "";
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
}
.footer-3 .overly .container:after {
  left: -268px;
  width: 228px;
  height: 229px;
  bottom: -835px;
  /* background-image: url(assets/img/footer/spot-1.png); */
}
.footer-3 .overly .container:before {
  top: -66px;
  height: 270px;
  right: -268px;
  width: 264.01px;
  /* background-image: url(assets/img/footer/spot-2.png); */
}

.footer-1 .newsletter_widget .newsletter_box_2 form input {
  width: 100%;
  color: rgb(255, 255, 255);
  font-size: 16px;
  line-height: 19px;
  border-radius: 2px;
  background: #1e1b15 !important;
  padding: 21px 200px 21px 20px;
  border: none !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}
.leaflet-right {
  display: none;
}
